import { useNavigation } from "@react-navigation/native"
import { useState } from "react"
import auth from "@react-native-firebase/auth"
import { Alert, View, SafeAreaView, ScrollView, Text, Platform } from "react-native"
import PageStyle from "../style/PageStyle"
import Divider from "../components/Divider"
import Button from "../components/Button"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import * as webAuth from 'firebase/auth'
import { showMessage, getUserFriendlyErrorMessage } from "../Api"
import { useTranslation } from "react-i18next"

// Change password page
const ScreenChangePassword = () => {
    const navigation = useNavigation()
    const { t } = useTranslation()
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loadingText, setLoadingText] = useState<string>()

    const onPressChangePassword = async () => {
        if(password !== confirmPassword) {
            Alert.alert(t('ScreenChangePassword.error_mismatch_title'), t('ScreenChangePassword.error_mismatch_text'))
            return
        }

        try {
            setLoadingText(t('ScreenChangePassword.loading_change'))
            if(Platform.OS === 'web') {
                const auth = webAuth.getAuth()
                const user = auth.currentUser
                if(!user) return
                await webAuth.reauthenticateWithCredential(user, webAuth.EmailAuthProvider.credential(user.email ?? '', currentPassword))
                await webAuth.updatePassword(user, password)
            } else {
                await auth().currentUser?.reauthenticateWithCredential(auth.EmailAuthProvider.credential(auth().currentUser?.email ?? '', currentPassword))
                await auth().currentUser?.updatePassword(password)
            }
            showMessage(t('ScreenChangePassword.success_title'), t('ScreenChangePassword.success_text'))
            navigation.goBack()
        } catch(e: any) {
            setLoadingText(undefined)
            const userFriendlyMessage = getUserFriendlyErrorMessage(e)
            showMessage(t('ScreenChangePassword.error_change'), userFriendlyMessage)
            return
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                <Divider.Medium />
                <Button.Back text={t('ScreenChangePassword.button_back')} />
                <Divider.Medium />
                <ScrollView style={PageStyle.safeArea}>

                    {/* Screen title and subtitle */}
                    <Text style={Typography.Title}>{t('ScreenChangePassword.title')}</Text>
                    <Text style={Typography.Subtitle}>{t('ScreenChangePassword.subtitle')}</Text>
                    <Divider.Medium />

                    {/* User input */}
                    <TextInput title={t('ScreenChangePassword.input_password_current')} onChangeText={setCurrentPassword} secureTextEntry={true} />
                    <Divider.Medium />
                    <TextInput title={t('ScreenChangePassword.input_password_new')} onChangeText={setPassword} secureTextEntry />
                    <Divider.Medium />
                    <TextInput title={t('ScreenChangePassword.input_password_new_repeat')} onChangeText={setConfirmPassword} secureTextEntry />
                    <Divider.Medium />
                    <Button.Primary text={t('ScreenChangePassword.button_change')} loadingText={loadingText} onPress={onPressChangePassword} />
                    <Divider.Extreme />
                </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ScreenChangePassword