import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function Icon(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 77 77"
      fill="none"
      {...props}
    >
      <Path
        d="M67.555 33.447c0-1.083-.42-2.046-1.143-2.707a3.862 3.862 0 00-2.767-1.143H61.6V16c0-.962-.421-1.864-1.023-2.466a3.407 3.407 0 00-2.466-1.023H18.889c-.962 0-1.865.421-2.466 1.023a3.407 3.407 0 00-1.023 2.466v13.536h-2.045a3.862 3.862 0 00-2.768 1.143 3.827 3.827 0 00-1.142 2.707v27.13c0 1.083.42 2.045 1.143 2.767a3.862 3.862 0 002.767 1.143h50.29a3.862 3.862 0 002.767-1.143 3.862 3.862 0 001.143-2.767v-27.07zM65.691 31.4c.48.482.721 1.083.842 1.745l-4.873 2.346V30.56h2.046c.721 0 1.443.361 1.985.842zm-49.268-15.4c0-.661.3-1.323.722-1.744a2.41 2.41 0 011.744-.722h39.222c.662 0 1.323.3 1.745.722.48.421.721 1.083.721 1.745v19.971l-1.143.542-12.693 6.015L38.5 46.5l-8.241-3.91-10.468-4.992-3.368-1.625V16.002zm13.775 27.612l8.121 3.85c.12.06.301.06.422 0l8.12-3.85 15.762 19.852H14.377l15.821-19.852zM11.31 31.401a2.865 2.865 0 012.046-.842H15.4v4.933l-4.873-2.346c.06-.662.361-1.263.782-1.745zm-.842 29.176V34.29l5.234 2.466 13.535 6.437-16.122 20.273a2.825 2.825 0 01-1.805-.842 2.902 2.902 0 01-.842-2.046zm55.224 2.046a2.825 2.825 0 01-1.805.842L47.764 43.192l13.595-6.437 5.234-2.466v26.349a3.213 3.213 0 01-.902 1.985z"
        fill="#fff"
      />
      <Path
        d="M38.981 17.866h12.573c.3 0 .481-.24.481-.48 0-.302-.24-.482-.481-.482H38.98c-.3 0-.481.24-.481.481 0 .24.18.481.481.481zM38.981 21.235h12.573c.3 0 .481-.24.481-.481 0-.3-.24-.481-.481-.481H38.98c-.3 0-.481.24-.481.481 0 .24.18.481.481.481zM38.981 24.604h12.573c.3 0 .481-.24.481-.481 0-.301-.24-.482-.481-.482H38.98c-.3 0-.481.241-.481.482 0 .24.18.48.481.48zM51.554 26.95H25.867c-.3 0-.481.24-.481.481 0 .301.24.482.481.482h25.687c.3 0 .481-.241.481-.482 0-.24-.24-.481-.481-.481zM51.554 30.138H25.867c-.3 0-.481.24-.481.481 0 .301.24.482.481.482h25.687c.3 0 .481-.24.481-.482 0-.24-.24-.48-.481-.48zM26.048 21.897s0 .06 0 0l4.09 4.09c.301.301.662.421 1.023.421.36 0 .722-.12 1.023-.42l.06-.06 4.09-4.091c.361-.361.662-.782.843-1.264.18-.481.3-.962.3-1.444 0-.48-.12-1.022-.3-1.443a3.584 3.584 0 00-.843-1.264 3.585 3.585 0 00-1.263-.842c-.481-.18-.962-.3-1.444-.3-.48 0-1.022.12-1.443.3-.361.12-.662.361-.963.602a5.2 5.2 0 00-.962-.602c-.482-.18-.963-.3-1.444-.3s-1.023.12-1.444.3c-.481.18-.902.482-1.263.842-.361.361-.662.782-.842 1.264-.18.481-.301.962-.301 1.444 0 .48.12 1.022.3 1.443.181.542.422.963.783 1.324zm.12-3.73c.12-.3.36-.662.602-.902.3-.301.601-.482.902-.602.36-.12.722-.24 1.083-.24.36 0 .722.06 1.082.24.301.12.662.361.903.602l.06.06c.18.18.541.18.722 0l.06-.06c.3-.301.602-.482.902-.602.361-.12.722-.24 1.083-.24s.722.06 1.083.24c.3.12.662.361.902.602.301.3.482.601.602.902.12.361.24.722.24 1.083 0 .36-.06.722-.24 1.083-.12.3-.361.661-.602.902l-4.09 4.09a.46.46 0 01-.301.12c-.12 0-.24-.06-.361-.12l-4.09-4.09c-.241-.24-.482-.541-.602-.902s-.24-.722-.24-1.083c.12-.361.18-.782.3-1.083zM51.554 33.627H25.867c-.3 0-.481.24-.481.481 0 .301.24.482.481.482h25.687c.3 0 .481-.24.481-.482 0-.24-.24-.48-.481-.48zM51.554 37.117H25.867c-.3 0-.481.24-.481.48 0 .241.24.482.481.482h25.687c.3 0 .481-.24.481-.481 0-.24-.24-.481-.481-.481z"
        fill="#fff"
      />
    </Svg>
  );
}

export default Icon;