import * as React from "react";
import Svg, {
  SvgProps,
  Path,
  Defs,
  LinearGradient,
  Stop,
} from "react-native-svg";

function BottomGradient(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 375 186"
      fill="none"
      {...props}
    >
      <Path fill="url(#prefix__paint0_linear_114_229)" d="M-2 0h382v186H-2z" />
      <Defs>
        <LinearGradient
          id="prefix__paint0_linear_114_229"
          x1={189}
          y1={0}
          x2={189}
          y2={186}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" stopOpacity={0} />
          <Stop offset={0.5}  stopOpacity={1} stopColor="#FFF7FF" />
          <Stop offset={1} stopColor="#FFF7FF" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default BottomGradient;