import ICategory from "./ICategory"
import IconCategoryCar from "../components/icons/IconCategoryCar"
import IconCategoryClothes from "../components/icons/IconCategoryClothes"
import IconCategoryElectronics from "../components/icons/IconCategoryElectronics"
import IconCategoryKids from "../components/icons/IconCategoryKids"
import IconCategoryFood from "../components/icons/IconCategoryFood"
import IconCategoryDiy from "../components/icons/IconCategoryDiy"
import IconCategoryGifts from "../components/icons/IconCategoryGifts"
import IconCategoryHealth from "../components/icons/IconCategoryHealth"
import IconCategoryHobby from "../components/icons/IconCategoryHobby"
import IconCategoryHome from "../components/icons/IconCategoryHome"
import IconCategoryOutdoor from "../components/icons/IconCategoryOutdoor"
import IconCategoryTravel from "../components/icons/IconCategoryTravel"
import i18n from "../translations/i18n"
const t = i18n.t

const categories: ICategory[] = [{
    name: t('ProductCategory.automotive'),
    icon: <IconCategoryCar width={42} height={42} />,
    iconSmall: <IconCategoryCar width={16} height={16} />,
    apiName: 'Automotive'
}, {
    name: t('ProductCategory.clothing_accessories'),
    icon: <IconCategoryClothes width={42} height={42} />,
    iconSmall: <IconCategoryClothes width={16} height={16} />,
    apiName: 'Clothing & Accessories'
}, {
    name: t('ProductCategory.electronics'),
    icon: <IconCategoryElectronics width={42} height={42} />,
    iconSmall: <IconCategoryElectronics width={16} height={16} />,
    apiName: 'Electronics & Technology'
}, {
    name: t('ProductCategory.family_kids'),
    icon: <IconCategoryKids width={42} height={42} />,
    iconSmall: <IconCategoryKids width={16} height={16} />,
    apiName: 'Family & Kids'
}, {
    name: t('ProductCategory.food_drink'),
    icon: <IconCategoryFood width={42} height={42} />,
    iconSmall: <IconCategoryFood width={16} height={16} />,
    apiName: 'Food & Drink'
}, {
    name: t('ProductCategory.home_garden'),
    icon: <IconCategoryDiy width={42} height={42} />,
    iconSmall: <IconCategoryDiy width={16} height={16} />,
    apiName: 'Garden & Construction'
}, {
    name: t('ProductCategory.gifts_flowers'),
    icon: <IconCategoryGifts width={42} height={42} />,
    iconSmall: <IconCategoryGifts width={16} height={16} />,
    apiName: 'Gifts & Flowers'
}, {
    name: t('ProductCategory.health_beauty'),
    icon: <IconCategoryHealth width={42} height={42} />,
    iconSmall: <IconCategoryHealth width={16} height={16} />,
    apiName: 'Health & Beauty'
}, {
    name: t('ProductCategory.hobby_sparetime'),
    icon: <IconCategoryHobby width={42} height={42} />,
    iconSmall: <IconCategoryHobby width={16} height={16} />,
    apiName: 'Hobbies & Recreation'
}, {
    name: t('ProductCategory.home_interior'),
    icon: <IconCategoryHome width={42} height={42} />,
    iconSmall: <IconCategoryHome width={16} height={16} />,
    apiName: 'Home & Interior'
}, {
    name: t('ProductCategory.sport_outdoor'),
    icon: <IconCategoryOutdoor width={42} height={42} />,
    iconSmall: <IconCategoryOutdoor width={16} height={16} />,
    apiName: 'Sports & Outdoor'
}, {
    name: t('ProductCategory.travel'),
    icon: <IconCategoryTravel width={42} height={42} />,
    iconSmall: <IconCategoryTravel width={16} height={16} />,
    apiName: 'Travel & Accommodation'
}]

export const getCategoryIcon = (apiName: string, small?: boolean) => {
    const category = categories.find(c => c.apiName === apiName)
    return small ? category?.iconSmall : category?.icon
}

export const getCategoryName = (apiName: string) => {
    const category = categories.find(c => c.apiName === apiName)
    return category?.name
}

export const getCategoryDescription = (apiName: string) => {
    const category = categories.find(c => c.apiName === apiName)
    return category?.name
}

export default categories