import i18n from "i18next"
import { initReactI18next } from 'react-i18next'
import danish from './da-dk.json'
import english from './en-us.json'
import * as Localization from 'expo-localization'

i18n.use(initReactI18next).init({
    lng: Localization.locale,
    fallbackLng: 'en',
    resources: {
        en: {translation: english},
        da: {translation: danish}
    },
})

export default i18n