import React, { ReactNode, useEffect, useState } from "react"
import { ActivityIndicator, FlatList, Platform, StyleSheet } from "react-native"
import { RefreshControl, TouchableOpacity, View, SafeAreaView, Text } from "react-native"
import PageStyle from "../style/PageStyle"
import Divider from "../components/Divider"
import Typography from "../style/Typography"
import Button from "../components/Button"
import TextInput from "../components/TextInput"
import IProduct from "../models/IProduct"
import * as api from "../Api"
import CardProduct from "../components/CardProduct"
import { useNavigation } from "@react-navigation/native"
import { useTranslation } from "react-i18next"
import { getUser } from "../Api"
import { getCategoryIcon, getCategoryName } from "../models/Categories"
import IconPen from "../components/icons/IconPen"
import CardProductLarge from "../components/CardProductLarge"
import Color from "../style/Color"
import Animated, { FadeInDown, FadeOutDown } from "react-native-reanimated"

interface IFeedItem {
    title?: string
    subtitle?: string
    icon?: ReactNode
    product?: IProduct
}

let abortController = new AbortController()

const style = StyleSheet.create({
    containerInterest: {
        elevation: 1,
        backgroundColor: 'white',
        height: 30,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 5,
        flexDirection: 'row',
        margin: 2,
        shadowColor: Color.Dark,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 1
    }
})


const ScreenDiscovery = () => {

    const { t } = useTranslation()
    const navigation = useNavigation<any>()
    
    const [searchText, setSearchText] = useState("")
    const [feed, setFeed] = useState<IFeedItem[]>([])
    const [searchProducts, setSearchProducts] = useState<IProduct[]>([])
    const [isSearching, setIsSearching] = useState(false)
    const [interests, setInterests] = useState<string[]>([])
    const [suggestableCategories, setSuggestableCategories] = useState<string[]>([])
    const [isRefreshing, setIsRefreshring] = useState(false)

    const hasSearchText = searchText.length > 0
    const hasSearchResults = searchProducts.length > 0

    const refreshFeed = async () => {
        setIsRefreshring(true)
        setFeed([])
        await getInterests()
        setIsRefreshring(false)
    }

    const getInterests = async () => {
        const interests = await api.getUserInterests()
        if(interests !== undefined && interests.length > 0) {
            setInterests(interests)
        }
    }

    // Navigate to interest selection page if the user has no interests
    api.useAsyncFocusEffect(async () => {
        const user = getUser()
        if(!user) return

        const userInterests = await api.getUserInterests()

        if((!userInterests || userInterests?.length === 0)) {
            navigation.navigate('DiscoveryInterests')
        } else {
            const stateInterests = interests.join(',')
            const userInterestsString = userInterests.join(',')
            if(stateInterests !== userInterestsString) {
                setInterests(userInterests)
                refreshFeed()
            }
        }
    })

    // Clear feed on mount
    useEffect(() => {
        setFeed([])
    }, [])

    // Refresh feed when navigation changes
    useEffect(() => {
        refreshFeed()
    }, [navigation])

    // Add popular products to feed
    api.useAsyncEffect(async () => {
        const products = await api.productGetPopular()
        const categoryHeader: IFeedItem = {
            title: t('ScreenDiscovery.title_trending'),
            subtitle: t('ScreenDiscovery.text_trending')
        }
        const feedItems: IFeedItem[] = [categoryHeader].concat(products.map(product => {return {product}}))
        setTimeout(() => {setFeed(feed => feed.concat(feedItems))}, Math.random() * 1000)
    }, [interests])

    // Add products, in categories based on wishes on the user's existing wishlists, to the feed
    api.useAsyncEffect(async () => {
        const suggestableCategories = await api.getSuggestableCategories()
        if(suggestableCategories.length > 0) {
            setSuggestableCategories(suggestableCategories)
        }
    }, [interests])

    // Fetch popular products from interests
    api.useAsyncEffect(async () => {

        // Shuffle intersts for a more variated feed
        const interestsShuffled = [...interests].sort(() => Math.random() - 0.5)
        if(interestsShuffled.length === 0) return
        try {
            for(const interest of interestsShuffled) {
                const products = await api.productsGetPopularCategory(interest)
                if(products.length > 0) {
                    const categoryHeader: IFeedItem = {
                        title: getCategoryName(interest),
                        subtitle: t('ScreenDiscovery.text_most_popular'),
                        icon: getCategoryIcon(interest, true),
                    }
                    const feedItems: IFeedItem[] = [categoryHeader].concat(
                        products
                            .map(product => {return {product}})
                            .sort(() => Math.random() - 0.5))
                    setFeed(feed => feed.concat(feedItems))
                }
            } 
        } catch(e) {
            console.error(e)
        }
    }, [interests])

    // Load product suggestions from suggested categories
    api.useAsyncEffect(async () => {
        for(let i=0; i<suggestableCategories.length; i++) {
            const category = suggestableCategories[i]
            const products = await api.productGetCategory(category)
            if(products.length === 0) continue
            const categoryTree = category.split(/[-|]/)
            const suggestedCategory = categoryTree[categoryTree.length - 1].trim()
            const categoryHeader: IFeedItem = {
                title: t('ScreenDiscovery.title_suggestions'),
                subtitle: t('ScreenDiscovery.text_suggestions') + suggestedCategory
            }
            const feedItems: IFeedItem[] = [categoryHeader].concat(products.map(product => {return {product}}))
            setFeed(feed => feed.concat(feedItems))
        }
    }, [suggestableCategories])

    const searchForProducts = async (query: string) => {
        try {
            setSearchText(query)
            abortController.abort()
            abortController = new AbortController()
            if(query.length < 3) {
                setSearchProducts([])
                return
            }
            setIsSearching(true)
            const products = await api.productSearch(query, 0, abortController.signal)
            setIsSearching(false)
            setSearchProducts(products)
        } catch(e) {
            // No need to do anything
        }
    }

    const onPressProduct = (product: IProduct) => {
        const productId = product.id
        navigation.push('DiscoveryProduct', { product, productId, discovery: true })
    }

    const renderProductList = (products: IProduct[]) => {
        return <FlatList
            data={products}
            numColumns={Platform.OS === 'web' ? 3 : 1}
            columnWrapperStyle={Platform.OS === 'web' && { justifyContent: 'space-evenly' }}
            keyExtractor={item => item.id}
            renderItem={info => <CardProduct onPress={() => onPressProduct(info.item)} product={info.item} />}
        />
    }

    const onPressEditInterests = () => {
        navigation.navigate('DiscoveryInterests')
    }

    const renderTop = () => {
        return <>
            <Divider.Medium />

            {/* Screen title and subtitle */}
            <Text style={Typography.Title}>{t('ScreenDiscovery.title')}</Text>
            <Text style={Typography.Subtitle}>{t('ScreenDiscovery.subtitle')}</Text>
            <Divider.Medium />

            {/* Search for wishes */}
            <TextInput 
                loading={isSearching} 
                onChangeText={searchForProducts} 
                placeholder={t('ScreenDiscovery.input_products')} 
                title={t('ScreenDiscovery.input_products_placeholder')} />

            {/* Search results */}
            {hasSearchResults && renderProductList(searchProducts)}
            <Divider.Medium />

            {/* If the user is not searching */}
            
            {/* Interests */}
            {feed.length === 0 ? (
                <ActivityIndicator size='large' color={Color.Primary} style={{alignSelf: 'center'}} />
            ) : (
                <Animated.View entering={FadeInDown} exiting={FadeOutDown}>
                    {!hasSearchText && interests && interests.length > 0 && <>
                        <Text style={Typography.Subsubtitle}>{t('ScreenDiscovery.title_your_interests')}</Text>
                        <View style={PageStyle.rowWrap}>
                            {interests.map((interest, index) => (
                                <View key={index} style={style.containerInterest}>
                                    {getCategoryIcon(interest, true)}
                                    <Text style={[Typography.TextSmall, {marginLeft: 4}]}>{getCategoryName(interest)}</Text>
                                </View>
                            ))}
                            <TouchableOpacity onPress={onPressEditInterests} style={style.containerInterest}>
                                <IconPen width={16} height={16} />
                                <Text style={[Typography.TextSmall, {marginLeft: 4}]}>{t('ScreenDiscovery.button_edit_interests')}</Text>
                            </TouchableOpacity>
                        </View>
                    </>}
                </Animated.View>
            )}
        </>
    }

    const renderProduct = (product: IProduct) => {
        if(Math.sin(product.name.length) < -0.7) {
            return <CardProductLarge onPress={() => onPressProduct(product)} product={product} />
        } else {
            return <CardProduct onPress={() => onPressProduct(product)} product={product} />
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent]}>
                {Platform.OS === 'web' && <View style={PageStyle.webSafeArea}>
                    <Divider.Medium />
                    <Button.Back text="" />
                    <Divider.Small />
                </View> }
                <FlatList
                    contentContainerStyle={[PageStyle.webSafeArea, {paddingHorizontal: 16}]}
                    data={hasSearchText ? [] : feed}
                    refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={refreshFeed} />}
                    ListHeaderComponent={renderTop()}
                    ListFooterComponent={<Divider.Large />}
                    nestedScrollEnabled={true}
                    renderItem={info => (
                        <View>
                            {info.item?.title !== undefined && <>
                                <Divider.Medium />
                                <Text style={Typography.Subsubtitle}>{info.item.title}</Text>
                            </>}
                            {info.item?.subtitle !== undefined && <Text style={Typography.TextSmall}>{info.item.subtitle}</Text>}
                            {info.item?.product !== undefined && renderProduct(info.item.product!)}
                        </View>
                    )}
                />
            </SafeAreaView>
        </View>
    )
}

export default ScreenDiscovery