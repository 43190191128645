import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryCar(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <Path
        d="M6.594 6c-1.336 0-2.57.668-3.313 1.781L.5 11.938a2.97 2.97 0 00-.5 1.656v6.625a1.99 1.99 0 001.5 1.937l2.594.657A4 4 0 008 26c1.852 0 3.398-1.281 3.844-3h9.312c.446 1.719 1.992 3 3.844 3 1.852 0 3.398-1.281 3.844-3H30c1.094 0 2-.906 2-2v-3.656a3.998 3.998 0 00-3.031-3.875l-5.407-1.375-3.906-4.656A3.985 3.985 0 0016.594 6h-10zm0 2H11v4H2.875l2.063-3.094v-.031A1.963 1.963 0 016.593 8zM13 8h3.594c.593 0 1.152.262 1.531.719L20.875 12H13V8zM2 14h20.875l5.625 1.406a1.976 1.976 0 011.5 1.938V21h-1.156c-.446-1.719-1.992-3-3.844-3-1.852 0-3.398 1.281-3.844 3h-9.312c-.446-1.719-1.992-3-3.844-3-1.773 0-3.262 1.172-3.781 2.781L2 20.22V14zm6 6c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm17 0c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryCar;