import { useEffect, useRef, useState } from "react"
import { ScrollView, View, Text, ImageBackground, StyleSheet, Platform, Dimensions, Alert } from "react-native"
import Color from "../style/Color"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import BackgroundStars from '../assets/bg-stars.png'
import { useTranslation } from "react-i18next"
import { useNavigation } from "@react-navigation/native"
import Divider from "../components/Divider"
import Button from "../components/Button"
import ICategory from "../models/ICategory"
import CardInterest from "../components/CardInterest"
import firestore from "@react-native-firebase/firestore"
import * as webFirestore from "firebase/firestore"
import { getUser, getUserFriendlyErrorMessage, getUserInterests, setUserInterests, useAsyncEffect } from "../Api"
import AsyncStorage from "@react-native-async-storage/async-storage"
import categories from "../models/Categories"
import { showMessage } from "../Api"

const ScreenDiscoveryInterests = () => {

    const { t } = useTranslation()
    const [isSaving, setIsSaving] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const navigation = useNavigation()
    const [selectedCategories, setSelectedCategories] = useState<string[]>([])

    // Load existing interests from Firestore
    useAsyncEffect(async () => {
        const interests = await getUserInterests()
        if(interests) {
            setSelectedCategories(interests)
            if(interests.length > 0) setEditMode(true)
        }
    }, [])

    const onPressContinue = async () => {
        
        // If the user didn't choose any categories, just give them all of them
        let categoriesToSave = selectedCategories
        if(selectedCategories.length === 0) categoriesToSave = categories.map(c => c.apiName)
        
        // Save the selected categories to firestore
        const user = getUser()
        setIsSaving(true)
        if(!user) {
            showMessage(t('ScreenDiscoveryInterests.error_no_user'), t('ScreenDiscoveryInterests.error_no_user_text'))
        }
        try {
            await setUserInterests(categoriesToSave)
            navigation.goBack()
        } catch(e) {
            console.error(e)
            setIsSaving(false)
            const errorMessage = getUserFriendlyErrorMessage(e)
            showMessage(t('ScreenDiscoveryInterests.error_save'), errorMessage)
        }
    }

    const onCategoryValueChange = (category: ICategory, selected: boolean) => {
        if(selected) {
            setSelectedCategories(selectedCategories => selectedCategories.concat([category.apiName]))
        } else {
            setSelectedCategories(selectedCategories.filter(c => c !== category.apiName))
        }
    }
    
    const renderCurtain = () => {
        return (
            <View style={[{width: Dimensions.get('window').width, height: 650, marginTop: -200}]}>
                <ImageBackground source={BackgroundStars} style={[PageStyle.fillParent, {paddingTop: 200}]}>
                    <Text style={[Typography.Title, PageStyle.TextShadow, {color: Color.Lightest, fontSize: 40, marginHorizontal: 24, marginTop: '10%'}]}>{t('ScreenDiscoveryInterests.title')}</Text>
                    <Text style={[Typography.Subtitle, PageStyle.TextShadow, {color: Color.Lightest, marginHorizontal: 24, marginTop: -5}]}>{t('ScreenDiscoveryInterests.subtitle')}</Text>
                </ImageBackground>
            </View>
        )
    }

    return (
        <View style={PageStyle.default}>
                <ScrollView style={PageStyle.webSafeArea}>
            
            {renderCurtain()}
            
                {/* Web logo */}
                <Divider.Medium />
                {Platform.OS === 'web' && <Button.Back />}

                {Platform.OS === 'web' && <Divider.Gigantic /> }
                    <View style={{paddingHorizontal: 16}}>
                        <Text style={Typography.Subtitle}>{t('ScreenDiscoveryInterests.title_interests')}</Text>
                        <Text style={Typography.Text}>{!editMode ? 
                                t('ScreenDiscoveryInterests.title_intro')
                            :
                                t('ScreenDiscoveryInterests.title_intro_returning_user')}
                        </Text>
                        <Divider.Medium />

                        {/* Categories */}
                        { categories.map((category, index) => {
                            const selected = selectedCategories.indexOf(category.apiName) !== -1
                            return <CardInterest value={selected} key={index} category={category} onValueChange={onCategoryValueChange} />
                        })}
                        
                        {/* Reminder that interests can be edited anytime */}
                        <Divider.Medium />
                        <Text style={[Typography.Text, Typography.Center]}>{t('ScreenDiscoveryInterests.change_anytime')}</Text>
                        <Divider.Tiny />
                        
                        {/* Save button */}
                        <Button.Primary text={t('ScreenDiscoveryInterests.button_save')} onPress={onPressContinue} loadingText={isSaving ? t('ScreenDiscoveryInterests.saving') : ''} />
                        <Divider.Huge />
                    </View>
                </ScrollView>
        </View>
    )


}

export default ScreenDiscoveryInterests