import { Image, ImageStyle } from 'react-native'
import Gift1 from '../assets/gifts/gift1.png'
import Gift2 from '../assets/gifts/gift2.png'
import Gift3 from '../assets/gifts/gift3.png'
import Gift4 from '../assets/gifts/gift4.png'
import Gift5 from '../assets/gifts/gift5.png'
import Gift6 from '../assets/gifts/gift6.png'
import Gift7 from '../assets/gifts/gift7.png'
import Gift8 from '../assets/gifts/gift8.png'
import Gift9 from '../assets/gifts/gift9.png'

interface GiftProps {
    index: number
    style?: ImageStyle
}

const gifts = [
    Gift1, Gift2, Gift3,
    Gift4, Gift5, Gift6,
    Gift7, Gift8, Gift9
]

export default (props: GiftProps) => (
    <Image source={gifts[props.index % 9]} style={[{width: 128, height: 128, resizeMode: 'contain'}, props.style]} />
)