import React, { useCallback, useEffect, useState } from "react"
import IReservation from "../models/IReservation"
import firestore from '@react-native-firebase/firestore'
import * as webFirestore from 'firebase/firestore'
import { Platform, ScrollView, StyleSheet, Switch } from "react-native"
import { getUser, observeReservations } from "../Api"
import { View, SafeAreaView, Text } from "react-native"
import PageStyle from "../style/PageStyle"
import Divider from "../components/Divider"
import Typography from "../style/Typography"
import CardReservation from "../components/CardReservation"
import Color from "../style/Color"
import Button from "../components/Button"
import AsyncStorage from '@react-native-async-storage/async-storage'
import { enableReservationNotifications, disableReservationNotifications } from '../Api'
import { useFocusEffect } from "@react-navigation/native"
import { useTranslation } from "react-i18next"

const style = StyleSheet.create({
    shoppingListCircle: {
        width: 32, 
        height: 32, 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: Color.Danger, 
        position: 'relative', 
        top: -17, 
        marginLeft: 8,
        borderRadius: 16
    }
})

const ScreenReservations = () => {

    const { t } = useTranslation()
    const [reservations, setReservations] = useState<IReservation[]>([])
    const [notificationsEnabled, setNotificationsEnabled] = useState(true)
    const shoppingList = reservations.filter(reservation => !reservation.bought)
    const boughtList = reservations.filter(reservation => reservation.bought)

    // Observe reservations from Firestore
    useEffect(() => {
        return observeReservations(setReservations)
    }, [])

    // Enable notifications on first launch
    useFocusEffect(useCallback(() => {
        const enableNotificationsOnFirstLaunch = async () => {
            const enabledReservationNotifications = await AsyncStorage.getItem('enabledReservationNotifications')
            if(enabledReservationNotifications === null) {
                await AsyncStorage.setItem('enabledReservationNotifications', 'true')
                await enableReservationNotifications()
                setNotificationsEnabled(true)
            }
        }

        enableNotificationsOnFirstLaunch()
    }, []))

    // On switch value changed
    const onSwitchValueChanged = async (notificationsEnabled: boolean) => {
        if(notificationsEnabled) await enableReservationNotifications()
        else await disableReservationNotifications()
        setNotificationsEnabled(notificationsEnabled)
    }

    // Update switch from AsyncStorage value (if the user has enabled notifications)
    useFocusEffect(() => {
        AsyncStorage.getItem('enabledReservationNotifications').then(enabledReservationNotifications => {
            if(enabledReservationNotifications === 'true') setNotificationsEnabled(true)
            else setNotificationsEnabled(false)   
        })
    })

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>

                {/* Web logo */}
                <Divider.Huge />
                {Platform.OS === 'web' && <Button.Back />}
                {Platform.OS === 'web' && <Divider.Gigantic /> }

                <ScrollView style={PageStyle.safeArea}>

                    {/* Screen title and subtitle */}
                    <Text style={Typography.Title}>{t('ScreenReservations.title')}</Text>
                    <Text style={Typography.Subtitle}>{t('ScreenReservations.subtitle')}</Text>
                    <Divider.Large />

                    {/* Shopping list title */}
                    <View style={{flexDirection: 'row'}}>
                        <Text style={[Typography.Subtitle, {fontSize: 18}]}>{t('ScreenReservations.shopping_list')}</Text>
                        
                        {/* Circle containing the amount of wishes on the shopping list */}
                        {shoppingList.length > 0 && <View style={style.shoppingListCircle}>
                            <Text style={[Typography.Text, {color: Color.White}]}>{shoppingList.length}</Text>
                        </View>}
                    </View>
                    
                    {/* Shopping list (reserved wishes that haven't been bought) */}
                    {shoppingList.length === 0 && <Text style={Typography.Text}>{t('ScreenReservations.shopping_list_empty')}</Text>}
                    {shoppingList.map(reservation => <>
                        <CardReservation reservation={reservation} key={reservation.wishId} />
                        <Divider.Tiny />
                    </>)}
                    <Divider.Large />

                    {/* Already bought (reserved wishes that was already bought) */}
                    <Text style={[Typography.Subtitle, {fontSize: 18}]}>{t('ScreenReservations.bought')}</Text>
                    {boughtList.length === 0 && <Text style={Typography.Text}>{t('ScreenReservations.bought_empty')}</Text>}
                    {boughtList.map(reservation => <View style={{opacity: 0.5}}>
                        <CardReservation reservation={reservation} key={reservation.wishId} hideShadow />
                        <Divider.Tiny />
                    </View>)}
                    <Divider.Large />

                    {/* Notification settings (iOS only) */}
                    {Platform.OS === 'ios' && <View>
                        <Text style={[Typography.Subtitle, {fontSize: 18}]}>{t('ScreenReservations.title_settings')}</Text>
                        <Text style={Typography.InputTitle}>{t('ScreenReservations.text_reminder')}</Text>
                        <Switch style={{alignSelf: 'flex-start'}} onValueChange={onSwitchValueChanged} value={notificationsEnabled} thumbColor={Color.Background} trackColor={{true: Color.Primary}} />
                        <Divider.Huge />
                    </View>}
                    <Divider.Medium />

                </ScrollView>
            </SafeAreaView>
        </View>
    )

}

export default ScreenReservations