import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function StarEmpty(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 50 48"
      fill="none"
      {...props}
    >
      <Path
        d="M23.098 1.854c.599-1.843 3.205-1.843 3.804 0l4.384 13.493a2 2 0 001.902 1.383h14.188c1.938 0 2.743 2.479 1.176 3.617l-11.478 8.34a2 2 0 00-.727 2.236l4.384 13.493c.6 1.843-1.51 3.375-3.077 2.237l-11.478-8.34a2 2 0 00-2.352 0l-11.478 8.34c-1.567 1.138-3.676-.394-3.077-2.237l4.384-13.493a2 2 0 00-.727-2.236l-11.478-8.34C-.119 19.21.686 16.73 2.624 16.73H16.81a2 2 0 001.903-1.383l4.384-13.493z"
        fill="#D9D9D9"
      />
    </Svg>
  );
}

export default StarEmpty;