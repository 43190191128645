import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconPenDisabled(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <Path
        d="M20.66 1.648a3.099 3.099 0 00-4.378 0L15.186 2.75 3.52 14.41l-.025.025c-.006.006-.006.012-.012.012-.013.02-.031.038-.043.056 0 .006-.007.006-.007.012l-.03.05c-.007.006-.007.012-.013.019l-.019.05c0 .005-.006.005-.006.012L.777 22.429a.61.61 0 00.148.632.623.623 0 00.638.148l7.778-2.594c.006 0 .006 0 .012-.006a.222.222 0 00.056-.025c.005 0 .009-.003.012-.006.019-.013.043-.025.062-.038.018-.012.037-.03.056-.043.006-.006.012-.006.012-.012.006-.006.019-.012.025-.025L22.338 7.698a3.098 3.098 0 000-4.378L20.66 1.648zM9.143 19.16l-4.31-4.31L15.62 4.063l4.31 4.31L9.142 19.16zm-4.917-3.165L7.99 19.76l-5.654 1.883 1.889-5.648zm17.24-9.164l-.657.662-4.31-4.31.663-.662a1.857 1.857 0 012.625 0l1.684 1.684c.72.729.717 1.901-.006 2.626z"
        fill="#C4C4C4"
      />
    </Svg>
  );
}

export default IconPenDisabled;