import {View, ScrollView, Text, Dimensions, ImageBackground, NativeSyntheticEvent, NativeScrollEvent} from 'react-native';
import BackgroundWedding from '../assets/bg-wedding.png'
import BackgroundBeach from '../assets/bg-beach.png'
import BackgroundStars from '../assets/bg-stars.png'
import Color from '../style/Color';
import PageStyle from '../style/PageStyle';
import Typography from '../style/Typography';
import Button from '../components/Button';
import { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

interface CircleProps {
    active: boolean
}
const Circle = (props: CircleProps) => {
    return (
        <View style={{
            width: 8, height: 8, 
            margin: 2, borderRadius: 4, 
            backgroundColor: props.active ? Color.Primary : Color.Light}} />
    )
}

const ScreenLandingPageNative = () => {

    const navigation = useNavigation<any>()
    const { t } = useTranslation()
    const { width } = Dimensions.get('window')
    const [activePage, setActivePage] = useState(0)

    const onPressGetStarted = () => {
        navigation.navigate('Home')
    }

    const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        const newActivePage = Math.round(e.nativeEvent.contentOffset.x / width)
        if(newActivePage !== activePage) {
            setActivePage(newActivePage)
        }
    }

    const renderScreen1 = () => {
        return (
            <View style={[PageStyle.fillParent, {width}]}>
                <ImageBackground source={BackgroundStars} style={PageStyle.fillParent}>
                    <Text style={[Typography.Title, PageStyle.TextShadow, {color: Color.Lightest, fontSize: 40, marginHorizontal: 24, marginTop: '10%'}]}>{t('ScreenLandingPageNative.title_1')}</Text>
                    <Text style={[Typography.Text, PageStyle.TextShadow, {color: Color.Lightest, marginHorizontal: 24}]}>{t('ScreenLandingPageNative.title_2')}</Text>
                </ImageBackground>
            </View>
        )
    }

    const renderScreen2 = () => {
        return (
            <View style={[PageStyle.fillParent, {width}]}>
                <ImageBackground source={BackgroundWedding} style={PageStyle.fillParent}>
                    <Text style={[Typography.Title, PageStyle.TextShadow, {color: Color.Lightest, fontSize: 40, marginHorizontal: 24, marginTop: '10%'}]}>{t('ScreenLandingPageNative.title_3')}</Text>
                    <Text style={[Typography.Text, PageStyle.TextShadow, {color: Color.Lightest, marginHorizontal: 24}]}>
                    {t('ScreenLandingPageNative.title_3_text')}.</Text>
                </ImageBackground>
            </View>
        )
    }

    const renderScreen3 = () => {
        return (
            <View style={[PageStyle.fillParent, {width}]}>
                <ImageBackground source={BackgroundBeach} style={PageStyle.fillParent}>
                    <Text style={[Typography.Title, PageStyle.TextShadow, {color: Color.Lightest, fontSize: 40, marginHorizontal: 24, marginTop: '10%'}]}>{t('ScreenLandingPageNative.title_4')}</Text>
                    <Text style={[Typography.Text, PageStyle.TextShadow, {color: Color.Lightest, marginHorizontal: 24}]}>{t('ScreenLandingPageNative.title_4_text')}</Text>
                </ImageBackground>
            </View>
        )
    }

    return (   
        <View style={[PageStyle.default, PageStyle.fillParent, {justifyContent: 'space-between'}]}>
            <View style={{flex: 1, alignSelf: 'stretch'}}>
                <ScrollView scrollEventThrottle={width / 2} onScroll={onScroll} style={[PageStyle.fillParent]} showsHorizontalScrollIndicator={false} horizontal pagingEnabled>
                    {renderScreen1()}
                    {renderScreen2()}
                    {renderScreen3()}
                </ScrollView>
            </View>
            <View style={{paddingHorizontal: 24, paddingBottom: 24}}>
                <View style={[PageStyle.row, {alignSelf: 'center', marginBottom: 8}]}>
                    <Circle active={activePage === 0} />
                    <Circle active={activePage === 1} />
                    <Circle active={activePage === 2} />
                </View>
                <Button.Primary onPress={onPressGetStarted} text={t('ScreenLandingPageNative.button_start')} />
            </View>
        </View>
    )
}

export default ScreenLandingPageNative