import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryHome(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 28 26"
      fill="none"
      {...props}
    >
      <Path
        d="M14 .594l-.719.687-13 13L1.72 15.72 3 14.438V26h9V16h4v10h9V14.437l1.281 1.282 1.438-1.438-13-13L14 .594zm0 2.844l9 9V24h-5V14h-8v10H5V12.437l9-9z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryHome;