import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import Animated, { FadeInDown, FadeOut, FadeOutDown } from "react-native-reanimated";
import Color from "../style/Color";
import PageStyle from "../style/PageStyle";
import Typography from "../style/Typography";
import Button from "./Button";

const style = StyleSheet.create({
    view: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Color.BackgroundTransparent,
        zIndex: 1,
        flexDirection: 'row',
    },
    container: {
        padding: 16,
        marginHorizontal: 16,
        borderRadius: 22.5,
        borderColor: Color.DarkTransparentMore,
        borderWidth: 1,
        justifyContent: 'center',
        maxWidth: 350,
        backgroundColor: Color.Background,
        ... PageStyle.Shadow
    },
    hidden: {
        display: 'none'
    },
    text: {
        marginLeft: 8
    },
    feedbackTextInput: {
        backgroundColor: 'white',
        borderRadius: 22.5,
        borderColor: Color.DarkTransparentMore,
        borderWidth: 1,
        height: 90,
        paddingHorizontal: 8,
        paddingVertical: 16,
        textAlignVertical: 'top'
    },
    counterContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    counterButton: {
        width: 32,
        height: 32,
        alignSelf: 'center'
    }
})

interface WishCountInputProps {
    onFinished: (amount: number | null) => void
    initialValue?: number
    textAdd?: string
    visible?: boolean
}

const WishCountInput = (props: WishCountInputProps) => {

    const { t } = useTranslation()
    const [count, setCount] = useState(props?.initialValue ?? 1)
    const canMinus = count > 1
    const canPlus = true

    const onPressPlus = () => {
        setCount(count => count + 1)
    }

    const onPressMinus = () => {
        setCount(count => Math.max(1, count - 1))
    }

    const onPressAdd = () => {
        props.onFinished(count)
    }

    const onPressUndefinedAmount = () => {
        props.onFinished(null)
    }
    
    // Set visibility
    let display: 'flex' | 'none' = 'flex'
    if(props.visible !== undefined) display = props.visible ? 'flex' : 'none'

    return (    
        <Animated.View exiting={FadeOut} style={[style.view, {display: display}]}>
            <Animated.View style={style.container} entering={FadeInDown} exiting={FadeOutDown}>
                <Text style={[Typography.Subsubtitle, Typography.Center]}>{t('WishCountInput.title')}</Text>
                <View style={style.counterContainer}>
                    <Button.PrimarySmall style={style.counterButton} text="-" onPress={onPressMinus} disabled={!canMinus} />
                    <Text style={Typography.Title}>{count}</Text>
                    <Button.PrimarySmall style={style.counterButton} text="+" onPress={onPressPlus} disabled={!canPlus} />
                </View>
                <View style={[PageStyle.row, {marginTop: 8}]}>
                    <Button.PrimarySmall onPress={onPressAdd} style={{marginRight: 8, paddingHorizontal: 16}} text={props?.textAdd ?? t('WishCountInput.button_add')} />
                    <Button.PrimarySmall onPress={onPressUndefinedAmount} style={{paddingHorizontal: 16}} text={t('WishCountInput.button_amount_undefined')} />
                </View>
            </Animated.View>
        </Animated.View>
    )

}

export default WishCountInput