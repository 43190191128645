import {Text, View, SafeAreaView, StyleSheet, TouchableOpacity, ScrollView, ImageBackground, Alert, Platform} from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import Color from "../style/Color"
import { useState } from "react"
import * as ImagePicker from "expo-image-picker"
import * as api from '../Api'
import { useNavigation } from "@react-navigation/native"
import LoadingOverlay from "../components/LoadingOverlay"
import auth from '@react-native-firebase/auth'
import * as webAuth from 'firebase/auth'
import { getUser } from '../Api'
import { disableReservationNotifications } from '../Api'
import { useTranslation } from "react-i18next"

const style = StyleSheet.create({
    title: {fontSize: 20},
    profilePicture: {width: 128, height: 128, borderRadius: 64, overflow: 'hidden', alignSelf: 'center'},
    deleteProfileButton: {backgroundColor: Color.Danger}
})

const ScreenProfile = () => {

    const navigation = useNavigation<any>()
    const { t } = useTranslation()
    const [profilePicture, setProfilePicture] = useState<string>()
    const [name, setName] = useState<string>(getUser()?.displayName ?? '')
    const [profilePictureLoadingText, setProfilePictureLoadingText] = useState<string>()
    const [loadingText, setLoadingText] = useState<string>()
    const providerId = getUser()?.providerData[0]?.providerId
        
    const isGoogleUser = providerId === 'google.com'
    const isEmailUser = providerId === 'password'
    const userInfoChanged = name !== getUser()?.displayName

    // Change user profile picture
    const onPressChangeProfilePicture = async () => {
        // Pick a single image
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.2
        })

        // Upload image if needed
        if(!result.cancelled) {
            let imageUrl: string = api.defaultWishlistBackgroundUrl
            try {
                setProfilePictureLoadingText(t('ScreenProfile.loading_upload_image'))
                setProfilePicture(result.uri)
                imageUrl = await api.uploadImage(result.uri)
                await api.setUserProfilePicture(imageUrl)
            } catch(e: any) {
                Alert.alert(t('ScreenProfile.error'), t('ScreenProfile.error_upload_text', {message: e.message}))
                console.error(e)
                return
            } finally {
                setProfilePictureLoadingText(undefined)
            }
        }
    }

    // Logout
    const onPressLogout = async () => {
        setLoadingText(t('ScreenProfile.loading_logout'))
        try {
            if(Platform.OS === 'web') {
                // Sign out of Google, if it is a Google account
                const auth = webAuth.getAuth()
                await webAuth.signOut(auth)
                navigation.goBack()
            } else {
                // Display a confirmation, if the user is sure to logout
                await Alert.alert(t('ScreenProfile.confirm_logout_title'), t('ScreenProfile.confirm_logout_text'), 
                [{text: t('ScreenProfile.text_yes'), onPress: async () => {

                    try {
                        // Sign out of Google, if it is a Google account
                        if(isGoogleUser) {
                            const { GoogleSignin } = require('@react-native-google-signin/google-signin')
                            await GoogleSignin.signOut()
                        }
                        
                        // Disable notifications for reserved wishes
                        await disableReservationNotifications()

                        // Finally, sign out
                        await auth().signOut()

                        // Go back to the previous screen (home screen)
                        navigation.goBack()
                    } catch (e: any) {
                        console.error(e)
                        api.showMessage(t('ScreenProfile.error'), t('ScreenProfile.error_logout', {message: e.message}))
                        setLoadingText(undefined)
                    }
                }}, {text: t('ScreenProfile.text_no'), style: 'cancel'}], {cancelable: false})
            }
        } catch(e: any) {
            console.error(e)
            api.showMessage(t('ScreenProfile.error'), t('ScreenProfile.error_logout', {message: e.message}))
            setLoadingText(undefined)
        }
    }

    // Save user profile, only display name for now
    const onPressSave = async () => {
        try {
            setLoadingText(t('ScreenProfile.loading_save'))
            await api.setUserDisplayName(name)
            navigation.goBack()
        } catch(e: any) {
            Alert.alert(t('ScreenProfile.error'), t('ScreenProfile.error_save', {message: e.message}))
            setLoadingText(undefined)
        }
    }

    // Navigate to delete profile screen
    const onPressDeleteProfile = () => {
        navigation.navigate('DeleteProfile')
    }

    // Navigate to change password screen
    const onPressChangePassword = () => {
        navigation.navigate('ChangePassword')
    }

    // Navigate to change email screen
    const onPressChangeEmail = () => {
        navigation.navigate('ChangeEmail')
    }


    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>

                    {/* Loading overlay */}
                    { loadingText && <LoadingOverlay text={loadingText} /> }

                    {/* Back button */}
                    <Divider.Medium />
                    <Button.Back text={t('ScreenProfile.button_back')} />
                    <Divider.Medium />

                    <ScrollView style={PageStyle.safeArea}>

                        {/* Title and subtitle */}
                        <Text style={[Typography.Title]}>{t('ScreenProfile.title')}</Text>
                        <Text style={Typography.Subtitle}>{t('ScreenProfile.subtitle')}</Text>
                        <Divider.Medium />

                        {/* Profile picture */}
                        <TouchableOpacity onPress={onPressChangeProfilePicture} style={PageStyle.Shadow}>
                            <ImageBackground style={style.profilePicture} source={{uri: profilePicture ?? getUser()?.photoURL ?? ''}} />
                        </TouchableOpacity>

                        {/* Display name input */}
                        <TextInput title={t('ScreenProfile.input_first_name')} onChangeText={setName} defaultValue={getUser()?.displayName ?? ''} />
                        <Divider.Small />

                        {/* Change profile picture button */}
                        <Text style={Typography.InputTitle}>{t('ScreenProfile.profile_picture')}</Text>
                        <Button.PrimarySmall loadingText={profilePictureLoadingText} onPress={onPressChangeProfilePicture} text={t('ScreenProfile.button_choose_profile_picture')} />
                        <Divider.Tiny />

                        {/* Change password button (non-social/email accounts only) */}
                        { isEmailUser && <>
                            <Text style={Typography.InputTitle}>{t('ScreenProfile.text_password')}</Text>
                            <Button.PrimarySmall onPress={onPressChangePassword} text={t('ScreenProfile.button_change_password')} />
                            <Divider.Tiny />
                        </> }

                        {/* Change email button (non-social/email accounts only) */}
                        { isEmailUser && <>
                            <Text style={Typography.InputTitle}>{t('ScreenProfile.text_email')}</Text>
                            <Button.PrimarySmall onPress={onPressChangeEmail} text={t('ScreenProfile.button_change_email')} />
                            <Divider.Tiny />
                        </> }
                        
                        {/* Logout button */}
                        <Text style={Typography.InputTitle}>{t('ScreenProfile.text_change_profile')}</Text>
                        <Button.PrimarySmall onPress={onPressLogout} text={t('ScreenProfile.button_logout')} />
                        <Divider.Tiny />

                        {/* Delete profile button (non-social/email accounts only) */}
                        { isEmailUser && <>
                        <Text style={Typography.InputTitle}>{t('ScreenProfile.text_delete_profile')}</Text>
                        <Button.PrimarySmall style={style.deleteProfileButton} onPress={onPressDeleteProfile} text={t('ScreenProfile.button_delete_profile')} />
                        </> }
                        
                        {/* Save button */}
                        <Divider.Medium />
                        {userInfoChanged && <Button.Primary onPress={onPressSave} text={t('ScreenProfile.button_save')} /> }
                        <Divider.Large />

                    </ScrollView>                
            </SafeAreaView>
        </View>
    )
}

export default ScreenProfile