import Navigation from './Navigation';
import { Platform, View } from 'react-native'
import { useFonts, Mukta_200ExtraLight, Mukta_300Light, Mukta_400Regular, Mukta_500Medium, Mukta_600SemiBold, Mukta_700Bold, Mukta_800ExtraBold } from '@expo-google-fonts/mukta';
import { OleoScriptSwashCaps_400Regular, OleoScriptSwashCaps_700Bold } from '@expo-google-fonts/oleo-script-swash-caps';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { initializeApp } from 'firebase/app'
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import './translations/i18n' // Initialize localization
import { SafeAreaProvider } from 'react-native-safe-area-context';

const App = () => {

  // Inject fonts
  let [fontsLoaded] = useFonts({
    Mukta_200ExtraLight,
    Mukta_300Light,
    Mukta_400Regular,
    Mukta_500Medium,
    Mukta_600SemiBold,
    Mukta_700Bold,
    Mukta_800ExtraBold,
    OleoScriptSwashCaps_400Regular,
    OleoScriptSwashCaps_700Bold
  })

  const initializeFacebookAutoAppInstalls = async () => {
    if(Platform.OS === 'web') return
    const { Settings } = require('react-native-fbsdk-next')
    const { status } = await requestTrackingPermissionsAsync()

    Settings.initializeSDK()

    if (status === 'granted') {
        await Settings.setAdvertiserTrackingEnabled(true)
        await Settings.setAppID('330730532474881')
    }
  }

  if(Platform.OS === 'web') {
    // Web-only intialization

    // Initialize Firebase
    const firebaseConfig = {
      apiKey: "AIzaSyDRdxbck309K1UiO5qtnG_AG4oLzs-RtnI",
      authDomain: "oenskeliste-8904f.firebaseapp.com",
      databaseURL: "https://oenskeliste-8904f.firebaseio.com",
      projectId: "oenskeliste-8904f",
      storageBucket: "oenskeliste-8904f.appspot.com",
      messagingSenderId: "503802547992",
      appId: "1:503802547992:web:311d060725f6369019d86d",
      measurementId: "G-2DF8NG5VMM"
    }
    initializeApp(firebaseConfig);
  } else {
    // Native-only initialization

    // Initialize Google sign-in
    const { GoogleSignin } = require('@react-native-google-signin/google-signin')
    GoogleSignin.configure({
      webClientId: '503802547992-lnjbqj8jcugfhufh2dilu11sv9i6d3kg.apps.googleusercontent.com',
    })

    // Initialize Facebook auto app installs
    initializeFacebookAutoAppInstalls()
  }

  return <SafeAreaProvider>
      <ActionSheetProvider>
        {!fontsLoaded ? <View /> : <Navigation />}
      </ActionSheetProvider>
    </SafeAreaProvider>
  
}

export default App