import { Platform, StyleSheet } from "react-native";
import Color from "./Color";

export default StyleSheet.create({
    default: {
        flex: 1,
        backgroundColor: Color.Background,
    },
    safeArea: {
        flex: 1,
        paddingHorizontal: 16,
        alignSelf: 'stretch',
        position: 'relative',
        zIndex: 0
    },
    fillParent: {
        flex: 1
    },
    webSafeArea: { // Web-only style, for SafeAreaView
        maxWidth: Platform.OS === 'web' ? 1024 : undefined,
        alignSelf: Platform.OS === 'web' ? 'center' : undefined,
        width: Platform.OS === 'web' ? '100%' : undefined,
    },
    row: {
        flexDirection: 'row'
    },
    rowWrap: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    Shadow: Platform.OS !== 'web' ? {
        elevation: 4,
        shadowColor: 'black',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 2
    } : {},
    TextShadow: {textShadowColor: 'black', textShadowRadius: 2, textShadowOffset: {width: 0, height: 3}}
})