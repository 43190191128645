import React, { useState } from "react"
import { View, Text, StyleSheet, NativeScrollEvent, NativeSyntheticEvent, FlatList, Platform, SafeAreaView } from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import TextInput from "../components/TextInput"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import * as api from '../Api'
import CardProduct from "../components/CardProduct"
import { useNavigation, useRoute } from "@react-navigation/native"
import IProduct from "../models/IProduct"
import Animated, { FadeInUp } from 'react-native-reanimated'
import * as webFirestore from 'firebase/firestore'
import firestore from '@react-native-firebase/firestore'
import LoadingOverlay from "../components/LoadingOverlay"
import { useTranslation } from "react-i18next"
import IWish from "../models/IWish"
import { facebookTrackAddToWishlist } from "../Api"

const style = StyleSheet.create({
    viewNotSearching: {
        flex: 1,
        justifyContent: 'center',
    },
    viewSearching: {
        flex: 1,
        overflow: 'visible'
    },
    scrollView: {
        marginHorizontal: -24,
        paddingHorizontal: 24
    }
})

let abortController = new AbortController()
let nextPageAbortController = new AbortController()

const ScreenWishCreate = () => {

    const navigation = useNavigation<any>()
    const route = useRoute<any>()
    const { t } = useTranslation()
    const [searchText, setSearchText] = useState<string>()
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [products, setProducts] = useState<IProduct[]>([])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [reachedEnd, setReachedEnd] = useState<boolean>(false)
    const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false)
    const [loadingText, setLoadingText] = useState<string>()

    const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        const { layoutMeasurement, contentOffset, contentSize } = e.nativeEvent
        const isCloseToBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height - 400
        if (isCloseToBottom) loadNextPage()
    }

    const createWishFromUrl = async (url: string) => {
        setLoadingText(t('ScreenWishCreate.loading_link'))
        const wishlist = route.params.wishlist
        if(!wishlist) {
            api.showMessage(t('ScreenWishCreate.error', t('ScreenWishCreate.error_text', {message: ''})))
        }

        try {
            await api.wishlistAddWishFromUrl(wishlist, url)
            navigation.navigate('Wishlist', { wishlistId: wishlist.id })
        } catch(e: any) {
            setLoadingText(undefined)
            console.error(e)
            api.showMessage(t('ScreenWishCreate.error'), t('ScreenWishCreate.error_text', {message: e.message}))
            return
        }
    }

    const loadFirstPage = async (query: string) => {

        // Before we do anything, check if the user pasted a link
        if(api.isUrl(query)) {
            createWishFromUrl(query)
            return
        }

        setSearchText(query)
        nextPageAbortController.abort()
        abortController.abort()
        abortController = new AbortController()
        nextPageAbortController = new AbortController()

        if (!query) {
            setProducts([])
            setIsSearching(false)
            return
        }

        try {
            setIsSearching(true)
            setPageNumber(0)
            setReachedEnd(false)
            const products = await api.productSearch(query, pageNumber, abortController.signal)
            setProducts(products)
            setIsSearching(false)
        } catch (e: any) {
            if (e?.message !== 'Aborted') {
                setIsSearching(false)
                setReachedEnd(true)
                setProducts([])
            }
        }
    }

    const loadNextPage = async () => {
        if (reachedEnd || isLoadingNextPage || !searchText) return
        setPageNumber(pageNumber + 1)
        nextPageAbortController.abort()
        nextPageAbortController = new AbortController()

        try {
            setIsLoadingNextPage(true)
            const productsResult = await api.productSearch(searchText, pageNumber + 1, nextPageAbortController.signal)
            if(productsResult.length === 0) {
                setReachedEnd(true)
            }
            setProducts([...products, ...productsResult])
            setIsLoadingNextPage(false)
        } catch (e: any) {
            if (e?.message !== 'Aborted') {
                setIsLoadingNextPage(false)
                setReachedEnd(true)
                return
            }

            setReachedEnd(true)
            setIsLoadingNextPage(false)
        }
    }

    const onPressProduct = (product: IProduct) => {
        const wishlist = route?.params?.wishlist
        const productId = product.id
        navigation.navigate('Product', {productId, product, wishlist})
    }

    const onPressCreateWishManually = () => {
        const wishlist = route?.params?.wishlist 
        navigation.navigate('CreateWishManually', {wishlistId: wishlist.id})
    }

    const [giftCardProducts, setGiftCardProducts] = useState<IProduct[]>([])
    api.useAsyncEffect(async () => {
        const giftCardProducts = await api.productSearch('gavekort', 0)
        setGiftCardProducts(giftCardProducts)
    }, [])

    const renderNoResults = () => {
        return (
            <View style={{alignItems: 'center', flex: 1}}>
                <Divider.Large />
                <Text style={[Typography.Text, {textAlign: 'center'}]}>{t('ScreenWishCreate.notfound_text')}</Text>
                <Button.PrimarySmall text={t('ScreenWishCreate.button_add_manually')} onPress={onPressCreateWishManually} style={{marginTop: 8}} />
                <Divider.Medium />

                <Text style={[Typography.Text, {textAlign: 'center'}]}>{t('ScreenWishCreate.giftcard_text')}</Text>
                
                    {giftCardProducts.map((product, index) => (
                        <CardProduct key={index} product={product} onPress={() => onPressProduct(product)} />
                    ))}
            </View>
        )
    }

    return (
        <View style={PageStyle.default}>
            { !!loadingText && <LoadingOverlay text={loadingText} /> }
            <SafeAreaView style={[PageStyle.fillParent, !searchText && PageStyle.webSafeArea]}>

                {/* Back button */}
                {!searchText && <Animated.View entering={FadeInUp}>
                    <Divider.Medium />
                    <Button.Back text={route?.params?.wishlist?.Title || t('ScreenWishCreate.wishlist')} />
                    <Divider.Medium />
                </Animated.View>}

                <View style={PageStyle.safeArea}>

                    {/* Screen title and subtitle */}
                    {!searchText && <Animated.View entering={FadeInUp}>
                        <Text style={Typography.Title}>{t('ScreenWishCreate.title')}</Text>
                        <Text style={Typography.Subtitle}>{t('ScreenWishCreate.subtitle')}</Text>
                    </Animated.View>}

                    <View style={searchText ? style.viewSearching : style.viewNotSearching}>
                        {!!searchText && <Divider.Medium />}

                        {/* Search query input */}
                        <TextInput 
                            loading={isSearching} 
                            onChangeText={loadFirstPage} 
                            title={t('ScreenWishCreate.input_search')} 
                            placeholder={t('ScreenWishCreate.input_search_placeholder')} />

                        {!searchText && <Divider.Medium />}

                        {/* Search results */}
                        {!!searchText && searchText.length > 2 && !isSearching &&
                            <FlatList
                                data={products}
                                style={style.scrollView}
                                onScroll={onScroll}
                                ListHeaderComponent={() => <Divider.Tiny />}
                                ListFooterComponent={() => products.length === 0 ? renderNoResults() : <Divider.Tiny />}
                                numColumns={Platform.OS === 'web' ? 3 : 1}
                                columnWrapperStyle={Platform.OS === 'web' && { justifyContent: 'space-evenly' }}
                                keyExtractor={item => item.id}
                                renderItem={info => <CardProduct onPress={() => onPressProduct(info.item)} product={info.item} />}
                            />
                        }

                        {/* Add manually button */}
                        {!searchText && <View>
                            <Text style={Typography.Subsubtitle}>{t('ScreenWishCreate.manual_wish')}</Text>
                            <Button.PrimarySmall onPress={onPressCreateWishManually} text={t('ScreenWishCreate.button_add_manually')} />
                        </View>}
                    </View>
                </View>
            </SafeAreaView>
        </View>
    )
}

export default ScreenWishCreate