import { useNavigation } from "@react-navigation/native"
import React, { useState } from "react"
import auth from "@react-native-firebase/auth"
import { Text, View, SafeAreaView, ScrollView, Platform } from "react-native"
import PageStyle from "../style/PageStyle"
import Divider from "../components/Divider"
import Button from "../components/Button"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import * as webAuth from "firebase/auth"
import { getUserFriendlyErrorMessage } from '../Api'
import { showMessage } from "../Api"
import { useTranslation } from "react-i18next"

// Forgot password page
const ScreenForgotPassword = () => {

    const navigation = useNavigation()
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const [loadingText, setLoadingText] = useState<string>()

    const onPressForgotPassword = async () => {
        try {
            setLoadingText(t('ScreenForgotPassword.loading_sending'))
            if(Platform.OS === 'web') {
                const auth = webAuth.getAuth()
                await webAuth.sendPasswordResetEmail(auth, email)
            } else {
                await auth().sendPasswordResetEmail(email)
            }
            showMessage(t('ScreenForgotPassword.dialog_success_title'), t('ScreenForgotPassword.dialog_success_text'))
            navigation.goBack()
        } catch(e: any) {
            showMessage(t('ScreenForgotPassword.error_email_title'), getUserFriendlyErrorMessage(e))
            return
        } finally {
            setLoadingText(undefined)
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                <Divider.Medium />

                <Button.Back text={t('ScreenForgotPassword.home')} />
                <Divider.Medium />
                {Platform.OS === 'web' && <Divider.Gigantic /> }
                <ScrollView style={PageStyle.safeArea}>

                    {/* Screen title and subtitle */}
                    <Text style={Typography.Title}>{t('ScreenForgotPassword.title')}</Text>
                    <Text style={Typography.Subtitle}>{t('ScreenForgotPassword.subtitle')}</Text>
                    <Divider.Medium />

                    {/* User input */}
                    <TextInput 
                        title={t('ScreenForgotPassword.input_email_title')} 
                        onChangeText={setEmail} 
                        autoCapitalize="none" 
                        keyboardType="email-address" />
                    <Divider.Medium />

                    {/* Send link button */}
                    <Button.Primary 
                        text={t('ScreenForgotPassword.button_send_link')} 
                        style={Platform.OS === 'web' && {alignSelf: 'flex-start'}} 
                        loadingText={loadingText} 
                        onPress={onPressForgotPassword} />
                </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ScreenForgotPassword