import { useNavigation, useRoute } from "@react-navigation/native"
import { useEffect, useState } from "react"
import { ScrollView, SafeAreaView, Alert, View, Text, StyleSheet, ImageBackground, TouchableOpacity, Platform } from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import { getUser, getUserFriendlyErrorMessage } from "../Api"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import firestore from "@react-native-firebase/firestore"
import IWishlist from "../models/IWishlist"
import IUserInfo from "../models/IUserInfo"
import IconTrash from "../components/icons/IconTrash"
import auth from "@react-native-firebase/auth"
import * as webFirestore from 'firebase/firestore'
import { showMessage } from "../Api"
import { useTranslation } from "react-i18next"

const style = StyleSheet.create({
    editorContainer: {
        flexDirection: 'row',
    },
    editorProfilePicture: {
        width: 48,
        height: 48,
        borderRadius: 24,
        overflow: 'hidden',
    },
    editorText: {
        alignSelf: 'center',
        marginLeft: 8
    },
    trashIconTouchableOpacity: {
        width: 24,
        height: 24,
        alignSelf: 'center',
        marginLeft: 8
    }
})

const ScreenWishlistAddEditor = () => {

    const route = useRoute<any>()
    const { t } = useTranslation()
    const navigation = useNavigation<any>()
    const [email, setEmail] = useState<string>('')
    const [loadingText, setLoadingText] = useState<string>()
    const wishlistId = route.params.wishlistId
    const [editorUserInfos, setEditorUserInfos] = useState<IUserInfo[]>([])

    const onPressAddEditor = async () => {
        try {
            setLoadingText(t('ScreenWishlistAddEditor.loading_adding'))

            if(Platform.OS === 'web') {
                // Lookup the user by email
                const db = webFirestore.getFirestore()
                const editorRef = webFirestore.doc(db, `editor/${email}`)
                const editorDoc = await webFirestore.getDoc(editorRef)
                if(!editorDoc || !editorDoc.exists) {
                    showMessage(t('ScreenWishlistAddEditor.error_not_found_title'), t('ScreenWishlistAddEditor.error_not_found_text'))
                    return
                }

                // Add the user as an editor
                const editorUid = editorDoc.data()!.uid
                const wishlistRef = webFirestore.doc(db, `wishlist/${wishlistId}`)
                await webFirestore.updateDoc(wishlistRef, {
                    editors: webFirestore.arrayUnion(editorUid)
                })
            } else {
                // Lookup the user by email
                const editor = await firestore().collection('editor').doc(email).get()
                if(!editor.exists) {
                    showMessage(t('ScreenWishlistAddEditor.error_not_found_title'), t('ScreenWishlistAddEditor.error_not_found_text'))
                    return
                }
                
                // Add the user as an editor
                const editorUid = editor.data()!.uid
                await firestore().collection('wishlist').doc(wishlistId).update({
                    editors: firestore.FieldValue.arrayUnion(editorUid)
                })
            }
        } catch(e) {
            showMessage(t('ScreenWishlistAddEditor.info'), getUserFriendlyErrorMessage(e))
            console.error(e)
            return
        } finally {
            setEmail('')
            setLoadingText(undefined)
        }
    }

    const onConfirmRemoveEditor = async (editorUid: string) => {
        if(!editorUid) {
            showMessage(t('ScreenWishlistAddEditor.error'), t('ScreenWishlistAddEditor.error_invalid'))
            return
        }

        try {
            setLoadingText(t('ScreenWishlistAddEditor.loading_removing'))
            if(Platform.OS === 'web') {
                const db = webFirestore.getFirestore()
                const wishlistRef = webFirestore.doc(db, `wishlist/${wishlistId}`)
                await webFirestore.updateDoc(wishlistRef, {
                    editors: webFirestore.arrayRemove(editorUid)
                })
            } else {
                await firestore().collection('wishlist').doc(wishlistId).update({
                    editors: firestore.FieldValue.arrayRemove(editorUid)
                })
            }
            if(editorUid === auth().currentUser?.uid) {
                navigation.navigate('Home')
            }
        } catch(e) {
            showMessage(t('ScreenWishlistAddEditor.info'), `${e}`)
            console.error(e)
            return
        } finally {
            setLoadingText(undefined)
        }
    }

    // Confirm the user wants to remove the editor:
    const onPressRemoveEditor = async (editorUserInfo: IUserInfo) => {
        if(editorUserInfo?.uid === getUser()?.uid) {
            if(Platform.OS === 'web') {
                const confirmation = confirm(t('ScreenWishlistAddEditor.confirm_remove_self'))
                if(confirmation) onConfirmRemoveEditor(editorUserInfo?.uid ?? '')
            } else {
                Alert.alert(t('ScreenWishlistAddEditor.confirm_remove_self_title'), t('ScreenWishlistAddEditor.confirm_remove_self'), [
                    { text: t('ScreenWishlistAddEditor.no'), style: 'cancel' },
                    { text: t('ScreenWishlistAddEditor.yes'), onPress: () => onConfirmRemoveEditor(editorUserInfo?.uid ?? '') }
                ])
            }
        } else {
            if(Platform.OS === 'web') {
                const confirmation = confirm(t('ScreenWishlistAddEditor.confirm_remove_other', {name: editorUserInfo.name}))
                if(confirmation) onConfirmRemoveEditor(editorUserInfo?.uid ?? '')
            } else {
                Alert.alert(t('ScreenWishlistAddEditor.confirm_remove_self_title'), t('ScreenWishlistAddEditor.confirm_remove_other', {name: editorUserInfo.name}), [
                    { text: t('ScreenWishlistAddEditor.no'), style: 'cancel' },
                    { text: t('ScreenWishlistAddEditor.yes'), onPress: () => onConfirmRemoveEditor(editorUserInfo?.uid ?? '') }
                ])
            }
        }
    }

    useEffect(() => {
        if(Platform.OS === 'web') {
            const fetchEditors = async(uids: string[]) => {
                const editors: IUserInfo[] = []
                for(let i=0; i<uids.length; i++) {
                    const editorUid = uids[i]
                    const db = webFirestore.getFirestore()
                    const userInfoRef = webFirestore.doc(db, `userInfo/${editorUid}`)
                    const userInfo = await webFirestore.getDoc(userInfoRef)
                    const userInfoData = userInfo.data() as IUserInfo
                    userInfoData.uid = editorUid
                    editors.push(userInfoData)
                }
                setEditorUserInfos(editors)
            }
            
            const db = webFirestore.getFirestore()
            const wishlistRef = webFirestore.doc(db, `wishlist/${wishlistId}`)
            const unsubscribeWishlist = webFirestore.onSnapshot(wishlistRef, (doc => {
                if(!doc.exists) {
                    showMessage(t('ScreenWishlistAddEditor.info'), t('ScreenWishlistAddEditor.error_wishlist_not_found'))
                    navigation.navigate('Home')
                    return
                }
                const wishlist = doc.data() as IWishlist
                fetchEditors(wishlist.editors)
            }))

            return () => unsubscribeWishlist()
        } else {
            const fetchEditors = async (uids: string[]) => {
                const editors: IUserInfo[] = []
                for(let i=0; i<uids.length; i++) {
                    const editorUid = uids[i]
                    const editorUserInfo = await (await firestore().collection('userInfo').doc(editorUid).get()).data() as IUserInfo
                    editorUserInfo.uid = editorUid
                    editors.push(editorUserInfo)
                }
                setEditorUserInfos(editors)
            }

            const unsubscribeWishlist = firestore().collection('wishlist').doc(wishlistId).onSnapshot(doc => {
                if(!doc.exists) {
                    showMessage(t('ScreenWishlistAddEditor.info'), t('ScreenWishlistAddEditor.error_wishlist_not_found'))
                    navigation.navigate('Home')
                    return
                }
                const wishlist = doc.data() as IWishlist
                fetchEditors(wishlist.editors)
            })

            return () => unsubscribeWishlist()
        }
    }, [])

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={{ flex: 1 }}>
                <Divider.Medium />
                <Button.Back text={t('ScreenWishlistAddEditor.button_back')} />
                <Divider.Medium />
                <ScrollView style={PageStyle.safeArea}>

                    {/* Screen title and subtitle */}
                    <Text style={Typography.Title}>{t('ScreenWishlistAddEditor.title')}</Text>
                    <Text style={Typography.Subtitle}>{t('ScreenWishlistAddEditor.subtitle')}</Text>
                    <Divider.Medium />

                    {/* User input */}
                    <TextInput title={t('ScreenWishlistAddEditor.input_email')} value={email} onChangeText={setEmail} autoCapitalize="none" keyboardType="email-address" />
                    <Divider.Medium />
                    <Button.Primary text={t('ScreenWishlistAddEditor.button_add')} loadingText={loadingText} onPress={onPressAddEditor} />
                    <Divider.Medium />

                    {/* List of editors */}
                    <Text style={Typography.InputTitle}>{t('ScreenWishlistAddEditor.subsubtitle')}</Text>

                    {editorUserInfos.map(editorUserInfo => (
                        <View key={editorUserInfo.uid}>
                            <View style={style.editorContainer}>
                                <ImageBackground source={{ uri: editorUserInfo.image }} style={style.editorProfilePicture} />
                                <Text style={[Typography.Text, style.editorText]}>{editorUserInfo.name}</Text>
                                { editorUserInfos.length > 1 && <TouchableOpacity style={style.trashIconTouchableOpacity} onPress={() => onPressRemoveEditor(editorUserInfo)}>
                                    <IconTrash width={24} height={24} />
                                </TouchableOpacity> }
                            </View>
                            
                            <Divider.Tiny />
                        </View>
                    ))}
                </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ScreenWishlistAddEditor