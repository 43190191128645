import { TouchableOpacity, Image, Text, StyleSheet } from "react-native";
import IProduct from "../models/IProduct";
import Color from "../style/Color";
import Typography from "../style/Typography";
// @ts-ignore
import html2plaintext from 'html2plaintext'
import {decode} from 'html-entities'
import PriceComparison from "./PriceComparison";
import Divider from "./Divider";
import Button from "./Button";
import { useNavigation } from "@react-navigation/native";
import { defaultProductPicture } from "../Api";
import PageStyle from "../style/PageStyle";
import { useTranslation } from "react-i18next";

const style = StyleSheet.create({
    container: {
        ...PageStyle.Shadow,
        marginVertical: 4,
        padding: 16,
        paddingVertical: 12,
        borderRadius: 22.5,
        borderTopWidth: 1,
        borderTopColor: Color.DarkTransparentMore,
        backgroundColor: Color.Background,
        margin: 2
    },
    image: {
        width: '100%',
        height: 250,
        backgroundColor: Color.White,
        resizeMode: 'contain',
        borderRadius: 22.5,
    },
    title: {

    },
    description: {

    }
})

interface CardProductLarge {
    product: IProduct
    onPress?: () => void
}

const CardProductLarge = (props: CardProductLarge) => {

    const navigation = useNavigation<any>()
    const { t } = useTranslation()

    let discount = 0
    try {
        const priceDelta = Number(props.product.originalPrice) - Number(props.product.price)
        discount = Math.round(priceDelta / Number(props.product.originalPrice) * 100)
    } catch(e) {
        // This is just there in case there is an integer parsing error
        // No need to inform the user
    }

    // Navigate to wishlist selection screen
    const onPressAddToWishlist = () => {
        const product = props.product
        navigation.navigate('AddWishSelectWishlist', { product }) 
    }

    let imageUri = defaultProductPicture
    if(props.product.urlImage && props.product.urlImage.length > 0) {
        imageUri = props.product.urlImage
    }

    return (
        <TouchableOpacity onPress={props.onPress} style={style.container}>
            <Text style={Typography.Text}>{props.product.name}</Text>
            <Image style={style.image} source={{uri: imageUri}} />
            <Divider.Small />

            {/* Discount */}
            {discount > 0 && <>
                <Text style={Typography.Subsubtitle}>{t('CardProductLarge.discount')}</Text>
                <Text>{t('CardProductLarge.discount_text', {percent: `${discount}`, original: `${props.product.originalPrice}`})}</Text>
                <Divider.Small />
            </>}

            {/* Description */}
            {(props.product?.description?.length ?? 0) > 0 && <>
                <Text style={Typography.Subsubtitle}>{t('CardProductLarge.description')}</Text>
                <Text style={Typography.TextSmall}>{html2plaintext(decode(props.product.description))}</Text>
                <Divider.Tiny />
            </>}
            
            {/* Price comparison */}
            {props.product.ean.length > 0 && <PriceComparison ean={props.product.ean} />}

            {/* Wishlist actions */}
            <Divider.Tiny />
            <Button.PrimarySmall text={t('CardProductLarge.button_add')} onPress={onPressAddToWishlist} />

        </TouchableOpacity>
    )
}

export default CardProductLarge