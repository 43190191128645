import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

function IconGiftDisabled(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <G clipPath="url(#prefix__clip0_719_627)" fill="#BBBBBB">
        <Path d="M54.667 64H9.333c-2.205 0-4-1.795-4-4V33.333c0-.736.598-1.333 1.334-1.333h50.666c.736 0 1.334.597 1.334 1.333V60c0 2.205-1.795 4-4 4zM8 34.667V60c0 .733.6 1.333 1.333 1.333h45.334c.733 0 1.333-.6 1.333-1.333V34.667H8z" />
        <Path d="M60 34.667H4c-2.205 0-4-1.795-4-4v-8c0-2.206 1.795-4 4-4h56c2.205 0 4 1.794 4 4v8c0 2.205-1.795 4-4 4zM4 21.333c-.733 0-1.333.6-1.333 1.334v8C2.667 31.4 3.267 32 4 32h56c.733 0 1.333-.6 1.333-1.333v-8c0-.734-.6-1.334-1.333-1.334H4z" />
        <Path d="M32 21.333c-.387 0-.755-.165-1.008-.461a1.32 1.32 0 01-.312-1.059C30.71 19.613 33.69 0 49.333 0c6.891 0 9.336 3.723 9.336 6.91 0 5.989-8.274 14.423-26.669 14.423zM49.333 2.667C38.368 2.667 34.63 14.36 33.63 18.64c14.611-.453 22.374-6.976 22.374-11.73 0-1.185-.499-2.16-1.478-2.894-1.173-.883-2.97-1.35-5.192-1.35z" />
        <Path d="M32.003 21.333c-18.395 0-26.67-8.434-26.67-14.424 0-2.01.904-3.797 2.542-5.026C9.515.65 11.864 0 14.669 0c15.643 0 18.624 19.613 18.654 19.813.056.384-.059.768-.312 1.059a1.323 1.323 0 01-1.008.461zM14.669 2.667C10.555 2.667 8 4.29 8 6.909c0 4.758 7.768 11.28 22.387 11.734-.968-4.307-4.614-15.976-15.718-15.976z" />
        <Path d="M32 64a1.334 1.334 0 01-1.333-1.333V20a1.334 1.334 0 012.666 0v42.667c0 .736-.597 1.333-1.333 1.333z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_719_627">
          <Path fill="#fff" d="M0 0h64v64H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default IconGiftDisabled;