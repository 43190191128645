import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryTravel(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 28 24"
      fill="none"
      {...props}
    >
      <Path
        d="M23.719.781a2.911 2.911 0 00-1.125.344l-4.719 2.5L11.5 2.063l-.375-.094-.375.187-2.156 1.25-1.281.75 1.187.907 2.719 2.062-3.406 1.813L4.155 7.28l-.437-.187-.438.219-1.75.937-1.156.625.875.938 5.406 5.812.5.594.688-.375L13 13.094l-1.031 5.687-.344 1.813 1.719-.719 2.562-1.094.375-.156.157-.375 3.718-9.031 5.25-2.813c1.446-.777 2.028-2.617 1.25-4.062A2.988 2.988 0 0024.875.906a3.12 3.12 0 00-1.156-.125zm.187 2c.125-.008.254-.004.375.032a.979.979 0 01.188 1.812l-5.594 3.031-.313.157-.125.343-3.718 8.938-.438.187 1.063-5.906.375-2.031-1.813.969-6.312 3.406-3.969-4.313.156-.093 3.656 1.624.47.22.405-.25 15.22-8.032a.886.886 0 01.374-.094zM11.375 4.094l3.844.937-2.063 1.063-2.25-1.719.469-.281zM1 22v2h26v-2H1z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryTravel;