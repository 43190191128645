import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconViewCards(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <Path
        d="M26.094 5.813H7.812a2 2 0 00-2 2v18.28a2 2 0 002 2h18.282a2 2 0 002-2V7.814a2 2 0 00-2-2zM54.188 5.813H35.906a2 2 0 00-2 2v18.28a2 2 0 002 2h18.282a2 2 0 002-2V7.814a2 2 0 00-2-2zM26.094 33.906H7.812a2 2 0 00-2 2v18.282a2 2 0 002 2h18.282a2 2 0 002-2V35.906a2 2 0 00-2-2zM54.188 33.906H35.906a2 2 0 00-2 2v18.282a2 2 0 002 2h18.282a2 2 0 002-2V35.906a2 2 0 00-2-2z"
        stroke="#4D335E"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default IconViewCards;