import React, { useState } from "react"
import { ActivityIndicator, Image, Platform, ScrollView, StyleSheet, Text, View, SafeAreaView } from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import Color from "../style/Color"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import * as api from '../Api'
import CardProduct from "../components/CardProduct"
import { useNavigation, useRoute } from "@react-navigation/native"
import WebView from "react-native-webview"
import * as Linking from "expo-linking"
import IWishlist from "../models/IWishlist"
import {decode} from 'html-entities'
import IProduct from "../models/IProduct"
import IWish from "../models/IWish"
// @ts-ignore
import html2plaintext from 'html2plaintext'
import { getUser } from "../Api"
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useTranslation } from "react-i18next"
import PriceComparison from "../components/PriceComparison"

const style = StyleSheet.create({
    image: {
        alignSelf: 'stretch',
        aspectRatio: 1,
        borderRadius: 22.5,
    },
    imageShadow: {
        shadowColor: Color.Dark,
        shadowRadius: 5,
        shadowOffset: {width: 0, height: 2},
        borderRadius: 22.5,
        elevation: 6,
        maxWidth: Platform.OS === 'web' ? 256 : undefined,
        aspectRatio: Platform.OS === 'web' ? 1 : undefined,
        backgroundColor: Color.ImageBackground,
        borderColor: Color.DarkTransparentMore,
        borderWidth: 1
    },
    textPrice: {
        color: Color.Secondary,
        width: 200
    },
    textLeft: {
        alignSelf:  'flex-start',
        maxWidth: 250,
    },
    textRight: {
        flex: 1,
        textAlign: 'right'
    },
    scrollView: {
        paddingHorizontal: 24,
        alignSelf: 'stretch'
    },
    activityIndicator: {
        alignSelf: 'center'
    },
    webView: {
        height: 500, 
        borderRadius: 22.5, 
        overflow: 'hidden', 
        flex: 1,
        shadowColor: Color.Dark,
        shadowRadius: 5,
        shadowOffset: {width: 0, height: 2},
        elevation: 6,
    }
})

const ScreenWish = () => {

    const navigation = useNavigation<any>()
    const route = useRoute<any>()
    const { t } = useTranslation()
    const wish: IWish = route.params?.wish

    const [sameCategoryProducts, setSameCategoryProducts] = useState<IProduct[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [didReserve, setReserved] = useState(wish?.reservations?.includes(getUser()?.uid ?? ''))
    const [reserveLoadingText, setReserveLoadingText] = useState<string | undefined>(' ')
    const [hasUrl, setHasUrl] = useState(false)
    const [ean, setEan] = useState<string>()

    const wishlist: IWishlist = route.params?.wishlist
    const wishlistId: string = wishlist.id
    if(!wish || !wishlistId) navigation.goBack()
    const editMode = wishlist.editors.includes(getUser()?.uid ?? '')

    const isLoggedIn = getUser() !== null
    const offlineReservationKey = `reservation-${wish.id ?? ''}`
    const hasDescription = (wish?.description?.length ?? 0) > 0
    const hasComment = (wish.comment?.length ?? 0) > 0
    const hasImage = (wish?.urlImage?.length ?? 0) > 0
    const hasPrice = (wish?.price?.length ?? 0) > 0
    const hasPriceComparison = (ean?.length ?? 0) > 0
    const hasShipping = (wish?.shippingPrice?.length ?? 0) > 0
    const hasDiscount = wish?.originalPrice && wish?.price && wish?.price < wish?.originalPrice
    const discountPercentage = Math.round(hasDiscount ? (
        (Number(wish?.originalPrice) - Number(wish?.price)) / Number(wish?.originalPrice) * 100
    ) : 0)

    // Check if the wish url is valid, and can be opened 
    api.useAsyncEffect(async () => {
        // Ensure there is a wish
        if(!wish || !wish.url) return  

        // Check if we can open the url
        const canOpenUrl = await Linking.canOpenURL(wish.url)
        setHasUrl(canOpenUrl)
    }, [])

    // Get product EAN if this wish is a product
    api.useAsyncEffect(async () => {
        if(!wish.productId) return
        const product = await api.getProduct(wish.productId)
        setEan(product?.ean)
    }, [])

    // Check if this wish is already reserved by the current user
    api.useAsyncEffect(async () => {
        if(!wish) return
        if(editMode) {
            setReserveLoadingText(undefined)
            return
        }

        // Check if the user is annonymous, and already reserved this wish
        const user = getUser()
        if(!user) {
            const reserved = await AsyncStorage.getItem(offlineReservationKey)
            setReserved(reserved === 'true')
            setReserveLoadingText(undefined)
            return
        }

        // Check if the user is logged in, and already reserved this wish
        const didReserveWish = await api.getUserDidReserveWish(wish)
        setReserved(didReserveWish)

        // Stop loading
        setReserveLoadingText(undefined)
    }, [])

    // Fetch products in the same category as this wish
    api.useAsyncEffect(async () => {
        if(!wish) return
        try {
            // If the wish has a category...
            if(wish?.category) {
                // ...search for products in the same category
                const products = await api.productGetCategory(wish?.category)
                setSameCategoryProducts(products)
            } else {
                // ...otherwise, search for products with the same name
                const products = await api.productSearch(wish?.name, 0)
                setSameCategoryProducts(products)
            }
        } catch(e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }, [])
    
    // Navigate to product page, if a suggested product was pressed
    const onPressProduct = (product: IProduct) => {
        const productId = product.id
        navigation.push('Product', {product, wishlist, productId})
    }

    // Reserve a wish
    const onPressReserve = async () => {
        
        // Unreseve the wish, if we already reserved it
        if(didReserve) return onPressUnreserve()

        // If the user is not logged in, save the reservation locally
        if(!isLoggedIn) {
            await AsyncStorage.setItem(offlineReservationKey, 'true')
            setReserved(true)
        }

        setReserveLoadingText('Reserverer...')
        // Get product from product id if it exists
        let product: null | IProduct = null
        if(wish.productId) {
            product = await api.getProduct(wish.productId)
        }

        // Reserve the wish
        try {
            api.wishReserve(wishlist, wish, product)
            setReserved(true)
        } catch(e) {
            console.error(e)
            api.showMessage(t('ScreenWish.error'), t('ScreenWish.error_reservation'))
        } finally {
            setReserveLoadingText(undefined)
        }
    }

    // Unreserve a wish
    const onPressUnreserve = async () => {
        setReserveLoadingText('Fjerner...')

        // If the user is not logged in, remove the reservation locally
        if(!isLoggedIn) {
            await AsyncStorage.removeItem(offlineReservationKey)
        }

        // Unreserve the wish
        try {
            api.wishUnreserve(wishlist, wish)
            setReserved(false)
        } catch(e) {
            console.error(e)
            api.showMessage(t('ScreenWish.error'), t('ScreenWish.error_reservation'))
        } finally {
            setReserveLoadingText(undefined)
        }
    }

    // Open the wish url, if it exists
    const onPressOpenInBrowser = async () => {
        if(!hasUrl) return
        api.openUrl({
            url: wish.url, 
            productId: wish.productId
        })
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>

                {/* Back button */}
                <Divider.Medium />
                <Button.Back text={t('ScreenWish.button_back')} />
                <Divider.Small />

                <ScrollView contentContainerStyle={style.scrollView} nestedScrollEnabled={true}>

                    {/* Product image */}
                    {hasImage && <View>
                        <View style={style.imageShadow}>
                            <Image style={style.image} source={{uri: wish?.urlImage}} />
                        </View>
                        <Divider.Medium />
                    </View>}

                    {/* Product name and price */}
                    <Text style={Typography.Subsubtitle}>{t('ScreenWish.info')}</Text>
                    <View style={PageStyle.row}>
                        <Text style={[Typography.ProductName, style.textLeft]}>{wish?.name}</Text>
                        {hasPrice && <Text style={[Typography.Text, style.textRight, style.textPrice]}>{wish?.price} kr.</Text>}
                    </View>

                    {/* Product discount information (if any) */}
                    {hasDiscount && <View style={PageStyle.row}>
                        <Text style={[Typography.Text, style.textLeft]}>{t('ScreenWish.save')} {discountPercentage}%</Text>
                        <Text style={[Typography.ProductPriceBefore, style.textRight]}>{wish?.originalPrice} kr.</Text>
                    </View>}

                    {/* Free shipping text (if any) */}
                    {hasShipping && <View>
                        <Text style={Typography.Text}>{t('ScreenWish.free_delivery')}</Text>
                        <Divider.Tiny />
                    </View>}

                    {/* Reserve button */}
                    {!!wishlist && !editMode && <View>
                        <Button.PrimarySmall loadingText={reserveLoadingText} text={didReserve ? t('ScreenWish.button_reservation_remove') : t('ScreenWish.button_reserve')} onPress={onPressReserve} />
                        <Divider.Tiny />
                    </View> }

                    {/* Price comparison */}
                    {hasPriceComparison && <>
                        <PriceComparison ean={ean!} />
                        <Divider.Tiny />
                    </>}

                    {/* Buy button */}
                    {hasUrl && <View> 
                        <Button.PrimarySmall text={t('ScreenWish.button_buy')} onPress={onPressOpenInBrowser} />
                        <Divider.Medium />
                    </View> } 

                    {/* User comment */}
                    {hasComment && <View>
                        <Text style={Typography.Subsubtitle}>{t('ScreenWish.comment')}</Text>
                        <Text style={Typography.Text}>{wish?.comment}</Text>
                        <Divider.Medium />
                    </View>}

                    {/* Product description */}
                    {hasDescription && <View>
                        <Text style={Typography.Subsubtitle}>{t('ScreenWish.description')}</Text>
                        <Text style={Typography.Text}>{html2plaintext(decode(wish?.description))}</Text>
                        <Divider.Medium />
                    </View>}

                    {/* Product website */}
                    {hasUrl && Platform.OS !== 'web' && <View>
                        <Text style={Typography.Subsubtitle}>{t('ScreenWish.product_site')}</Text>
                        <WebView 
                            style={{opacity: 0.99 /* Prevent crash with react navigation, do not remove */}} 
                            sharedCookiesEnabled={true} 
                            nestedScrollEnabled={true} 
                            thirdPartyCookiesEnabled={true} 
                            scalesPageToFit={true} 
                            startInLoadingState={true} 
                            javaScriptEnabled={true}
                            scrollEnabled={true} 
                            containerStyle={style.webView} 
                            source={{uri: wish?.url}} />
                        <Divider.Small />

                        {/* Open in browser button */}
                        <Button.PrimarySmall onPress={onPressOpenInBrowser} text={t('ScreenWish.open_browser')} />
                        <Divider.Medium />
                    </View>}

                    {/* Loading indicator */}
                    {isLoading && <ActivityIndicator size='large' color={Color.Primary} style={style.activityIndicator}/>}

                    {/* Products in same category */}
                    {sameCategoryProducts.length > 0 && <Text style={Typography.Subsubtitle}>
                        {(wish?.category?.length ?? 0 > 0) ? t('ScreenWish.same_category') : t('ScreenWish.inspiration')}    
                    </Text>}
                    {sameCategoryProducts.length > 0 && <View>
                        {sameCategoryProducts?.map(product => <CardProduct product={product} key={product.id} onPress={() => onPressProduct(product)} />)}
                    </View>}

                    <Divider.Large />
                </ScrollView>
            </SafeAreaView>
        </View>
    )

}


export default ScreenWish