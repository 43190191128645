import { useNavigation } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import {ScrollView, View, SafeAreaView, StyleSheet, Platform, Image, Text} from 'react-native'
import Gift from '../components/Gift'
import PageStyle from '../style/PageStyle'
import * as webAuth from 'firebase/auth'
import Divider from '../components/Divider'
import Logo from '../assets/adaptive-icon.png'
import Typography from '../style/Typography'
import Button from '../components/Button'
import Swiper from 'react-native-web-swiper'

import Screenshot1 from '../assets/landing/screenshot_1.png'
import Screenshot2 from '../assets/landing/screenshot_2.png'
import Screenshot3 from '../assets/landing/screenshot_3.png'
import Screenshot4 from '../assets/landing/screenshot_4.png'
import Screenshot5 from '../assets/landing/screenshot_5.png'
import Screenshot6 from '../assets/landing/screenshot_6.png'
import Screenshot7 from '../assets/landing/screenshot_7.png'
import Screenshot8 from '../assets/landing/screenshot_8.png'
import Screenshot9 from '../assets/landing/screenshot_9.png'
import CardWishlistImage from '../assets/landing/card.png'
import ScreenshotVisuals from '../assets/landing/screenshot_visuals.png'
import Illustration1 from '../assets/landing/illustration_1.png'
import Illustration2 from '../assets/landing/illustration_2.png'
import Illustration3 from '../assets/landing/illustration_3.png'
import Illustration4 from '../assets/landing/shopping.png'
import CardSmall from '../assets/landing/card_small.png'
import IconPdf from '../assets/landing/pdf.png'
import IconPrinter from '../assets/landing/printer.png'
import { TouchableOpacity } from 'react-native-gesture-handler'
import * as Device from 'expo-device'
import * as Linking from 'expo-linking'
import Color from '../style/Color'
import AsyncStorage from '@react-native-async-storage/async-storage'

const style = StyleSheet.create({
    containerLogo: {
        width: 256, 
        height: 256, 
        justifyContent: 'center', 
        alignItems: 'center', 
        alignSelf: 'center'
    },
    content: {
        width: '100%',
        maxWidth: 1024,
        alignItems: 'center'
    }
})

const ScreenLandingPageWeb = () => {

    const navigation = useNavigation<any>()
    const [giftIndex, setGiftIndex] = useState(-1)

    // Redirect to home screen if already logged in
    useEffect(() => {
        if(Platform.OS === 'web') {
            const unsubscribeUser = webAuth.getAuth().onAuthStateChanged(user => {
                if(user) navigation.navigate('Home')
            })
            return unsubscribeUser
        }
    }, [])

    // Rotate gift icon
    useEffect(() => {
        const updateInterval = () => {
            setGiftIndex(giftIndex => {
                if(giftIndex === 26) {
                    clearInterval(interval)
                    interval = setInterval(updateInterval, 5000)
                }
                if(giftIndex === -1) {
                    clearInterval(interval)
                    interval = setInterval(updateInterval, 100)
                }
                return (giftIndex + 1 < 27) ? (giftIndex + 1) : -1
            })   
        }

        let interval = setInterval(updateInterval, 100)
        return () => clearInterval(interval)
    }, [])

    const onPressCreateWishlist = async () => {
        let url: string | null = null
        switch(Device.osName) {
            case 'Android':
                url = 'https://play.google.com/store/apps/details?id=nu.oenskeliste'
                break
            case 'iOS':
                url = 'https://apps.apple.com/dk/app/%C3%B8nskeliste/id1441878692'
                break
        }

        if(url === null) {
            navigation.navigate('Home')
        } else {
            if(await Linking.canOpenURL(url)) {
                await Linking.openURL(url)
            }
        }
    }

    const renderGift = () => {
        switch(giftIndex) {
            case -1:
                return <Image source={Logo} style={{width: 128, height: 128}} />
            default:
                return <Gift index={giftIndex} />
        }
    }

    /* Cookie policy that should be displayed absolutely positioned at the bottom right of the screen
    It should contain a link to the cookie policy page, and a button to accept or reject the policy
    If the user accepts the policy, the cookie policy should be hidden
    It uses AsyncStorage to store the user's choice:*/

    const [cookiePolicyAccepted, setCookiePolicyAccepted] = useState(true)
    useEffect(() => {
        const checkCookiePolicy = async () => {
            const accepted = await AsyncStorage.getItem('cookiePolicy')
            if(accepted === null) {
                setCookiePolicyAccepted(false)
            }
        }
        checkCookiePolicy()
    }, [])

    const renderCookiePolicy = () => {
        if(!cookiePolicyAccepted) {
            return (
                <View style={{borderTopLeftRadius: 22.5, borderWidth: 1, borderColor: Color.Primary, position: 'absolute', bottom: 0, right: 0, width: 300, height: 250, backgroundColor: 'white', padding: 16, justifyContent: 'space-between'}}>
                    <Text style={[Typography.Text]}>Denne hjemmeside bruger cookies til at huske dine indstillinger og statistik. Ved at bruge hjemmesiden accepterer du vores cookie politik.</Text>
                    <View style={{flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <Button.SecondarySmall text="Accepter" style={{paddingHorizontal: 16}} onPress={async () => {
                            await AsyncStorage.setItem('cookiePolicy', 'true')
                            setCookiePolicyAccepted(true)
                        }} />
                        <Button.PrimarySmall text="Læs mere" style={{paddingHorizontal: 16}} onPress={onPressCookiePolicy} />
                    </View>
                </View>
            )
        } else {
            return <View />
        }
    }


    const onPressCookiePolicy = () => {
        window.open('/cookie-policy.html', '_blank')
    }

    const onPressPrivacyPolicy = () => {
        window.open('/privacy-policy.html', '_blank')
    }

    const onPressContact = () => {
        window.open('mailto://fred@wituz.com', '_blank')
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent]}>
                <ScrollView style={PageStyle.fillParent}>
                    <View style={PageStyle.webSafeArea}>
                    <Divider.Gigantic />
                    <View style={style.containerLogo}>
                        {renderGift()}
                        <Text style={[Typography.Title, {fontSize: 50}]}>Ønskelister</Text>
                        <Text style={Typography.Subtitle}>Til alle begivenheder</Text>
                        <Divider.Medium />
                        <Button.Primary onPress={onPressCreateWishlist} text='Opret ønskeliste' style={{width: 312}} />
                        <Divider.Huge />
                    </View>
                    <Divider.Gigantic />

                    <View style={style.content}>
                        <Text style={Typography.Subtitle}>Skærmbilleder</Text>
                        <View style={{width: 350, height: 670, alignSelf: 'center'}}>
                            {/* @ts-ignore, children prop missing in external dependency, but still works */}
                            <Swiper loop
                                innerContainerStyle={{flex: 1}}>
                                <Image source={Screenshot1} style={{alignSelf: 'center', width: 257, height: 625}} />
                                <Image source={Screenshot2} style={{alignSelf: 'center', width: 257, height: 625}} />
                                <Image source={Screenshot3} style={{alignSelf: 'center', width: 257, height: 625}} />
                                <Image source={Screenshot4} style={{alignSelf: 'center', width: 257, height: 625}} />
                                <Image source={Screenshot5} style={{alignSelf: 'center', width: 257, height: 625}} />
                                <Image source={Screenshot6} style={{alignSelf: 'center', width: 257, height: 625}} />
                                <Image source={Screenshot7} style={{alignSelf: 'center', width: 257, height: 625}} />
                                <Image source={Screenshot8} style={{alignSelf: 'center', width: 257, height: 625}} />
                                <Image source={Screenshot9} style={{alignSelf: 'center', width: 257, height: 625}} />
                            </Swiper>
                        </View>
                        <Divider.Huge />

                        <View style={[PageStyle.row]}>
                            <View style={{width: 340, height: 543, justifyContent: 'space-between', marginRight: 24, marginBottom: 24}}>
                                <View style={{width: 340, alignSelf: 'stretch', flex: 1}} />
                                <Text style={[Typography.Title, {textAlign: 'right', fontSize: 50}]}>{`Flotte,\nvisuelle\nønskelister`}</Text>
                                <Text style={[Typography.Subtitle, {textAlign: 'right'}]}>Udfold dig, og find passende billeder til din ønskeliste</Text>
                                <Divider.Small />
                                <Image source={CardWishlistImage} style={{width: 335, height: 207}} />
                            </View>
                            <Image source={ScreenshotVisuals} style={{width: 261, height: 543}} />
                        </View>

                        <Divider.Huge />
                        <Button.Primary onPress={onPressCreateWishlist} text='Opret ønskeliste' style={{width: 312}} />
                        <Divider.Huge />

                        <View style={{width: '100%', maxWidth: 591}}>
                            <Text style={[Typography.Title, {fontSize: 50}]}>Tilføj ønsker</Text>
                            <Text style={[Typography.Text]}>{`Du kan tilføje ønsker til din ønskeliste, på den måde, du plejer. Både bare som simpel tekst, men også men links til butikker, billeder og meget mere.\n\nDenne app og hjemmeside er lavet til at tilpasse sig dine behov, hvad end du plejer at gøre med ønskelister.`}</Text>
                            <Text style={[Typography.Text]}>{`Nedenfor kan du se, hvordan du kan tilføje alle slags ønsker.`}</Text>
                            <Divider.Huge />
                            
                            <Text style={[Typography.Subtitle]}>Fra et link</Text>
                            <Text style={[Typography.Text]}>Hvis du ønsker dig noget fra en hjemmeside, kan du bare indsætte linket - så finder appen automatisk ønsket med billede, titel og beskrivelse af det du ønsker dig.</Text>
                            <Divider.Small />
                            <Image source={Illustration1} style={{width: '100%', maxWidth: 591, height: 494}} />
                            <Divider.Huge />

                            <Text style={[Typography.Subtitle]}>Fra et produkt</Text>
                            <Text style={[Typography.Text]}>{`Ønsker du dig et bestemt produkt, kan du søge på det, og nemt tilføje det til din ønskeliste.\n\nHerefter finder appen automatisk produktet, billede, tekst, pris, beskrivelse og et link til en hjemmeside hvor man kan købe produktet.\n\nDet gør det nemt for dig, og for dem, der skal købe gaven.`}</Text>
                            <Divider.Small />
                            <Image source={Illustration2} style={{width: 581, height: 547}} />
                            <Divider.Huge />

                            <Text style={[Typography.Subtitle]}>Bare som tekst</Text>
                            <Text style={[Typography.Text]}>Hvis du bare vil lave en helt standard ønskeliste uden links eller billeder er der selvfølgelig også mulighed for det.</Text>
                            <Divider.Small />
                            <Image source={Illustration3} style={{width: 581, height: 551}} />
                            <Divider.Huge />
                            <Button.Primary onPress={onPressCreateWishlist} text='Opret ønskeliste' style={{width: 312}} />
                            <Divider.Huge />

                            <View style={[PageStyle.row, {width: '100%'}]}>
                                <Image source={CardSmall} style={{width: 210, height: 290}} />
                                <View style={{alignSelf: 'stretch', flex: 1, justifyContent: 'center', paddingLeft: 20}}>
                                    <Text style={[Typography.Subtitle]}>Gæster kan reservere</Text>
                                    <Text style={[Typography.Text]}>Gæster, venner, familie eller andre du deler den ønskeliste med, kan reservere ønskerne. På den måde undgår du at få flere af den samme ting.</Text>
                                </View>
                            </View>

                            <View style={[PageStyle.row, {width: '100%'}]}>
                                <Image source={IconPdf} style={{width: 210, height: 290, resizeMode: 'contain'}} />
                                <View style={{alignSelf: 'stretch', flex: 1, justifyContent: 'center', paddingLeft: 20}}>
                                    <Text style={[Typography.Subtitle]}>Eksporter til PDF</Text>
                                    <Text style={[Typography.Text]}>Vil du ikke have at dine gæster skal bruge appen? Du kan eksportere hele ønskelisten med billeder, links og pris til PDF.</Text>
                                </View>
                            </View>

                            <View style={[PageStyle.row, {width: '100%'}]}>
                                <Image source={IconPrinter} style={{width: 210, height: 290, resizeMode: 'contain'}} />
                                <View style={{alignSelf: 'stretch', flex: 1, justifyContent: 'center', paddingLeft: 20}}>
                                    <Text style={[Typography.Subtitle]}>Print ønskelister ud</Text>
                                    <Text style={[Typography.Text]}>Hvis du vil sende listen med posten, eller give dine nære den på et stykke papir, er der også mulighed for det. Ønskelisten kan printes ud, såfremt din telefon er tilkoblet en printer.</Text>
                                </View>
                            </View>
                            <Button.Primary onPress={onPressCreateWishlist} text='Opret ønskeliste' style={{width: 312}} />
                            <Divider.Huge />

                            <View style={{alignSelf: 'stretch', flex: 1, justifyContent: 'center', paddingLeft: 20}}>
                                <Text style={[Typography.Subtitle]}>Flere om samme liste</Text>
                                <Text style={[Typography.Text]}>Det er muligt, at lave en ønskeliste sammen. Alle der laver listen kan på samme tid oprette, redigere og flytte ønsker, samt alt andet der hører til. Ændringerne sker i real-time, så i kan med det samme se, det hinanden har lavet. Det er rigtig lækkert, og real-time synkroniseringen gør, at i får det bedste resultat til jeres ønskeliste.</Text>
                            </View>
                            <Divider.Huge />

                            <View style={{alignSelf: 'stretch', flex: 1, justifyContent: 'center', paddingLeft: 20}}>
                                <Text style={[Typography.Subtitle]}>Virker som hjemmeside og app</Text>
                                <Text style={[Typography.Text]}>Denne ønskeliste platform virker både på web, iOS og Android. Du kan lave og redigere lister over alt.</Text>
                            </View>
                            <Divider.Huge />

                            <View style={{alignSelf: 'stretch', flex: 1, justifyContent: 'center', paddingLeft: 20}}>
                                <Text style={[Typography.Subtitle]}>Brugersystem</Text>
                                <Text style={[Typography.Text]}>For at oprette en ønskeliste opretter du en bruger, så du altid kan logge ind fra alle steder og se redigere lister. Dine gæster behøves ikke at oprette en bruger for at se eller redigere dine ønsker. De kan bare modtage et link, se ønskerne og reservere dem.</Text>
                            </View>
                            <Divider.Huge />

                            <View style={{alignSelf: 'stretch', flex: 1, justifyContent: 'center', paddingLeft: 20}}>
                                <Text style={[Typography.Subtitle]}>Hjælper med indkøb</Text>
                                <Text style={[Typography.Text]}>Hvis du reserverer noget på andres lister, vil der blive lavet en oversigt til dig over, hvad du har købt og mangler at købe. På den måde behøves du ikke selv at holde styr på, hvad du har købt og ikke har købt. Perfekt til at handle online, eller til at tage med i shopping center til julehandlen.</Text>
                            </View>
                            <Image source={Illustration4} style={{width: 581, height: 551, resizeMode: 'contain'}} />
                            <Divider.Huge />

                            <View style={{alignSelf: 'stretch', flex: 1, justifyContent: 'center', paddingLeft: 20}}>
                                <Text style={[Typography.Subtitle]}>Udforsk</Text>
                                <Text style={[Typography.Text]}>Se hvilke ønsker, der er populære, og bliv inspireret til hvad der skal være på din egen ønskeliste.</Text>
                            </View>
                            <Divider.Huge />

                            <Divider.Huge />
                        </View>
                        </View>
                    </View>
                    <Divider.Gigantic />
                    <View style={{
                        backgroundColor: Color.DarkTransparentMore, 
                        flexDirection: 'row', 
                        justifyContent: 'space-evenly',
                        paddingVertical: 20,
                        borderTopWidth: 1,
                        borderTopColor: Color.DarkTransparentMore,
                    }}>
                        <Image source={Logo} style={{width: 64, height: 64, marginTop: -23}} />
                        <TouchableOpacity onPress={onPressCookiePolicy}>
                            <Text>Cookie politik</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={onPressPrivacyPolicy}>
                            <Text>Privatlivspolitik</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={onPressContact}>
                            <Text>Kontakt</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>

                <>
                    { renderCookiePolicy() }
                </>

            </SafeAreaView>
        </View>
    )

}

export default ScreenLandingPageWeb