import * as React from "react";
import Svg, { SvgProps, Circle, Path } from "react-native-svg";

function IconCheckFull(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <Circle cx={16} cy={16} r={16} fill="#4D335E" />
      <Path
        d="M24.54 9L12.89 21.796l-4.43-4.865L7 18.534 12.889 25 26 10.602 24.54 9z"
        fill="#fff"
      />
    </Svg>
  );
}

export default IconCheckFull;