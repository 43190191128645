import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

function IconTrash(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <G clipPath="url(#prefix__clip0_719_741)" fill="#4D335E">
        <Path d="M54.623 10.932h-1.824v-2.06a2.828 2.828 0 00-2.825-2.825h-8.301V3.742A3.746 3.746 0 0037.93 0h-4.92a.937.937 0 100 1.875h4.92a1.87 1.87 0 011.867 1.867v2.305H24.202V3.742a1.87 1.87 0 011.867-1.867h2.572a.937.937 0 000-1.875H26.07a3.746 3.746 0 00-3.742 3.742v2.305h-8.301a2.828 2.828 0 00-2.825 2.825v2.06H9.377a2.02 2.02 0 00-2.017 2.017v5.407a2.02 2.02 0 002.017 2.017h.155l4.024 38.074A6.193 6.193 0 0019.727 64h24.546a6.192 6.192 0 006.17-5.553l3.28-31.03a.937.937 0 10-1.864-.197l-3.28 31.03a4.322 4.322 0 01-4.306 3.876H19.727a4.322 4.322 0 01-4.306-3.876l-4.003-37.877h41.165l-.269 2.541a.937.937 0 101.864.198l.29-2.739h.155a2.02 2.02 0 002.017-2.017v-5.407a2.02 2.02 0 00-2.017-2.017zm-41.547-2.06c0-.524.426-.95.95-.95h35.948c.524 0 .95.426.95.95v2.06H13.076v-2.06zm41.69 9.484a.143.143 0 01-.143.142H9.377a.143.143 0 01-.143-.142v-5.407c0-.079.065-.143.143-.143h45.246c.079 0 .143.064.143.143v5.407z" />
        <Path d="M20.66 24.107a.937.937 0 00-.862 1.007L22.33 57.53a.937.937 0 101.869-.146l-2.532-32.416a.937.937 0 00-1.008-.861zM42.333 24.968l-2.532 32.416a.937.937 0 001.869.146l2.532-32.416a.937.937 0 00-1.869-.146zM31.063 25.041v32.416a.937.937 0 001.874 0V25.041a.937.937 0 00-1.874 0z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_719_741">
          <Path fill="#fff" d="M0 0h64v64H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default IconTrash;