import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryClothes(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 26"
      fill="none"
      {...props}
    >
      <Path
        d="M12 0c-.926 0-1.945.27-2.813.531-.851.258-1.476.524-1.5.531h-.03l-2.876.97-.093.03a4.16 4.16 0 00-.375.126L4 2.28v.031c-.836.36-2.145 1.137-2.906 2.75C.059 7.25 0 9.97 0 9.97V12h4v14h16V12h4V9.906s-.086-.847-.25-1.875c-.164-1.027-.332-2.16-.906-3.062A7.173 7.173 0 0020 2.375v-.094l-.406-.125c-.059-.027-.047-.011-.094-.031l-.188-.063-2.968-1h-.032c-.023-.007-.648-.273-1.5-.53C13.945.27 12.927 0 12 0zm0 2c.434 0 1.426.23 2.219.469.207.062.195.066.375.125-.063.094-.11.183-.188.281C13.937 3.457 13.281 4 12 4c-1.281 0-1.938-.543-2.406-1.125-.078-.098-.125-.188-.188-.281.18-.059.168-.063.375-.125C10.574 2.23 11.566 2 12 2zM7.437 3.219c.149.285.34.59.594.906A5.026 5.026 0 0012 6c1.906 0 3.23-.957 3.969-1.875.254-.316.445-.621.594-.906l1.437.5V24H6V3.719l1.438-.5zM4 4.563V10H2c.004-.055.156-2.477.906-4.063.301-.636.7-1.078 1.094-1.375zm16 .156c.367.308.773.71 1.156 1.312.16.254.473 1.375.625 2.313.145.886.211 1.578.219 1.656h-2V4.719z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryClothes;