import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconMore(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <Path
        d="M32 14.222A7.111 7.111 0 1032 0a7.111 7.111 0 000 14.222zM32 39.111a7.111 7.111 0 100-14.222 7.111 7.111 0 000 14.222zM32 64a7.111 7.111 0 100-14.222A7.111 7.111 0 0032 64z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconMore;