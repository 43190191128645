export default {
    Lightest: '#F3F3F3',
    Light: '#C4C4C4',
    Primary: '#5D436E',
    Secondary: '#75A381',
    Dark: '#373737',
    LessDark: '#4F4F4F',
    DarkTransparent: '#37373799',
    DarkTransparentMore: '#37373722',
    ImageBackground: '#FFFFFF',
    Background: '#FFF7FF',
    BackgroundTransparent: '#FFF7FFCC',
    Facebook: '#1877F2',
    Google: '#FFFFFF',
    Apple: '#000000',
    Danger: '#BF4B4B',
    White: '#FFFFFF'
}