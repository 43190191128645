import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryHealth(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <Path
        d="M12 0C9.086 0 6.734 2.316 6.562 5.188 3.98 4.68 1.305 6.022.344 8.563c-1 2.64.258 5.527 2.718 6.78-1.636 2.305-1.292 5.528.907 7.376 2.316 1.949 5.804 1.629 7.75-.688.12-.144.152-.34.25-.5.105.172.148.375.281.532 1.95 2.316 5.434 2.636 7.75.687 2.203-1.855 2.523-5.07.875-7.375 2.504-1.238 3.79-4.152 2.781-6.813-.96-2.539-3.64-3.882-6.218-3.374C17.265 2.316 14.913 0 12 0zm0 2c1.945 0 3.5 1.555 3.5 3.5 0 .156-.066.438-.188.781-.003.012.004.02 0 .032l-2.968 4.218C12.656 9.5 13 8.328 13 8c0-.55-.45-1-1-1s-1 .45-1 1c0 .316.273 1.426.531 2.438L8.625 6.061c-.066-.23-.125-.437-.125-.562C8.5 3.555 10.055 2 12 2zM5.375 7c.457-.016.922.047 1.375.219.078.027.207.133.375.25.012.008.02-.008.031 0l2.781 4.218c-.875-.593-1.851-1.234-2.156-1.343a.998.998 0 10-.688 1.875c.302.11 1.473.191 2.532.25l-5.063 1.406c-.128-.023-.238-.066-.312-.094A3.5 3.5 0 012.219 9.25 3.49 3.49 0 015.375 7zm13.25 0a3.49 3.49 0 013.156 2.25 3.504 3.504 0 01-2.031 4.531 2.587 2.587 0 01-.656.125l-4.875-1.437c1.09-.059 2.367-.133 2.687-.25a.998.998 0 10-.687-1.875c-.332.117-1.418.828-2.313 1.437l3.031-4.375c.125-.086.25-.164.313-.187A3.512 3.512 0 0118.625 7zm-8.313 7.375c-.89.621-1.984 1.387-2.218 1.656-.36.418-.293 1.078.125 1.438a.998.998 0 001.406-.125c.27-.313.965-1.895 1.375-2.844v3.688c-.184 1.199-.547 2.246-.813 2.562a3.492 3.492 0 01-4.937.438 3.492 3.492 0 01-.438-4.938c.106-.125.348-.313.657-.5l.125-.063 4.718-1.312zm3.47.063l4.78 1.374c.278.176.497.352.594.47a3.495 3.495 0 01-.437 4.937 3.495 3.495 0 01-4.938-.438c-.254-.3-.594-1.277-.781-2.406V14.5c.41.95 1.105 2.531 1.375 2.844.36.418.988.484 1.406.125.418-.36.485-1.02.125-1.438-.222-.258-1.242-.976-2.125-1.593z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryHealth;