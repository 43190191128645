import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryFood(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 23"
      fill="none"
      {...props}
    >
      <Path
        d="M5 0C3.363 0 2.137 1.21 1.312 2.563.489 3.913 0 5.515 0 7c0 2.582 1.781 4.465 4 4.906V23h2V11.906c2.219-.441 4-2.324 4-4.906 0-1.484-.488-3.086-1.313-4.438C7.864 1.212 6.638 0 5 0zm7 0v7c0 1.852 1.281 3.398 3 3.844V23h2V10.844c1.719-.446 3-1.992 3-3.844V0h-2v7c0 1.117-.883 2-2 2s-2-.883-2-2V0h-2zm3 0v7c0 .55.45 1 1 1s1-.45 1-1V0h-2zM5 2c.574 0 1.344.566 1.969 1.594C7.594 4.62 8 5.996 8 7c0 2.004-1.25 3-3 3s-3-.996-3-3c0-1.004.406-2.379 1.031-3.406C3.656 2.566 4.426 2 5 2z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryFood;