import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCompassDisabled(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 62 62"
      fill="none"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 58.125C16.02 58.125 3.875 45.981 3.875 31 3.875 16.02 16.019 3.875 31 3.875c14.98 0 27.125 12.144 27.125 27.125 0 14.98-12.144 27.125-27.125 27.125zm0-51.667C17.447 6.458 6.458 17.447 6.458 31S17.447 55.542 31 55.542 55.542 44.553 55.542 31 44.553 6.458 31 6.458zm20.667 25.834h-1.292a1.29 1.29 0 110-2.584h1.292A1.29 1.29 0 0152.958 31a1.29 1.29 0 01-1.291 1.292zm-7.778 10.596a1.219 1.219 0 01-.337.664 1.258 1.258 0 01-1.458.223l.075.076.01.01c-.209-.086-.395-.168-.599-.252-6.702-2.792-10.608-4.98-12.745-6.378-1.696-1.108-2.293-1.722-2.317-1.748-.058-.055-3.214-3.115-8.377-15.661h0l.087.087c-.013-.026-.016-.055-.026-.08a1.246 1.246 0 01-.049-.854c.057-.19.144-.375.296-.526a1.25 1.25 0 011.422-.234c.012.005.026.006.036.013l-.03-.032-.055-.055h.001-.001c12.546 5.163 15.606 8.319 15.661 8.377.059.055 3.214 3.117 8.377 15.661h-.002.002l-.057-.056-.03-.029c.03.06.042.125.062.189.064.197.09.403.054.605zM33.643 28.33c-.027-.027-2.604-2.489-11.867-6.552 4.064 9.262 6.53 11.84 6.581 11.893.027.027 2.604 2.49 11.867 6.553-4.064-9.264-6.53-11.84-6.581-11.894zM31 29.708a1.29 1.29 0 110 2.584 1.29 1.29 0 110-2.584zm0-16.791a1.291 1.291 0 01-1.292-1.292v-1.292a1.291 1.291 0 112.584 0v1.292c0 .713-.578 1.292-1.292 1.292zm0 36.166a1.29 1.29 0 011.292 1.292v1.292A1.29 1.29 0 0131 52.958a1.29 1.29 0 01-1.292-1.291v-1.292A1.29 1.29 0 0131 49.083zM11.625 32.292h-1.292a1.29 1.29 0 110-2.584h1.292a1.29 1.29 0 110 2.584z"
        fill="#BBBBBB"
      />
    </Svg>
  );
}

export default IconCompassDisabled;