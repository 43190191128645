import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryKids(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 27 24"
      fill="none"
      {...props}
    >
      <Path
        d="M15 0v9H6.687A9.83 9.83 0 005.47 6.906C4.414 5.504 2.605 4 0 4v2c1.855 0 3.047.996 3.875 2.094.79 1.047 1.094 2.031 1.125 2.125.082 3.39 2.082 6.32 4.938 7.781A3.016 3.016 0 007 21c0 1.645 1.355 3 3 3s3-1.355 3-3c0-.887-.383-1.7-1-2.25.645.148 1.313.25 2 .25h3c.688 0 1.355-.102 2-.25-.617.55-1 1.363-1 2.25 0 1.645 1.355 3 3 3s3-1.355 3-3a3.016 3.016 0 00-2.938-3c2.633-1.348 4.528-3.945 4.875-7 .032-.29.06-.578.063-.875v-.063s.008-.164 0-.437V9h-.063c-.074-.957-.273-2.406-1-3.938C23.747 2.555 21.047 0 16 0h-1zm2 2.125c3.613.297 5.293 1.996 6.156 3.813.59 1.246.723 2.292.782 3.062H17V2.125zM7.219 11H23.78c-.508 3.363-3.281 6-6.781 6h-3c-3.5 0-6.273-2.637-6.781-6zM10 20c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1zm11 0c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryKids;