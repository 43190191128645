import { Text, StyleSheet, ViewProps, ActivityIndicator } from "react-native";
import Color from "../style/Color";
import Typography from "../style/Typography";
import Animated, {FadeOut} from "react-native-reanimated";

const style = StyleSheet.create({
    view: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Color.BackgroundTransparent,
        zIndex: 10000,
        flexDirection: 'row',
    },
    hidden: {
        display: 'none'
    },
    text: {
        marginLeft: 8
    }
})

interface ILoadingOverlay extends ViewProps {
    text?: string
    small?: boolean
    borderRadius?: number
}

export default (props: ILoadingOverlay) => {
    return (<Animated.View exiting={FadeOut} style={[style.view, props.borderRadius !== undefined && {borderRadius: props.borderRadius}]}>
        <ActivityIndicator size={props.small ? 'small' : 'large'} color={Color.Primary} />
        <Text style={[Typography.Subsubtitle, style.text]}>{props.text}</Text>
    </Animated.View>)
}