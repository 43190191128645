import { useNavigation } from "@react-navigation/native";
import { Image, Platform, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, ViewStyle } from "react-native";
import Color from "../style/Color";
import Typography from "../style/Typography";
import IconArrowBack from "./icons/IconArrowBack";
import LoadingOverlay from "./LoadingOverlay";
import IconGoogle from './icons/IconGoogle'
import IconFacebook from './icons/IconFacebook'
import IconApple from './icons/IconApple'
import ImageLogo from '../assets/images/logo.png'
import PageStyle from "../style/PageStyle";
import * as webAnalytics from 'firebase/analytics'
import analytics from '@react-native-firebase/analytics'

const style = StyleSheet.create({
    primaryBackground: {
        borderRadius: 22.5,
        backgroundColor: Color.Primary,
        alignItems: 'center',
        justifyContent: 'center',
        height: 55,
        alignSelf: Platform.OS === 'web' ? 'center' : 'stretch',
        paddingHorizontal: Platform.OS === 'web' ? 24 : undefined,
        maxWidth: Platform.OS === 'web' ? 512 : undefined,
        ... PageStyle.Shadow
    },
    primarySmallBackground: {
        borderRadius: 18,
        backgroundColor: Color.Primary,
        alignItems: 'center',
        justifyContent: 'center',
        height: 41,
        alignSelf: 'stretch',
        ... PageStyle.Shadow
    },
    secondarySmallBackground: { // Must be combined with primarySmallBackground
        backgroundColor: Color.Secondary,
    },
    backBackground: {
        flexDirection: 'row',
        height: 24,
        marginLeft: 24
    },
    backText: {
        alignSelf: 'center',
        paddingLeft: 4
    },
    disabled: {
        backgroundColor: Color.Lightest
    },
    disabledText: {
        color: Color.Light
    }
})

interface IButton {
    style?: StyleProp<ViewStyle>
    textStyle?: StyleProp<TextStyle>
    text?: string
    googleIcon?: boolean
    facebookIcon?: boolean
    appleIcon?: boolean
    onPress?: () => void
    loadingText?: string
    disabled?: boolean
}

interface IBackButton {
    text?: string
}

const onPress = (button: IButton) => {
    if(!button.disabled) {
        if(Platform.OS === 'web') {
            const analytics = webAnalytics.getAnalytics()
            webAnalytics.logEvent(analytics, 'button_press', {button: button.text})
        } else {
            analytics().logEvent('button_press', {button: button.text})
        }
        if(button.onPress) button.onPress()
    }
}

const Primary = (props: IButton) => (
    <TouchableOpacity activeOpacity={0.8} style={[style.primaryBackground, props.style, props.disabled && style.disabled, {flexDirection: 'row'}]} onPress={() => {onPress(props)}}>
        { props.loadingText && <LoadingOverlay text={props.loadingText} borderRadius={style.primaryBackground.borderRadius} /> }
        { props.facebookIcon && <IconFacebook width={42} height={24} /> }
        { props.googleIcon && <IconGoogle width={62} height={24} /> }
        { props.appleIcon && <IconApple width={62} height={24} /> }
        <Text style={[Typography.ButtonText, props.disabled && style.disabledText, props.textStyle]}>{ props?.text }</Text>
    </TouchableOpacity>
)

const PrimarySmall = (props: IButton) => (
    <TouchableOpacity activeOpacity={0.8} style={[style.primarySmallBackground, props.style, props.disabled && style.disabled]} onPress={() => {onPress(props)}}>
        { props.loadingText && <LoadingOverlay text={props.loadingText} borderRadius={style.primarySmallBackground.borderRadius} /> }
        <Text style={[Typography.ButtonSmallText, props.disabled && style.disabledText, props.textStyle]}>{ props?.text }</Text>
    </TouchableOpacity>
)

const SecondarySmall = (props: IButton) => (
    <TouchableOpacity activeOpacity={0.8} style={[style.primarySmallBackground, style.secondarySmallBackground, props.style, props.disabled && style.disabled]} onPress={() => {onPress(props)}}>
        { props.loadingText && <LoadingOverlay text={props.loadingText} borderRadius={style.primarySmallBackground.borderRadius} /> }
        <Text style={[Typography.ButtonSmallText, props.disabled && style.disabledText, props.textStyle]}>{ props?.text }</Text>
    </TouchableOpacity>
)

const Back = (props: IBackButton) => {
    const navigation = useNavigation()
    const onPress = () => { if(navigation.canGoBack()) navigation.goBack() }
    const onPressWeb = () => { (navigation as any).navigate('Home') }
    return Platform.OS === 'web' ? (
        <TouchableOpacity onPress={onPressWeb}>
            <Image source={ImageLogo} style={{height: 48, width: 171, marginLeft: 16}} />
        </TouchableOpacity>
    ) : (
        <TouchableOpacity style={style.backBackground} onPress={onPress}>
            <IconArrowBack width={24} height={24} />
            <Text style={[Typography.BackButton, style.backText]}>{props.text}</Text>
        </TouchableOpacity> 
    )
}

export default {
    Primary, PrimarySmall, SecondarySmall, Back
}