import { useState } from "react";
import { Text, View, StyleSheet, TextInput, TextInputProps, ViewStyle, ActivityIndicator, Platform } from "react-native";
import Color from "../style/Color";
import Typography from "../style/Typography";
import IconPen from "./icons/IconPen";
import IconPenDisabled from "./icons/IconPenDisabled";

const style = StyleSheet.create({
    icon: {
        marginLeft: 8,
        marginRight: 16,
        marginBottom: 6,
        alignSelf: 'flex-start'
    },
    actitityIndicator: {
        marginLeft: 8,
        marginRight: 16,
        marginBottom: 6,
        alignSelf: 'flex-start',
        width: 24,
        height: 24
    },
    view: {
        borderBottomColor: Color.Light,
        borderBottomWidth: 2,
        flexDirection: 'row',
    },
    viewFocused: {
        borderBottomColor: Color.Primary
    },
    field: {
        flex: 1,
        color: Color.Dark
    }
})

interface CustomTextInputProps extends TextInputProps {
    title?: string
    loading?: boolean
    viewStyle?: ViewStyle
}

export default (props: CustomTextInputProps) => {
    
    const [isFocused, setIsFocused] = useState(false)
    const onFocus = () => {setIsFocused(true)}
    const onBlur = () => {setIsFocused(false)}
    const events = {onFocus, onBlur}

    return (
        <View>
            {props.title && <Text style={Typography.InputTitle}>{props.title}</Text> }
            <View style={[style.view, isFocused && style.viewFocused, props.viewStyle]}>
                { props.loading && <ActivityIndicator color={Color.Primary} style={style.actitityIndicator} /> }
                { !props.loading && !isFocused && <IconPenDisabled width={24} height={24} style={style.icon} />}
                { !props.loading && isFocused && <IconPen width={24} height={24} style={style.icon} />}
                <TextInput 
                    {...events} 
                    {...props} 
                    placeholder={props.placeholder} 
                    style={[
                        Typography.Text, 
                        style.field, 
                        Platform.OS === 'web' && ({outlineStyle: 'none'} as any)
                        ]} 
                    />
            </View>
        </View>
    )
}