import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

function IconArrowBack(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <G clipPath="url(#prefix__clip0_118_0)">
        <Path
          d="M17.735 24a.522.522 0 01-.37-.152L5.89 12.372a.522.522 0 010-.737L17.366.159a.522.522 0 01.75.725l-.013.013L6.997 12.003l11.107 11.106a.522.522 0 01-.369.891z"
          fill="#F44336"
        />
        <Path
          d="M17.735 24a.522.522 0 01-.37-.152L5.89 12.372a.522.522 0 010-.737L17.366.159a.522.522 0 01.75.725l-.013.013L6.997 12.003l11.107 11.106a.522.522 0 01-.369.891z"
          fill="#373737"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_118_0">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default IconArrowBack;