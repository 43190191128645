import { BlurView } from "expo-blur"
import { Text, StyleSheet, View, Image, TouchableOpacity, Alert, Platform, Dimensions } from "react-native"
import Color from "../style/Color"
import Typography from "../style/Typography"
import Divider from "./Divider"
import IconMore from "./icons/IconMore"
import { defaultProductPicture, limitText, showMessage } from '../Api'
import { useState } from "react"
import LoadingOverlay from "./LoadingOverlay"
import firestore from "@react-native-firebase/firestore"
import { useActionSheet } from "@expo/react-native-action-sheet"
import { useNavigation } from "@react-navigation/native"
import IWishlist from "../models/IWishlist"
import IWish from "../models/IWish"
import { getUser } from '../Api'
import * as webFirestore from 'firebase/firestore'
import PageStyle from "../style/PageStyle"
import Animated, { FadeInDown, FadeOutDown } from "react-native-reanimated"
import ViewMode from "../models/ViewMode"
import { useTranslation } from "react-i18next"

interface ICardWish {
    wish: IWish
    wishlist: IWishlist
    reserved: boolean
    viewMode?: ViewMode
}

const style = StyleSheet.create({
    blurContainer: {
        width: Dimensions.get('window').width / 2.4,
        maxWidth: 256,
        margin: Math.min(4, Dimensions.get('window').width / 150),
        borderRadius: 22.5,
    },
    blurContainerList: {
        alignSelf: 'stretch',
        borderRadius: 22.5,
    },
    blurView: {
        padding: 8,
        borderRadius: 22.5,
        overflow: 'hidden',
        flex: 1,
    },
    blurViewList: {
        borderRadius: 22.5,
        overflow: 'hidden',
        flex: 1
    },
    image: {
        aspectRatio: 1,
        alignSelf: 'stretch',
        borderRadius: 22.5,
        backgroundColor: Color.ImageBackground
    },
    imageList: {
        aspectRatio: 1,
        
        borderRadius: 22.5,
        backgroundColor: Color.ImageBackground
    },
    textLeft: {
        alignSelf: 'flex-start',
        maxWidth: 200
    },
    textRight: {
        flex: 1,
        textAlign: 'right'
    },
    text: {
        fontSize: 16,
        lineHeight: 24,
        paddingHorizontal: 4,
    },
    buttonMore: {
        alignSelf: 'flex-end',
        width: 24,
        height: 24,
        justifyContent: 'flex-end',
    }
})

export default (props: ICardWish) => {
    
    const { t } = useTranslation()
    const navigation = useNavigation<any>()
    const {showActionSheetWithOptions} = useActionSheet()
    const image = props.wish.urlImage.length > 0 ? props.wish.urlImage : defaultProductPicture
    const title = props.wish.name
    const price = props.wish.price
    const comment = props.wish.comment
    const marks = props.wish.marks
    const wishlistId = props.wishlist.id
    const [loadingText, setLoadingText] = useState<string>()
    const editMode = props.wishlist.editors.includes(getUser()?.uid ?? '')

    const move = async (delta: number) => {
        try {
            if(delta > 1 || delta < -1) {
                console.error('You can only move 1 position at a time')
                return
            }
            
            const currentPosition = props.wish.position ?? 0
            const desiredPosition = currentPosition + delta

            if(Platform.OS === 'web') {
                // Check if there is a wish at the desired position
                const db = webFirestore.getFirestore()
                const wishesRef = webFirestore.collection(db, `wishlist/${wishlistId}/wish`)
                const wishAtDesiredPositionQuery = webFirestore.query(wishesRef, webFirestore.where('position', '==', desiredPosition))
                const wishAtDesiredPosition = await webFirestore.getDocs(wishAtDesiredPositionQuery)
                if(wishAtDesiredPosition.empty) return
                const wishAtDesiredPositionId = wishAtDesiredPosition.docs[0].id

                // Set wish at desired position to current position
                const desiredWishRef = webFirestore.doc(db, `wishlist/${wishlistId}/wish/${wishAtDesiredPositionId}`)
                await webFirestore.updateDoc(desiredWishRef, {position: currentPosition})

                // Set current wish to desired position
                const currentWishRef = webFirestore.doc(db, `wishlist/${wishlistId}/wish/${props.wish.id}`)
                await webFirestore.updateDoc(currentWishRef, {position: desiredPosition})
            } else {
                // Check if there is a wish at the desired position
                const wishAtDesiredPosition = await firestore().collection('wishlist').doc(wishlistId).collection('wish').where('position', '==', desiredPosition).get()
                if(wishAtDesiredPosition.empty) {
                    return
                }
                const wishAtDesiredPositionId = wishAtDesiredPosition.docs[0].id
        
                // Set wish at desired position to current position
                await firestore().collection('wishlist').doc(wishlistId).collection('wish').doc(wishAtDesiredPositionId).update({
                    position: currentPosition
                })
        
                // Set this wish to desired position
                await firestore().collection('wishlist').doc(wishlistId).collection('wish').doc(props.wish.id).update({
                    position: desiredPosition
                })
            }
        } catch(e) {
            console.error(e)
        }
    }

    const onPressEdit = () => {
        navigation.navigate('EditWish', {wish: props.wish, wishlistId: wishlistId})
    }

    const onPressDelete = async () => {
        if(!props.wish.id) return
        if(!wishlistId) return

        setLoadingText('Sletter...')
        try {
            if(Platform.OS === 'web') {
                // Delete this wish
                const db = webFirestore.getFirestore()
                const wishRef = webFirestore.doc(db, `wishlist/${wishlistId}/wish/${props.wish.id}`)
                await webFirestore.deleteDoc(wishRef)

                // Update position of all wishes
                const wishesRef = webFirestore.collection(db, `wishlist/${wishlistId}/wish`)
                const wishesQuery = webFirestore.query(wishesRef, webFirestore.orderBy('position', 'asc'))
                const wishes = await webFirestore.getDocs(wishesQuery)
                for(let i = 0; i < wishes.docs.length; i++) {
                    const wishRef = webFirestore.doc(db, `wishlist/${wishlistId}/wish/${wishes.docs[i].id}`)
                    await webFirestore.updateDoc(wishRef, {position: i})
                }
            } else {
                // Delete this wish
                await firestore().collection('wishlist').doc(wishlistId).collection('wish').doc(props.wish.id).delete()

                // Update positions of all wishes
                const wishes = await firestore().collection('wishlist').doc(wishlistId).collection('wish').orderBy('position', 'asc').get()
                for(let i=0; i<wishes.size; i++) {
                    const wish = wishes.docs[i]
                    await firestore().collection('wishlist').doc(wishlistId).collection('wish').doc(wish.id).update({
                        position: i
                    })
                }
            }
        } catch(e: any) {
            console.error(e)
            showMessage(t('CardWish.error'), t('CardWish.error_delete', {message: e?.message}))
            return
        } finally {
            setLoadingText(undefined)
        }
    }

    const onPressMore = () => {
        const options = [t('CardWish.action_move_up'), t('CardWish.action_move_down'), t('CardWish.action_edit'), t('CardWish.action_delete')]
        if(Platform.OS === 'ios') options.push(t('CardWish.action_cancel'))

        showActionSheetWithOptions({
            options,
            message: title,
            cancelButtonIndex: Platform.OS === 'ios' ? 4 : -1 /* Workaround to get dismiss to work, do not remove */,
            destructiveButtonIndex: 3
        }, (index) => {
            switch(index) {
                case 0:
                    move(1)
                    break
                case 1:
                    move(-1)
                    break
                case 2:
                    onPressEdit()
                    break
                case 3:
                    onPressDelete()
                    break
            }
        })
    }

    const onPress = () => {
        const wishlistId = props.wishlist.id
        const wishId = props.wish.id
        navigation.navigate('Wish', {wish: props.wish, wishlist: props.wishlist, wishlistId, wishId})
    }

    let maxMarksText: string
    if(props.wish.marksMax > 1 && editMode) {
        maxMarksText = t('CardWish.total_amount_wished_for', {amount: props.wish.marksMax})
    } else {
        maxMarksText = ''
    }

    if(props.viewMode === ViewMode.Cards) {
        return (
            <Animated.View style={PageStyle.Shadow} entering={FadeInDown} exiting={FadeOutDown}>
                <View style={style.blurContainer}>
                    
                    {/* Content */}
                    <BlurView intensity={Platform.OS === 'android' ? 95 : 60} style={style.blurView} tint='light'>
                        
                        {/* Loading overlay */}
                        { loadingText && <LoadingOverlay text={loadingText} /> }
                        
                        {/* Make wish touchable */}
                        <TouchableOpacity style={{flex: 1}} onPress={onPress}>
                            
                            {/* Wish or product image */}
                            {<Image style={style.image} source={{uri: image}} />}
                            <Divider.Tiny />
    
                            <View style={[PageStyle.row, {justifyContent: 'space-around'}]}>
                                <View style={{alignSelf: 'stretch', flex: 1}}>

                                    {/* Reserved text */}
                                    {props.reserved && !editMode && (
                                        <Text style={[Typography.ProductName, {alignSelf: 'center', color: Color.Danger}]}>RESERVERET</Text>
                                    )}

                                    <Text style={[Typography.ProductName, style.text]}>
                                        {/* Amount text */}
                                        {editMode && maxMarksText.length > 0 && (
                                            <Text style={{color: Color.DarkTransparent}}>{maxMarksText + `\n`}</Text>
                                        )}
                                        
                                        {/* Wish title */}
                                        {limitText(title, 40)}
                                    </Text>
                                    {(comment?.length ?? '') > 0 && (
                                        <Text style={[Typography.ProductComment, {marginLeft: 4}]}>
                                            {comment}
                                        </Text>
                                    )}
                                </View>
                                
                                {/* Options button, edit mode only */}
                                {editMode && (
                                    <TouchableOpacity style={[style.buttonMore, {alignSelf: 'flex-end'}]} onPress={onPressMore}>
                                        <IconMore width={24} height={24} />
                                    </TouchableOpacity>
                                )}
                            </View>
                                
                        </TouchableOpacity>
                        <Divider.Micro />
                    </BlurView>
                </View>
            </Animated.View>
        )
    } else {
        return (
            <Animated.View style={[PageStyle.Shadow, {marginBottom: 8}]} entering={FadeInDown} exiting={FadeOutDown}>
                <View style={style.blurContainerList}>
                    <TouchableOpacity style={{flex: 1}} activeOpacity={0.75} onPress={onPress}>
                        <BlurView intensity={Platform.OS === 'android' ? 95 : 60} style={[style.blurViewList, PageStyle.row, {height: 64}]} tint='light'>
                            <Image source={{uri: image}} style={{ width: 64, height: 64, borderTopLeftRadius: 22.5, borderBottomLeftRadius: 22.5 }}/>
                            <View style={[{ flex: 1, paddingHorizontal: 4}, {justifyContent: 'center'}]}>
                                {props.reserved && !editMode && <Text style={[Typography.ProductName, {color: Color.Danger, marginLeft: 4}]}>RESERVERET</Text>}
                                <Text style={[Typography.ProductName, style.text]}>
                                    {maxMarksText.length > 0 && <Text style={{color: Color.DarkTransparent}}>{maxMarksText}</Text>}
                                    {limitText(title, (props.reserved || (comment?.length ?? 0) > 0) ? 25 : 60)}
                                </Text>
                                {!props.reserved && (comment?.length ?? 0) > 0 && <Text style={[Typography.ProductComment, {marginLeft: 4}]}>{limitText(comment, 90)}</Text>}
                            </View>
                            {editMode && <TouchableOpacity style={[{width: 24, height: 24, alignSelf: 'center', marginRight: 8}]} onPress={onPressMore}>
                                <IconMore width={24} height={24} />
                            </TouchableOpacity>}
                        </BlurView>
                    </TouchableOpacity>
                </View>
            </Animated.View>
        )
    }

}
