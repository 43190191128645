import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryDiy(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 28 23"
      fill="none"
      {...props}
    >
      <Path
        d="M21.398 10.024c-.34.02-.562-.09-.59-.11l-1.687 1.078c.371.582 1.137.977 1.996 1.028.067.004.133.004.2.004 1.128 0 2.238-.57 3.078-1.586 1.433-1.743 2.44-3.59 2.484-3.668l.453-.84-.816-.492c-4.532-2.723-7.121-1.43-8.024-.746A3.72 3.72 0 0017.09 8.43c-.93.594-1.856 1.344-2.594 2.258-.426-.996-1.09-1.891-1.863-2.68.07-.184.117-.363.148-.496.016-.07.031-.137.051-.195.512-1.715-.191-3.715-1.75-4.973-1.11-.895-4.262-2.66-9.562.262l-.684.375.195.75c.04.152.985 3.714 2.864 5.8.812.903 1.832 1.41 2.953 1.465.07.004.14.004.207.004 1.258 0 2.336-.559 2.773-.957l-1.344-1.48c-.015.011-1.695 1.191-3.101-.371-1.09-1.208-1.832-3.145-2.184-4.208C6.984 2.16 9.08 3.297 9.824 3.9c.875.707 1.309 1.75 1.13 2.656C8.8 4.96 6.558 4.07 6.378 4.004l-.723 1.863C7.7 6.657 13 9.484 13 13v3.145c-.762.199-1.457.609-1.98 1.199A3.477 3.477 0 009.5 17c-1.43 0-2.66.86-3.203 2.094A3.419 3.419 0 005.5 19C3.57 19 2 20.57 2 22.5v.5h2v-.5A1.5 1.5 0 015.5 21c.402 0 .71.168.898.309L8 20.512v-.016A1.505 1.505 0 019.5 19c.402 0 .781.16 1.066.45l1.621-.282A2.005 2.005 0 0114 18a1.99 1.99 0 011.75 1.063l1.578.242c.2-.195.465-.305.742-.305.586 0 1.067.48 1.067 1.067 0 .117-.024.234-.07.363l1.605 1.09c.265-.239.71-.52 1.328-.52 1.102 0 2 .899 2 2h2c0-2.207-1.793-4-4-4-.344 0-.684.047-1.008.133a3.074 3.074 0 00-4.113-1.895A3.988 3.988 0 0015 16.145V15h.004v-.328c0-4.152 6.531-6.14 6.598-6.16l-.563-1.918c-.102.027-.918.277-1.992.77.078-.415.293-.801.656-1.075 1.059-.805 2.856-.652 4.914.387a22.638 22.638 0 01-1.765 2.492c-.551.668-1.094.836-1.454.856zM11.484 6.82l.012.157c-.05-.04-.105-.078-.156-.121.047-.012.09-.032.144-.036z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryDiy;