import * as React from "react";
import Svg, { SvgProps, Rect } from "react-native-svg";

function IconCheckEmpty(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <Rect
        x={1}
        y={1}
        width={30}
        height={30}
        rx={15}
        stroke="#4D335E"
        strokeWidth={2}
      />
    </Svg>
  );
}

export default IconCheckEmpty;