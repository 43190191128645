import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconPen(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <Path
        d="M55.094 4.395a8.263 8.263 0 00-11.675 0l-2.923 2.938-31.11 31.094-.066.066c-.016.016-.016.033-.033.033-.033.05-.082.099-.115.149 0 .016-.017.016-.017.033-.033.05-.05.082-.083.132-.016.016-.016.032-.032.05-.017.05-.033.082-.05.132 0 .016-.016.016-.016.033L2.07 59.81a1.625 1.625 0 00.396 1.684c.313.308.734.48 1.173.48a1.95 1.95 0 00.528-.083l20.74-6.92c.017 0 .017 0 .034-.015a.6.6 0 00.148-.067.058.058 0 00.034-.016c.049-.033.115-.067.164-.1.05-.032.1-.082.149-.115.016-.017.033-.017.033-.033.017-.017.05-.032.066-.066L59.57 20.527a8.262 8.262 0 000-11.674l-4.475-4.458zM24.38 51.092L12.887 39.6l28.765-28.765 11.493 11.492L24.38 51.092zM11.27 42.654l10.04 10.04-15.077 5.02 5.037-15.06zM57.24 18.215l-1.75 1.768L43.996 8.489l1.767-1.766a4.953 4.953 0 017.002 0l4.49 4.49a4.97 4.97 0 01-.015 7.002z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconPen;