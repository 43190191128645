import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

function IconArrowRight(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <G clipPath="url(#prefix__clip0_719_590)" fill="#4D335E">
        <Path d="M16.707 64c.369 0 .723-.145.985-.406l30.6-30.601a1.391 1.391 0 000-1.967L17.693.425a1.391 1.391 0 10-1.967 1.966L45.34 32.008l-29.62 29.616A1.391 1.391 0 0016.708 64z" />
        <Path d="M16.707 64c.369 0 .723-.145.985-.406l30.6-30.601a1.391 1.391 0 000-1.967L17.693.425a1.391 1.391 0 10-1.967 1.966L45.34 32.008l-29.62 29.616A1.391 1.391 0 0016.708 64z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_719_590">
          <Path fill="#fff" d="M0 0h64v64H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default IconArrowRight;