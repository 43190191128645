import { useNavigation } from "@react-navigation/native"
import { useState } from "react"
import auth from "@react-native-firebase/auth"
import { Alert, View, SafeAreaView, ScrollView, Text, Platform } from "react-native"
import PageStyle from "../style/PageStyle"
import Divider from "../components/Divider"
import Button from "../components/Button"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import * as webAuth from "firebase/auth"
import { getUserFriendlyErrorMessage } from "../Api"
import { showMessage } from "../Api"
import { useTranslation } from "react-i18next"

// Change email page
const ScreenChangeEmail = () => {
    const navigation = useNavigation()
    const { t } = useTranslation()
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [loadingText, setLoadingText] = useState<string>()

    const onPressChangeEmail = async () => {
        try {
            setLoadingText(t('ScreenChangeEmail.loading_save'))
            if(Platform.OS === 'web') {
                const auth = webAuth.getAuth()
                const user = auth.currentUser
                if(!user) return
                await webAuth.reauthenticateWithCredential(user, webAuth.EmailAuthProvider.credential(user.email ?? '', password))
                await webAuth.updateEmail(user, email)
            } else {
                await auth().currentUser?.reauthenticateWithCredential(auth.EmailAuthProvider.credential(auth().currentUser?.email ?? '', password))
                await auth().currentUser?.updateEmail(email)
            }
            showMessage(t('ScreenChangeEmail.email_changed'), t('ScreenChangeEmail.email_changed_text'))
            navigation.goBack()
        } catch(e: any) {
            setLoadingText(undefined)
            const errorMessage = getUserFriendlyErrorMessage(e)
            showMessage(t('ScreenChangeEmail.error_change'), errorMessage)
            return
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                <Divider.Medium />
                <Button.Back text={t('ScreenChangeEmail.button_back')} />
                <Divider.Medium />
                <ScrollView style={PageStyle.safeArea}>

                    {/* Screen title and subtitle */}
                    <Text style={Typography.Title}>{t('ScreenChangeEmail.title')}</Text>
                    <Text style={Typography.Subtitle}>{t('ScreenChangeEmail.subtitle')}</Text>
                    <Divider.Medium />

                    {/* User input */}
                    <TextInput title={t('ScreenChangeEmail.input_password_current')} onChangeText={setPassword} secureTextEntry={true} />
                    <Divider.Medium />

                    <TextInput title={t('ScreenChangeEmail.input_email_new')} onChangeText={setEmail} autoCapitalize="none" keyboardType="email-address" />
                    <Divider.Medium />
                    
                    <Button.Primary text={t('ScreenChangeEmail.button_change')} loadingText={loadingText} onPress={onPressChangeEmail} />
                </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ScreenChangeEmail