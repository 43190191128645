import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryGifts(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 23"
      fill="none"
      {...props}
    >
      <Path
        d="M8 0C6.355 0 5 1.355 5 3c0 .352.074.684.188 1H0v6h1v13h22V10h1V4h-5.188A2.95 2.95 0 0019 3c0-1.645-1.355-3-3-3-1.75 0-2.938 1.328-3.719 2.438-.105.148-.187.292-.281.437-.094-.145-.176-.29-.281-.438C10.938 1.329 9.75 0 8 0zm0 2c.625 0 1.438.672 2.063 1.563.152.218.128.23.25.437H8c-.566 0-1-.434-1-1 0-.566.434-1 1-1zm8 0c.566 0 1 .434 1 1 0 .566-.434 1-1 1h-2.313c.122-.207.098-.219.25-.438C14.563 2.672 15.376 2 16 2zM2 6h20v2h-9V7h-2v1H2V6zm1 4h18v11h-8V11h-2v10H3V10z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryGifts;