import { ImageBackground, StyleSheet, Text, TouchableOpacity, Dimensions } from "react-native"
import CardDefault from '../assets/images/card-default.png'
import Color from "../style/Color"
import PageStyle from "../style/PageStyle"
import {FontStyle} from "../style/Typography"
import Aniamted, { FadeIn, FadeOut } from "react-native-reanimated"

interface ICardWishlist {
    title: string
    subtitle: string
    imageUri?: string | null
    onPress?: () => void
}

const style = StyleSheet.create({
    view: {
        borderRadius: 22.5,
        height: 198,
        width: Dimensions.get('window').width,
        maxWidth: 321,
        margin: 8,
        ... PageStyle.Shadow
    },
    backgroundImage: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22.5,
        overflow: 'hidden'
    },
    title: {
        color: Color.Lightest,
        backgroundColor: Color.DarkTransparent,
        paddingHorizontal: 8,
        borderRadius: 8,
        fontSize: 22,
        textAlign: 'center'
    },
    subtitle: {
        color: Color.Lightest,
        textShadowColor: Color.Dark,
        textShadowRadius: 4,
        textShadowOffset: {width: 0, height: 2},
        fontSize: 18
    }
})

export default (props: ICardWishlist) => {
    return (
        <Aniamted.View entering={FadeIn} exiting={FadeOut}>
            <TouchableOpacity activeOpacity={props.onPress ? 0.8 : 1} onPress={props.onPress} style={style.view}>
                <ImageBackground source={props.imageUri ? {uri: props.imageUri} : CardDefault} style={style.backgroundImage}>
                    {props.title.length > 0 && <Text style={[FontStyle.Medium, style.title]}>{props.title.trim()}</Text>}
                    {props.subtitle.length > 0 && <Text style={[FontStyle.Medium, style.subtitle]}>{props.subtitle.trim()}</Text>}
                </ImageBackground>
            </TouchableOpacity>
        </Aniamted.View>
    )
}