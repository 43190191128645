import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconViewList(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <Path
        d="M58 31c0 2.139-1.792 3.875-4 3.875H22c-2.208 0-4-1.736-4-3.875 0-2.139 1.792-3.875 4-3.875h32c2.208 0 4 1.736 4 3.875zM58 11.625c0 2.139-1.792 3.875-4 3.875H22c-2.208 0-4-1.736-4-3.875 0-2.139 1.792-3.875 4-3.875h32c2.208 0 4 1.736 4 3.875zM58 50.375c0 2.139-1.792 3.875-4 3.875H22c-2.208 0-4-1.736-4-3.875 0-2.139 1.792-3.875 4-3.875h32c2.208 0 4 1.736 4 3.875zM6 11.625c0 2.137 1.794 3.875 4 3.875s4-1.738 4-3.875-1.794-3.875-4-3.875-4 1.738-4 3.875zM6 31c0 2.137 1.794 3.875 4 3.875s4-1.738 4-3.875-1.794-3.875-4-3.875S6 28.863 6 31zM6 50.375c0 2.137 1.794 3.875 4 3.875s4-1.738 4-3.875-1.794-3.875-4-3.875-4 1.738-4 3.875z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconViewList;