import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import IProduct from "../models/IProduct"
import Color from "../style/Color"
import Typography from "../style/Typography"
import {defaultProductPicture, limitText} from '../Api'
import PageStyle from "../style/PageStyle"
import Animated, { FadeInDown, FadeOutDown } from "react-native-reanimated"

const style = StyleSheet.create({
    container: {
        ...PageStyle.Shadow, 
        borderColor: Color.DarkTransparentMore, 
        borderRadius: 22.5, 
        borderWidth: 1, 
        margin: 4
    },
    view: {
        borderRadius: 22.5,
        height: 96,
        width: '100%',
        flexDirection: 'row',
        overflow: 'hidden',
    },
    textBrand: {
        marginTop: -7
    },
    image: {
        width: 96,
        height: 96,
        alignSelf: 'flex-start',
        backgroundColor: Color.ImageBackground
    },
    innerView: {
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor: Color.Background,
        padding: 8
    },
    bottomView: {
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
    },
    textTitle: {
        marginTop: 4,
        lineHeight: 24
    }
})

interface ICardProduct {
    product: IProduct
    onPress?: () => void
    animated?: boolean
}

export default (props: ICardProduct) => {

    let imageUri = defaultProductPicture
    if(props.product.urlImage && props.product.urlImage.length > 0) {
        imageUri = props.product.urlImage
    }

    const content = (
        <TouchableOpacity onPress={props.onPress} activeOpacity={0.8} style={style.view}>
            <Image source={{uri: imageUri}} style={style.image} />
            <View style={style.innerView}>
                <Text style={[Typography.ProductTitle, style.textTitle]}>{limitText(props.product.name, 27)}</Text>
                <Text style={[Typography.ProductBrand, style.textBrand]}>{limitText(props.product.brand, 30)}</Text>
            </View>
        </TouchableOpacity>
    )

    return props.animated ? (
        <Animated.View entering={FadeInDown} exiting={FadeOutDown} style={style.container}>
            { content }
        </Animated.View>
    ) : (
        <View style={style.container}>
            { content }
        </View>
    )
}