import { useNavigation, useRoute } from "@react-navigation/native"
import { Alert, Text, View, SafeAreaView, ScrollView, ImageBackground, StyleSheet, Platform } from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import { useState } from "react"
import * as ImagePicker from "expo-image-picker"
import * as api from "../Api"
import Color from "../style/Color"
import IWish from "../models/IWish"
import { getUserFriendlyErrorMessage } from "../Api"
import WishCountInput from "../components/WishCountInput"
import { useTranslation } from "react-i18next"

const style = StyleSheet.create({
    image: {
        aspectRatio: 1,
        alignSelf: 'stretch',
        borderRadius: 22.5,
        backgroundColor: Color.ImageBackground,
        overflow: 'hidden'
    }
})

const ScreenWishEdit = () => {

    const navigation = useNavigation<any>()
    const route = useRoute<any>()
    const { t } = useTranslation()
    const wishlistId = route.params.wishlistId
    const wish: IWish = route.params.wish

    const [name, setName] = useState(wish.name ?? '')
    const [comment, setComment] = useState(wish.comment ?? '')
    const [price, setPrice] = useState(wish.price ?? '')
    const [url, setUrl] = useState(wish.url ?? '')
    const [amount, setAmount] = useState(wish.marksMax)
    const [urlImage, setUrlImage] = useState(wish.urlImage ?? '')
    const [uploadLoadingText, setUploadLoadingText] = useState<string>()
    const [loadingText, setLoadingText] = useState<string>()
    const [showCountInput, setShowCountInput] = useState(false)

    const onPressUploadImage = async () => {

        // Pick image
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.2
        })

        // Upload image if needed
        if(!result.cancelled) {
            try {
                setUploadLoadingText(t('ScreenWishEdit.loading_upload_image'))
                const uploadedImageUrl = await api.uploadImage(result.uri)
                setUrlImage(uploadedImageUrl)
            } catch(e: any) {
                Alert.alert(t('ScreenWishEdit.error'), t('ScreenWishEdit.error_upload_image', {message: e.message}))
                console.error(e)
                return
            } finally {
                setUploadLoadingText(undefined)
            }
        }
    }

    const onPressSave = async () => {
        setLoadingText(t('ScreenWishEdit.loading_save'))
        try {
            await api.wishUpdate(wishlistId, wish, name, comment, price, url, urlImage)
            navigation.goBack()
        } catch(e: any) {
            setLoadingText(undefined)
            const userFriendlyMessage = getUserFriendlyErrorMessage(e)
            api.showMessage(t('ScreenWishEdit.error_save'), userFriendlyMessage)
        }
    }

    const onAmountSelected = async (amount: number | null) => {
        setShowCountInput(false)
        try {
            await api.wishSetMaxMarks(wishlistId, wish, amount)
            setAmount(amount ?? 0)
        } catch(e: any) {
            setLoadingText(undefined)
            const userFriendlyMessage = getUserFriendlyErrorMessage(e)
            api.showMessage(t('ScreenWishEdit.error_change_amount'), userFriendlyMessage)
        }
    }

    const onPressChangeAmount = () => {
        setShowCountInput(true)
    }

    return (
        <View style={PageStyle.default}>

            {/* Change amount dialog */}
            { showCountInput && <>
                <WishCountInput initialValue={amount} textAdd={t('ScreenWishEdit.button_amount_save')} onFinished={onAmountSelected}/>
            </> }

            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                <Divider.Medium />

                {/* Back button */}
                <Button.Back text={t('ScreenWishEdit.button_back')} />
                <Divider.Medium />

                <ScrollView style={PageStyle.safeArea}>
                    <>
                        {/* Screen title and subtitle */}
                        <Text style={Typography.Title}>{t('ScreenWishEdit.title')}</Text>
                        <Text style={Typography.Subtitle}>{t('ScreenWishEdit.subtitle')}</Text>
                        <Divider.Medium />

                        {/* Name input */}
                        <TextInput onChangeText={setName} defaultValue={wish.name} title={t('ScreenWishEdit.input_title')} />
                        <Divider.Medium />

                        {/* Comment input */}
                        <TextInput onChangeText={setComment} defaultValue={wish.comment} title={t('ScreenWishEdit.input_comment')} />
                        <Divider.Medium />

                        {/* Price input */}
                        <TextInput onChangeText={setPrice} defaultValue={wish.price} keyboardType="numbers-and-punctuation" title={t('ScreenWishEdit.price')}  />
                        <Divider.Medium />
                        
                        {/* Url input */}
                        <TextInput onChangeText={setUrl} defaultValue={wish.url} keyboardType="url" title={t('ScreenWishEdit.input_link')} />
                        <Divider.Medium />

                        {/* Amount input */}
                        {amount > 0 && <Text style={Typography.InputTitle}>{t('ScreenWishEdit.amount_text', {amount})}</Text> }
                        {!amount && <Text style={Typography.InputTitle}>{t('ScreenWishEdit.amount_text_undefined')}</Text> }
                        <Button.PrimarySmall onPress={onPressChangeAmount} text={t('ScreenWishEdit.button_amount_change')} />
                        <Divider.Medium />

                        {/* Upload image button */}
                        <Text style={Typography.InputTitle}>{t('ScreenWishEdit.text_image')}</Text>
                        <Button.PrimarySmall loadingText={uploadLoadingText} onPress={onPressUploadImage} text={t('ScreenWishEdit.button_upload_image')} />
                        <Divider.Medium />

                        {/* Uploaded image preview */}
                        { urlImage?.length > 0 && <View>
                            <Text style={Typography.InputTitle}>{t('ScreenWishEdit.text_image')}</Text>
                            <ImageBackground style={style.image} source={{ uri: urlImage }} />
                            <Divider.Medium />
                        </View> }

                        {/* Save button */}
                        { name?.length > 0 && <Button.Primary onPress={onPressSave} loadingText={loadingText} text={t('ScreenWishEdit.button_save')} /> }
                        <Divider.Medium />

                    </>
                </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ScreenWishEdit