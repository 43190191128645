import { useNavigation, useRoute } from "@react-navigation/native"
import { Alert, Text, View, SafeAreaView, ScrollView, ImageBackground, StyleSheet, Platform } from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import { useState } from "react"
import * as ImagePicker from "expo-image-picker"
import * as api from "../Api"
import Color from "../style/Color"
import firestore from "@react-native-firebase/firestore"
import * as webFirestore from 'firebase/firestore'
import { useTranslation } from "react-i18next"
import WishCountInput from "../components/WishCountInput"
import { facebookTrackAddToWishlist } from "../Api"

const style = StyleSheet.create({
    image: {
        aspectRatio: 1,
        alignSelf: 'stretch',
        borderRadius: 22.5,
        backgroundColor: Color.ImageBackground,
        overflow: 'hidden'
    }
})

const ScreenWishCreateManually = () => {

    const navigation = useNavigation<any>()
    const route = useRoute<any>()
    const { t } = useTranslation()
    const wishlistId = route.params.wishlistId

    const [name, setName] = useState('')
    const [comment, setComment] = useState('')
    const [price, setPrice] = useState('')
    const [url, setUrl] = useState('')
    const [urlImage, setUrlImage] = useState('')
    const [uploadLoadingText, setUploadLoadingText] = useState<string>()
    const [loadingText, setLoadingText] = useState<string>()
    const [showCountInput, setShowCountInput] = useState(false)

    // Upload wish image
    const onPressUploadImage = async () => {

        // Pick image
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.2
        })

        // Upload image if needed
        if(!result.cancelled) {
            try {
                setUploadLoadingText(t('ScreenWishCreateManually.loading_uploading_picture'))
                const uploadedImageUrl = await api.uploadImage(result.uri)
                setUrlImage(uploadedImageUrl)
            } catch(e: any) {
                Alert.alert(t('ScreenWishCreateManually.error'), t('ScreenWishCreateManually.error_upload_picture', {message: e.message}))
                console.error(e)
                return
            } finally {
                setUploadLoadingText(undefined)
            }
        }
    }

    // Show amount selector
    const onPressCreate = async () => {
        setShowCountInput(true)
    }

    // Add the wish to the wishlist
    const onAmountSelected = async (amount: number | null) => {
        setShowCountInput(false)
        setLoadingText(t('ScreenWishCreateManually.loading_creating'))
        const marksMax = amount ?? 0
        try {
            // @ts-ignore me being lazy
            await api.wishlistAddWish({id: wishlistId}, {
                name, comment, price, url, urlImage, marksMax,
                marks: 0,
                type: 'custom'
            })
            navigation.navigate('Wishlist', { wishlistId })
        } catch(e: any) {
            console.error(e)
            setLoadingText(undefined)
            Alert.alert(t('ScreenWishCreateManually.error'), t('ScreenWishCreateManually.error_creating', {message: e.message}))
            return
        }
    }

    return (
        <View style={PageStyle.default}>


            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                <Divider.Medium />
                { Platform.OS === 'web' && <Divider.Gigantic /> }

                {/* Back button */}
                <Button.Back text={t('ScreenWishCreateManually.button_back')} />
                <Divider.Medium />

                <ScrollView style={PageStyle.safeArea}>

                    {/* Screen title and subtitle */}
                    <Text style={Typography.Title}>{t('ScreenWishCreateManually.title')}</Text>
                    <Text style={Typography.Subtitle}>{t('ScreenWishCreateManually.subtitle')}</Text>
                    <Divider.Medium />

                    {/* Name input */}
                    <TextInput onChangeText={setName} title={t('ScreenWishCreateManually.input_wish')} />
                    <Divider.Medium />

                    {/* Comment input */}
                    <TextInput onChangeText={setComment} title={t('ScreenWishCreateManually.input_comment')} />
                    <Divider.Medium />
                    
                    {/* Price input */}
                    <TextInput onChangeText={setPrice} keyboardType="numbers-and-punctuation" title={t('ScreenWishCreateManually.input_price')}  />
                    <Divider.Medium />
                    
                    {/* Url input */}
                    <TextInput onChangeText={setUrl} keyboardType="url" title={t('ScreenWishCreateManually.input_link')} />
                    <Divider.Medium />

                    {/* Upload image button */}
                    <Text style={Typography.InputTitle}>{t('ScreenWishCreateManually.picture')}</Text>
                    <Button.PrimarySmall loadingText={uploadLoadingText} onPress={onPressUploadImage} text={t('ScreenWishCreateManually.upload_picture')} />
                    <Divider.Medium />

                    {/* Uploaded image preview */}
                    { urlImage?.length > 0 && <View>
                        <Text style={Typography.InputTitle}>{t('ScreenWishCreateManually.uploaded_picture')}</Text>
                        <ImageBackground style={style.image} source={{ uri: urlImage }} />
                        <Divider.Medium />
                    </View> }

                    {/* Create button */}
                    { name?.length > 0 && <Button.Primary onPress={onPressCreate} loadingText={loadingText} text={t('ScreenWishCreateManually.button_create')} /> }
                    <Divider.Medium />
                    
                </ScrollView>
            </SafeAreaView>

            <WishCountInput onFinished={onAmountSelected} visible={showCountInput} />

        </View>
    )
}

export default ScreenWishCreateManually