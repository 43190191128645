import {Text, View, SafeAreaView, ImageBackground, StyleSheet, ScrollView, Alert, TouchableOpacity, Platform} from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import ProfilePlaceholder from '../assets/images/profile-placeholder.png'
import { useEffect, useState } from "react"
import * as ImagePicker from "expo-image-picker"
import * as api from '../Api'
import { getUser, getUserFriendlyErrorMessage } from "../Api"
import { useNavigation, useRoute } from "@react-navigation/native"
import { useTranslation } from "react-i18next"

const style = StyleSheet.create({
    profilePicture: {
        borderRadius: 64,
        width: 128,
        height: 128,
        overflow: 'hidden',
        alignSelf: 'center'
    }
})

const ScreenCreateAccount = () => {

    const navigation = useNavigation<any>()
    const { t } = useTranslation()
    const route = useRoute<any>()
    const [email, setEmail] = useState<string>(route.params.email)
    const [password, setPassword] = useState<string>(route.params.password)
    const [name, setName] = useState<string>('')
    const [loadingText, setLoadingText] = useState<string>()
    const [profilePicture, setProfilePicture] = useState<string | null>(null)

    useEffect(() => {
        suggestGoogleSignInIfAndroid()
    }, [])

    // On Android, suggest to sign in with Google
    const suggestGoogleSignInIfAndroid = () => {
        if(Platform.OS === 'android') {
            Alert.alert(
                t('ScreenCreateAccount.dialog_android_google_title'),
                t('ScreenCreateAccount.dialog_android_google_text'),
                [{
                    text: t('ScreenCreateAccount.no'),
                    onPress: () => {},
                    style: 'cancel'
                },{
                    text: t('ScreenCreateAccount.yes'),
                    onPress: async () => {
                        await api.signInWithGoogle()
                        if(getUser() !== null) {
                            await api.migrateFromCordovaIfNeeded()
                            navigation.navigate('Home')
                        }
                    }
                }]
            )
        }
    }

    // When the user presses the "Change profile picture" button
    const onPressChangeProfilePicture = async () => {
        
        // Pick an image from the gallery
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.2
        })

        // Set profile picture if the user selected an image
        if(!result.cancelled) {
            setProfilePicture(result.uri)
        }
    }

    const onPressCreateAccount = async () => {

        // Upload image if needed
        let imageUrl: string = api.defaultProfilePicture
        if(profilePicture) {
            try {
                setLoadingText(t('ScreenCreateAccount.loading_uploading_picture'))
                imageUrl = await api.uploadImage(profilePicture)
            } catch(e: any) {
                api.showMessage(t('ScreenCreateAccount.error_upload_picture_title'), t('ScreenCreateAccount.error_upload_picture_text'))
                console.error(e)
                return
            } finally {
                setLoadingText(undefined)
            }
        }

        try {
            setLoadingText(t('ScreenCreateAccount.loading_creating_account'))
            await api.createAccountEmail({email, password, name, imageUrl})
            navigation.navigate('Home')
        } catch(e: any) {
            console.error(e)
            setLoadingText(undefined)
            api.showMessage(t('ScreenCreateAccount.error_text'), getUserFriendlyErrorMessage(e))
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                <Divider.Medium />
                <Button.Back text={t('ScreenCreateAccount.button_back')} />
                <Divider.Medium />
                <ScrollView keyboardShouldPersistTaps='always' style={PageStyle.safeArea}>
                    {Platform.OS === 'web' && <Divider.Gigantic /> }

                    {/* Screen title and subtitle */}
                    <Text style={Typography.Title}>{t('ScreenCreateAccount.title')}</Text>
                    <Text style={Typography.Subtitle}>{t('ScreenCreateAccount.subtitle')}</Text>

                    {/* Profile picture */}
                    <TouchableOpacity onPress={onPressChangeProfilePicture}>
                        <ImageBackground source={profilePicture ? {uri: profilePicture} : ProfilePlaceholder} style={style.profilePicture} />
                    </TouchableOpacity>

                    {/* Email input */}
                    <TextInput title={t('ScreenCreateAccount.input_email')} defaultValue={email} onChangeText={setEmail} autoCapitalize="none" keyboardType="email-address" />
                    <Divider.Tiny />

                    {/* Password input */}
                    <TextInput title={t('ScreenCreateAccount.input_password')} defaultValue={password} onChangeText={setPassword} secureTextEntry={true} />
                    <Divider.Tiny />
                    
                    {/* First name input */}
                    <TextInput title={t('ScreenCreateAccount.input_first_name')} onChangeText={setName} />
                    <Divider.Tiny />

                    {/* Option to upload a profile picture */}
                    <Text style={Typography.InputTitle}>{t('ScreenCreateAccount.optional')}</Text>
                    <Button.PrimarySmall text={t('ScreenCreateAccount.choose_picture')} onPress={onPressChangeProfilePicture} />
                    <Divider.Large />
                    
                    {/* Create account button */}
                    { email.length > 0 && password.length > 0 && name.length > 0 && ( 
                        <Button.Primary text={t('ScreenCreateAccount.button_create')} loadingText={loadingText} onPress={onPressCreateAccount} /> 
                    )}

                    <Divider.Extreme />
                </ScrollView>                
            </SafeAreaView>
        </View>
    )
}

export default ScreenCreateAccount