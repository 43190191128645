import { StyleProp, StyleSheet, TextProps, TextStyle } from "react-native";
import Color from "./Color";

export const FontStyle = StyleSheet.create({
    ExtraLight: { fontFamily: 'Mukta_200ExtraLight' },
    Light: { fontFamily: 'Mukta_300Light' },
    Regular: { fontFamily: 'Mukta_400Regular' },
    Medium: { fontFamily: 'Mukta_500Medium' },
    SemiBold: { fontFamily: 'Mukta_600SemiBold' },
    Bold: { fontFamily: 'Mukta_700Bold' },
    ExtraBold: { fontFamily: 'Mukta_800ExtraBold' },
    Roboto: { fontFamily: 'Roboto'}
})

interface ITypography {
    [key: string]: StyleProp<TextStyle>
 }

export default {
    Title: [FontStyle.ExtraBold, {fontSize: 35, color: Color.Dark}],
    Subtitle: [FontStyle.Bold, {fontSize: 24, color: Color.Dark, marginTop: -16}],
    Subsubtitle: [FontStyle.Bold, {fontSize: 14, color: Color.Dark}],
    SubsubtitleLight: [FontStyle.Light, {fontSize: 14, color: Color.Dark}],
    TextLarge: [FontStyle.Medium, {fontSize: 22, color: Color.Dark}],
    Text: [FontStyle.Regular, {fontSize: 18, color: Color.Dark}],
    TextSmall: [FontStyle.Regular, {fontSize: 13, color: Color.Dark}],
    ButtonText: [FontStyle.Regular, {fontSize: 22, color: Color.Background}],
    ButtonSmallText: [FontStyle.Regular, {fontSize: 18, color: Color.Background}],
    Input: [FontStyle.Light, {fontSize: 20, color: Color.Dark}],
    InputTitle: [FontStyle.SemiBold, {fontSize: 14, color: Color.Dark}],
    BackButton: [FontStyle.Light, {fontSize: 14, color: Color.Dark}],
    WishlistTitle: [FontStyle.Bold, {fontSize: 30, color: Color.Dark}],
    WishlistSubtitle: [FontStyle.Regular, {fontSize: 24, color: Color.Dark}],
    ProductName: [FontStyle.SemiBold, {fontSize: 18, color: Color.Dark}],
    ProductComment: [FontStyle.Regular, {fontSize: 12, color: Color.Dark}],
    ProductPriceBefore: [FontStyle.Regular, {fontSize: 18, color: Color.Light, textDecorationLine: 'line-through'}],
    ProductTitle: [FontStyle.SemiBold, {fontSize: 16, color: Color.Dark}],
    ProductBrand: [FontStyle.Regular, {fontSize: 16, color: Color.Light}],
    ReviewGateTitle: [FontStyle.Medium, {fontSize: 20, color: Color.Dark}],
    ReviewGateSubtitle: [FontStyle.Light, {fontSize: 20, color: Color.Dark}],
    ReviewGateDismiss: [FontStyle.Regular, {fontSize: 14, color: Color.Light}],

    Center: {textAlign: 'center'},
    Roboto: [FontStyle.Roboto, {fontSize: 22, opacity: 0.54, color: '#000000'}]
} as ITypography