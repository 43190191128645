import React, { useEffect, useState } from "react"
import { View, SafeAreaView, Text, Platform, ScrollView } from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import IWishlist from "../models/IWishlist"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import * as webFirestore from 'firebase/firestore'
import firestore from '@react-native-firebase/firestore'
import { facebookTrackAddToWishlist, getUser } from "../Api"
import CardWishlist from "../components/CardWishlist"
import IWish from "../models/IWish"
import * as api from "../Api"
import { useNavigation, useRoute } from "@react-navigation/native"
import LoadingOverlay from "../components/LoadingOverlay"
import { useTranslation } from "react-i18next"

// Screen where a user can select a wishlist to add a product to:
const ScreenAddWishSelectWishlist = () => {

    const { t } = useTranslation()
    const [wishlists, setWishlists] = useState<IWishlist[]>([])
    const [loadingText, setLoadingText] = useState<string>()
    const navigation = useNavigation<any>()
    const route = useRoute<any>()
    const product = route.params.product

    useEffect(() => {
        loadWishlists()
    }, [])

    // Get all wishlists the user is an editor of
    const loadWishlists = async () => {
        setLoadingText(t('ScreenAddWishSelectWishlist.loading_wishlists'))
        try {
            const wishlists = await api.getWishlists()
            setWishlists(wishlists)
        } catch(e) {
            api.showMessage(t('ScreenAddWishSelectWishlist.error'), api.getUserFriendlyErrorMessage(e))
        } finally {
            setLoadingText(undefined)
        }
    }

    // Add selected product to selected wishlist
    const onPressWishlist = async (wishlist: IWishlist) => {
        try {
            setLoadingText(t('ScreenAddWishSelectWishlist.loading_adding'))
            api.wishlistAddProduct(wishlist, product)
            api.showMessage(t('ScreenAddWishSelectWishlist.success_title'), t('ScreenAddWishSelectWishlist.success_text'))
            navigation.goBack()
        } catch (e: any) {
            console.error(e)
            setLoadingText(undefined)
            api.showMessage(t('ScreenAddWishSelectWishlist.error'), t('ScreenAddWishSelectWishlist.error_text', {message: e.message}))
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                {loadingText && <LoadingOverlay text={loadingText} />}
                
                {/* Back button */}
                <Divider.Medium />
                <Button.Back text={t('ScreenAddWishSelectWishlist.button_back')} />
                <Divider.Medium />

                <ScrollView style={PageStyle.safeArea}>
                    <Text style={[Typography.Subtitle, {marginTop: 0}]}>{t('ScreenAddWishSelectWishlist.choose_wishlist')}</Text>
                    <Divider.Medium />
                    
                    {/* List of wishlists */}
                    {wishlists.map(wishlist => {
                        return <CardWishlist
                            key={wishlist.id}
                            title={wishlist.title} 
                            subtitle={wishlist.subtitle} 
                            imageUri={wishlist.imageUrl} 
                            onPress={() => onPressWishlist(wishlist)}
                            />
                    })}

                    {/* User has no wishlists, display text */}
                    {wishlists.length === 0 && (
                        <Text style={Typography.Text}>{t('ScreenAddWishSelectWishlist.no_wishlists')}</Text>
                    )}

                    <Divider.Medium />
                </ScrollView>
            </SafeAreaView>
            
        </View>
    )
}

export default ScreenAddWishSelectWishlist