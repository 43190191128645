import React, { useEffect, useState } from "react"
import { ActivityIndicator, Alert, Image, Platform, ScrollView, StyleSheet, Text, View, SafeAreaView } from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import Color from "../style/Color"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import * as api from '../Api'
import CardProduct from "../components/CardProduct"
import { useNavigation, useRoute } from "@react-navigation/native"
import WebView from "react-native-webview"
import IWishlist from "../models/IWishlist"
import LoadingOverlay from "../components/LoadingOverlay"
import {decode} from 'html-entities'
import IProduct from "../models/IProduct"
import * as Linking from 'expo-linking'

// @ts-ignore
import html2plaintext from 'html2plaintext'
import { facebookTrackInitiatedPurchase, getUser } from "../Api"
import WishCountInput from "../components/WishCountInput"
import { useTranslation } from "react-i18next"
import PriceComparison from "../components/PriceComparison"

const style = StyleSheet.create({
    image: {
        alignSelf: 'stretch',
        aspectRatio: 1,
        borderRadius: 22.5,
    },
    imageShadow: {
        shadowColor: Color.Dark,
        shadowRadius: 5,
        shadowOffset: {width: 0, height: 2},
        borderRadius: 22.5,
        elevation: 6,
        maxWidth: Platform.OS === 'web' ? 256 : undefined,
        aspectRatio: Platform.OS === 'web' ? 1 : undefined,
        backgroundColor: Color.ImageBackground,
        borderColor: Color.DarkTransparentMore,
        borderWidth: 1
    },
    textPrice: {
        color: Color.Secondary,
        width: 200
    },
    textLeft: {
        alignSelf:  'flex-start',
        maxWidth: 250,
    },
    textRight: {
        flex: 1,
        textAlign: 'right'
    },
    scrollView: {
        paddingHorizontal: 24,
        alignSelf: 'stretch'
    },
    activityIndicator: {
        alignSelf: 'center'
    },
    webView: {
        height: 500, 
        borderRadius: 22.5, 
        overflow: 'hidden', 
        flex: 1,
        shadowColor: Color.Dark,
        shadowRadius: 5,
        shadowOffset: {width: 0, height: 2},
        elevation: 6,
    }
})

const ScreenProduct = () => {

    const navigation = useNavigation<any>()
    const route = useRoute<any>()
    const { t } = useTranslation()
    const [sameCategoryProducts, setSameCategoryProducts] = useState<IProduct[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isLoadingAddToWishlist, setIsLoadingAddToWishlist] = useState(false)
    const [showCountInput, setShowCountInput] = useState(false)

    const product: IProduct = route.params?.product

    const wishlist: IWishlist | undefined = route.params?.wishlist
    const isDiscoveryScreen: boolean = route.params?.discovery
    const isReservationScreen: boolean = route.params?.reservation
    const wishlistId = wishlist?.id

    const hasDescription = !!product && (product?.description?.length ?? 0) > 0
    const editMode = wishlist?.editors.includes(getUser()?.uid ?? '')
    const hasDiscount = 
        product?.originalPrice && product?.price && 
        product?.price < product?.originalPrice
    const discountPercentage = Math.round(hasDiscount ? (
        (Number(product?.originalPrice) - Number(product?.price)) / Number(product?.originalPrice) * 100
    ) : 0)

    api.useAsyncEffect(async () => {
        if(!product) return
        try {
            const products = await api.productGetCategory(product?.category)
            setSameCategoryProducts(products)
        } catch(e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }, [])
    
    const onPressProduct = (product: IProduct) => {
        const productId = product.id
        let route = 'Product'
        if(isDiscoveryScreen) route = 'DiscoveryProduct'
        if(isReservationScreen) route = 'ReservationsProduct'
        navigation.push(route, {product, wishlist, productId, discovery: isDiscoveryScreen, reservation: isReservationScreen})
    }

    const onPressOpenInBrowser = () => {
        api.openUrl({
            url: product.urlTracking,
            productId: product.id
        })        
    }

    // Once the amount has been selected, add the product to the wishlist and navigate back to the wishlist
    const onAmountSelected = async (amount: number | null) => {
        if(!wishlist) return
        setIsLoadingAddToWishlist(true)
        try {
            await api.wishlistAddProduct(wishlist, product, amount)
            navigation.navigate('Wishlist', {wishlistId})

            // Ask the user if they want to enable price drop notifications
            if(wishlistId) api.askForWishlistNotificationsOnce(wishlistId)

        } catch (e: any) {
            console.error(e)
            Alert.alert(t('ScreenProduct.error'), t('ScreenProduct.error_text', {message: e.message}))
        }
    }

    const onPressAddToWishlist = () => {
        if(!editMode) {
            // Navigate to wishlist selection
            navigation.navigate('AddWishSelectWishlist', {product})
        } else {
            setShowCountInput(true)
        }
    }
    
    return (
        <View style={PageStyle.default}>

            { isLoadingAddToWishlist && <LoadingOverlay text={t('ScreenProduct.loading_adding')} />}
            { showCountInput && <WishCountInput onFinished={onAmountSelected}/>}

            <SafeAreaView style={[PageStyle.fillParent]}>
                <View style={PageStyle.webSafeArea}>
                    <Divider.Medium />
                    <Button.Back text={t('ScreenProduct.button_back')} />
                    <Divider.Small />
                </View>

                <ScrollView contentContainerStyle={[style.scrollView, PageStyle.webSafeArea]} nestedScrollEnabled={true}>

                    {/* Product image */}
                    <View style={style.imageShadow}>
                        <Image style={style.image} source={{uri: product?.urlImage}} />
                    </View>
                    <Divider.Medium />

                    {/* Product name and price */}
                    <Text style={Typography.Subsubtitle}>{t('ScreenProduct.info')}</Text>

                    <View style={PageStyle.row}>
                        <Text style={[Typography.ProductName, style.textLeft]}>{product?.name}</Text>
                        {/*<Text style={[Typography.Text, style.textRight, style.textPrice]}>{product?.price} kr.</Text>*/}
                    </View>
                    <Divider.Small />

                    {/* Add to wishlist button */}
                    <View>
                        <Button.PrimarySmall text={t('ScreenProduct.button_add')} onPress={onPressAddToWishlist} />
                        <Divider.Tiny />
                    </View>

                    {/* Buy button */}
                    <View> 
                        <Button.PrimarySmall text={t('ScreenProduct.button_buy')} onPress={onPressOpenInBrowser} />
                        <Divider.Medium />
                    </View>

                    {/* Product discount information (if any) */}
                    {hasDiscount && <View style={PageStyle.row}>
                        <Text style={[Typography.Text, style.textLeft]}>{t('ScreenProduct.save')} {discountPercentage}%</Text>
                        <Text style={[Typography.ProductPriceBefore, style.textRight]}>{product?.originalPrice} kr.</Text>
                    </View>}

                    {/* Price comparison */}
                    <PriceComparison ean={product.ean} />
                    <Divider.Small />

                    {/* Product description */}
                    {hasDescription && <View>
                        <Text style={Typography.Subsubtitle}>{t('ScreenProduct.description')}</Text>
                        <Text style={Typography.Text}>{html2plaintext(decode(product?.description))}</Text>
                        <Divider.Medium />
                    </View>}

                    {/* Product website (mobile only) */}
                    {Platform.OS !== 'web' && <View>
                        <Text style={Typography.Subsubtitle}>{t('ScreenProduct.product_site')}</Text>
                        <WebView 
                            style={{opacity: 0.99 /* Prevent crash with react navigation, do not remove */}} 
                            sharedCookiesEnabled={true} 
                            nestedScrollEnabled={true} 
                            thirdPartyCookiesEnabled={true} 
                            scalesPageToFit={true} 
                            startInLoadingState={true} 
                            javaScriptEnabled={true}
                            scrollEnabled={true} 
                            containerStyle={style.webView} 
                            source={{uri: product?.urlTracking}} />
                        <Divider.Small />
                    </View>}

                    {/* Open store button */}
                    {Platform.OS !== 'web' && <Button.PrimarySmall onPress={onPressOpenInBrowser} text={t('ScreenProduct.open_browser')} /> }
                    {Platform.OS === 'web' && <Button.PrimarySmall onPress={onPressOpenInBrowser} text={t('ScreenProduct.open_website')} /> }
                    <Divider.Medium />
                    
                    {/* Products in same category */}
                    {isLoading && <ActivityIndicator size='large' color={Color.Primary} style={style.activityIndicator}/>}
                    {sameCategoryProducts.length > 0 && <Text style={Typography.Subsubtitle}>{t('ScreenProduct.same_category')}</Text>}
                    {sameCategoryProducts.length > 0 && <View>
                        {sameCategoryProducts?.map(product => <CardProduct product={product} key={product.id} onPress={() => onPressProduct(product)} />)}
                    </View>}
                    <Divider.Large />

                </ScrollView>
            </SafeAreaView>
        </View>
    )

}

export default ScreenProduct