import IProduct from './models/IProduct'
import AWS from 'aws-sdk'
import { Platform, Alert, Share } from 'react-native'
import * as FileSystem from 'expo-file-system'
import ICordovaWishlist from './models/ICordovaWishlist'
import {decode as atob} from 'base-64'
import * as Notifications from 'expo-notifications'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as htmlparser2 from 'htmlparser2'
import { URL } from 'react-native-url-polyfill'
import { Buffer } from 'buffer'
import auth, { firebase } from '@react-native-firebase/auth';
import firestore from "@react-native-firebase/firestore"
import * as webAuth from 'firebase/auth'
import IProductWithProgram from './models/IProductWithProgram'
import * as Linking from 'expo-linking'
import {decode as decodeEntities} from 'html-entities'
import i18n from "./translations/i18n"
import IUserInfo, { IUserInfoUpdate } from './models/IUserInfo'
import * as webFirestore from 'firebase/firestore'
import IWishlistLink from './models/IWishlistLink'
import IWish from "./models/IWish";
import IWishlist from "./models/IWishlist";
import QRCode from "qrcode-svg"
import * as Clipboard from 'expo-clipboard'
import messaging from '@react-native-firebase/messaging'
const t = i18n.t


AWS.config.update({
    region: 'eu-central-1',
    accessKeyId: 'AKIAVFM3I46JYQ6E4DHJ',
    secretAccessKey: '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',
    signatureVersion: 'v4'
})

const new_search = `https://products.xn--nskeliste-k8a.nu/`
export const defaultProductPicture = `${new_search}product-placeholder.jpg`
export const defaultWishlistBackgroundUrl = `${new_search}card-default.png`
export const defaultProfilePicture = `${new_search}profile-placeholder.png`

export const productFindAll = async (ean: string) => {
    const url = `${new_search}ean?ean=${ean}`
    const response = await fetch(url)
    const data = await response.json()
    return data as IProductWithProgram[]
}

export const getUserFriendlyErrorMessage = (e: any) => {
    const errorCode = e?.code

    switch(errorCode) {
        case 'auth/email-already-in-use':
            return t('UserFriendly.email_already_in_use');
        case 'auth/internal-error':
            return t('UserFriendly.internal_error');
        case 'auth/invalid-display-name':
            return t('UserFriendly.invalid_display_name');
        case 'auth/invalid-email':
            return t('UserFriendly.invalid_email');
        case 'auth/invalid-password':
            return t('UserFriendly.invalid_password');
        case 'auth/weak-password':
            return t('UserFriendly.weak_password');
        case 'auth/invalid-photo-url':
            return t('UserFriendly.invalid_photo_url');
        case 'auth/wrong-password':
            return t('UserFriendly.wrong_password');
        case 'auth/user-not-found':
            return t('UserFriendly.user_not_found');
        case 'auth/user-disabled':
            return t('UserFriendly.user_disabled');
        case 'auth/too-many-requests':
            return t('UserFriendly.too_many_requests');
        default:
            return e?.message ?? t('UserFriendly.unknown')
    }
}

export const isUrl = (urlInput: string) => {
    let url
    try {
        url = new URL(urlInput)
    } catch (_) {
        return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:";
}



export const showMessage = (title: string, text?: string) => {
    if(Platform.OS === 'web') {
        alert(text)
    } else {
        Alert.alert(text?.length === 0 ? '' : title, text?.length === 0 ? title : text)
    }
}

interface IUrlData {
    url: string
    title: string
    description: string
    image: string
    siteName: string
    type: string
    locale: string
    price: string
    currency: string
    availability: string
    brand: string
    condition: string
    identifier: string
    ean: string
    logo: string
    meta: {[key: string]: string}
}

export const wishFromUrl = async (url: string) => {
    return new Promise<IUrlData>(async (resolve, reject) => {
        try {
            const endpoint = `${new_search}proxy2?url=${url}`
            const response = await fetch(endpoint)
            const data = await response.json()
            console.log(data)
            resolve(data as IUrlData)
        } catch(e) {
            console.error(e)
            reject(e)
        }
    })
}

export const wishlistAddWishFromUrl = async (wishlist: IWishlist, url: string) => {
    const urlInfo = await wishFromUrl(url)
    const wish: IWish = {
        name: urlInfo.title?.trim() ?? '',
        description: urlInfo?.description?.trim() ?? '',
        price: `${urlInfo?.price ?? ''} ${urlInfo?.currency ?? ''}`.trim() ?? '',
        url,
        urlImage: urlInfo?.image?.trim() ?? defaultProductPicture,
        marks: 0,
        marksMax: 1,
        type: 'custom'
    }

    const category = urlInfo?.meta['product:category'] ?? urlInfo?.meta['category'] ?? undefined
    if(category) wish.category = category
    if(urlInfo?.ean) wish.ean = urlInfo.ean
    await wishlistAddWish(wishlist, wish)
}

// Ask for permission to send push notifications
export const askForNotificationPermission = async () => {
    let notificationsAllowed = true
    if(Platform.OS === 'ios') {
        const permissions = await Notifications.getPermissionsAsync()
        if(!permissions.granted) {
            const requestRepsonse = await Notifications.requestPermissionsAsync({
                ios: {
                    allowAnnouncements: true,
                    allowAlert: true,
                    allowBadge: true,
                    allowSound: true
                }
            })
            notificationsAllowed = requestRepsonse.granted
        }
    }
    return notificationsAllowed
}

// Enable reservation buy reminder notifications
export const enableReservationNotifications = async () => {
    if(Platform.OS !== 'ios') return

    askForNotificationPermission()
    try {
        await Notifications.scheduleNotificationAsync({
            content: {
                title: t('Notification.reservation_reminder_title'),
                subtitle: t('Notification.reservation_reminder_subtitle'),
                body: t('Notification.reservation_reminder_body'),
                sound: 'notification.wav',
                data: {
                    type: 'reservation-reminder'
                }
            },
            trigger: {
                hour: 12,
                minute: 0
            },
        })
        await AsyncStorage.setItem('enabledReservationNotifications', 'true')
    } catch(e: any) {
        console.error(e)
        showMessage(t('Notification.error'), t('Notification.error_message', {message: e?.message}))
    }
}   

// Disable reservation buy reminder notifications
export const disableReservationNotifications = async () => {
    if(Platform.OS !== 'ios') return
    try {
        await Notifications.cancelAllScheduledNotificationsAsync()
        await AsyncStorage.setItem('enabledReservationNotifications', 'false')
    } catch(e: any) {
        console.error(e)
        showMessage(t('Notification.error'), t('Notification.error_deactivate_message', {message: e?.message}))
    }
}

// Get product by id
export const getProduct = async (productId: string) => {
    try {
        const response = await fetch(`${new_search}product/?id=${productId}`)
        const product = await response.json()
        return product as IProduct
    } catch(e) {
        console.error(e)
        return null
    }
}

// Get discounted products
export const productsFilterDiscounted = async (productIds: string[]) => {
    const productsIdsString = productIds.join(',')
    try {
        const response = await fetch(`${new_search}discounted/?productIds=${productsIdsString}`)
        const products = await response.json()
        return products as IProduct[]
    } catch(e) {
        console.error(e)
        return []
    }
}

// Get top 50 most popular products in a category
export const productsGetPopularCategory = async (category: string) => {
    try {
        const response = await fetch(`${new_search}popular/category?category=${category.replace(/&/g, '%26')}`)
        const products = await response.json()
        return products as IProduct[]
    } catch(e) {
        console.error(e)
        return []
    }
}

// Get top 5 most popular wishes
export const productGetPopular = async () => {
    try {
        const url = `${new_search}popular`
        const response = await fetch(url)
        const products = await response.json() as IProduct[]
        return products
    } catch(e) {
        return []
    }
}

// Inform server that a product was added to a wishlist
export const informProductAdd = async (product: IProduct)  => {
    return fetch(`${new_search}add?id=${product.id}`)
}

// Inform server that a products was bought
export const informProductBuy = (product: IProduct)  => {
    return fetch(`${new_search}buy?id=${product.id}`)
}

// Inform server that a products was bought
export const informProductBuyById = (productId: string)  => {
    return fetch(`${new_search}buy?id=${productId}`)
}

// Get products in a category
export const productGetCategory = async (category: string, page?: number) => {
    const url = `${new_search}category?query=${encodeURIComponent(category)}&page=${page ?? 0}`
    const response = await fetch(url)
    const products: IProduct[] = await response.json()
    if(!products) return Promise.reject(t('Api.error_parse_product'))
    return Promise.resolve(products)
}

// Limit text to a certain length
export const limitText = (text: string | undefined, chars: number) => {
    if(!text) return '' 
    return text.substring(0, chars)
}

// Search for a product
export const productSearch = async (query: string, pageNumber: number, abortSignal?: AbortSignal) => {
    if(query.length < 3) return Promise.resolve([])
    try {
        const url = `${new_search}?page=${pageNumber}&query=${query}`
        const response = await fetch(url, { signal: abortSignal })
        const products: IProduct[] = await response.json()        
        if(!products) return Promise.reject(t('Api.error_parse_product'))
        return Promise.resolve(products)
    } catch(e) {
        return Promise.reject(e)
    }
}

// Upload image to S3 directly using signed URL
export const uploadImage = async (imageUrl: string) => {
    
    // Initialize S3
    const S3 = new AWS.S3()

    // Prepare image filename
    const Key = new Date().getTime() + '-' + imageUrl.split('/').pop()

    try {
        // Get signed URL from S3
        const signedUrl = await S3.getSignedUrlPromise('putObject', {
            Bucket: 'wituz-wishlist-expo',
            Key,
            Expires: 60,
            ACL: 'public-read',
        })

        // Create blob from image uri
        const image = await fetch(imageUrl)
        const blob = await image.blob()

        // Upload image to S3
        const response = await fetch(signedUrl, {
            method: 'PUT',
            body: blob
        })

        // Return image url
        if(Platform.OS === 'web') {
            return response?.url?.split('?')[0]
        } else {
            const uploadDetails = JSON.parse(JSON.stringify(response))
            return uploadDetails?.url?.split('?')[0]
        }
        
    } catch(e) {
        console.error(e)
        return Promise.reject(JSON.stringify(e))
    }
}

// Cordova migration end file path
const cordovaMigrationEndedFilePath = FileSystem.documentDirectory + 'endmigration.txt'

// Check if cordova migration has ended
export const getDidEndCordovaMigration = async () => {
    try {
        const exists = await FileSystem.getInfoAsync(cordovaMigrationEndedFilePath)
        return exists.exists
    } catch(e) {
        return false
    }
}

// Write a file that symbolizes, that we should not migrate from cordova from now on
export const endCordovaMigration = async () => {
    const didEndCordovaMigration = await getDidEndCordovaMigration()
    if(didEndCordovaMigration) return
    await FileSystem.writeAsStringAsync(cordovaMigrationEndedFilePath, 'friendship ended with cordova, now react native is my new best friend')
}

export const getCordovaWishlists = async () => {

    const didEndCordovaMigration = await getDidEndCordovaMigration()
    if(didEndCordovaMigration) return []

    if(Platform.OS === 'android') {
        try {
            // List all log files
            const levelDbPath = `${FileSystem.cacheDirectory}../app_webview/Default/Local Storage/leveldb`
            const files = await FileSystem.readDirectoryAsync(levelDbPath)
            const logFiles = files.filter(file => file.endsWith('.log'))
    
            // Go through all log files
            for(const logFile of logFiles) {
                // Read the file 
                const data = await FileSystem.readAsStringAsync(`${levelDbPath}/${logFile}`, {encoding: 'base64'})
                
                // Parse base64 string and remove binary data
                const text = atob(data).replace(/\p{C}/gu, '')

                // Check if this is the localstorage file from the Cordova app
                const jsonIndex = text.indexOf(`[{"Id":`)
                if(jsonIndex !== -1) {

                    // Parse the JSON
                    const jsonRaw = text.slice(text.indexOf(`[{"Id":`), text.length).split('}]')[0] + '}]'
                    const previousLocalStorage = JSON.parse(jsonRaw)
                    return previousLocalStorage as ICordovaWishlist[]
                }
            }

            return [] as ICordovaWishlist[]
        } catch(e: any) {
            console.error(e?.message)
            await endCordovaMigration()
            return [] as ICordovaWishlist[]
        }
    } else if(Platform.OS === 'ios') {
        try {
            // List all localstorage files from WebKit directory
            const pathToDatabaseFile = FileSystem.cacheDirectory?.replace('Caches/', 'WebKit/WebsiteData/LocalStorage/')
            if(!pathToDatabaseFile) return [] as ICordovaWishlist[]

            // Go through every one of the files
            const files = await FileSystem.readDirectoryAsync(pathToDatabaseFile)
            const logFiles = files.filter(file => file.endsWith('.localstorage'))
            
            for(const logFile of logFiles) {
                // Read the file
                const pathToLogFile = `${pathToDatabaseFile}${logFile}`
                let data = await FileSystem.readAsStringAsync(pathToLogFile, {encoding: 'base64'})

                // Turn it into ASCII so we can do string parsing
                const text = atob(data).replace(/\p{C}/gu, '')

                // Check if this is the localstorage file from the Cordova app
                const vuexIndex = text.indexOf(`vuex{`)
                if(vuexIndex === -1) continue

                // Slice the text to get the JSON, and remove non-ascii characters
                const vuexRaw = text.slice(vuexIndex + 4, text.length)

                // Return the legacy LocalStorage as JSON
                return JSON.parse(vuexRaw).wishlists as ICordovaWishlist[]
            }

            return [] as ICordovaWishlist[]
        } catch(e: any) {
            console.error(e?.message)
            await endCordovaMigration()
            return [] as ICordovaWishlist[]
        }
    } else {
        return [] as ICordovaWishlist[]
    }
}

export const getCordovaWishlist = async (cordovaWishlistId: number): Promise<ICordovaWishlist | null> => {
    try {
        const url = `https://xn--nskeliste-k8a.nu:7000/api/v1.2/wishlist.php?Id=${cordovaWishlistId}`
        const response = await fetch(url)
        const json = await response.json()
        return Promise.resolve(json as ICordovaWishlist)
    } catch(e) {
        return Promise.reject(e)
        console.error(e)
    }
}

export const migrateFromCordovaIfNeeded = async () => {
    try {
        const cordovaWishlists = await getCordovaWishlists()
        if(cordovaWishlists.length === 0) return

        // Check if we already migrated
        const migratedWishlistCheck = await firebase
            .firestore()
            .collection('wishlist')
            .where('cordovaWishlistId', '==', `${cordovaWishlists[0].Id}`)
            .get()

        // If already migrated, delete the cordova wishlists
        if(!migratedWishlistCheck.empty) {
            console.log('Already migrated, ending cordova migration')
            await endCordovaMigration()
            return
        }

        // Get current user
        const user = await getUser()
        if(!user) return
        
        // Go through every cordova wishlist found in previous LocalStorage
        cordovaWishlists.forEach(async (localCordovaWishlist) => {

            // Get wishlist from old API
            const cordovaWishlist = await getCordovaWishlist(localCordovaWishlist.Id)
            if(!cordovaWishlist) return

            // Create the wishlist
            const response = await wishlistCreate(
                cordovaWishlist.Title, 
                cordovaWishlist.Description, 
                cordovaWishlist.Image,
                `${cordovaWishlist.Id}`)
            const wishlistId = response.id

            // Create the wishes
            cordovaWishlist.Wishes.forEach(async (cordovaWish) => {
                await firestore().collection('wishlist').doc(wishlistId).collection('wish').add({
                    name: (cordovaWish.OverwrittenName && cordovaWish.OverwrittenName.length > 0) ? cordovaWish.OverwrittenName : cordovaWish.Product.Title, 
                    comment: cordovaWish.OverwrittenDescription, 
                    price: cordovaWish.OverwrittenPrice ?? cordovaWish.Product.Price, 
                    url: (cordovaWish.OverwrittenUrl && cordovaWish.OverwrittenUrl.length > 0) ? cordovaWish.OverwrittenUrl : cordovaWish.Product.UrlTracking, 
                    urlImage: (cordovaWish.OverwrittenImage && cordovaWish.OverwrittenImage.length > 0) ? cordovaWish.OverwrittenImage : cordovaWish.Product.UrlImage, 
                    marks: cordovaWish.Marks,
                    marksMax: 1, // Default from legacy Cordova wishlists, since we didn't have this feature 
                    position: cordovaWishlist.Wishes.length - cordovaWish.Position // Reverse the position
                })
            })
        })
        
        // Stop cordova migration
        await endCordovaMigration()

        return Promise.resolve()
    } catch(e: any) {
        console.error(e)
        return Promise.resolve()
    }
}

export const signInWithGoogle = async () => {
    if(Platform.OS === 'web') {
        try {
            const auth = webAuth.getAuth()
            const provider = new webAuth.GoogleAuthProvider()
            await webAuth.signInWithPopup(auth, provider)
            const user = getUser()
            if(!user) return
            finalizeAccountCreationIfNeeded(user.displayName ?? '', user.email ?? '', user.photoURL ?? '', 'google')
        } catch(e) {
            console.error(e)
        }
    } else {
        try {
            const { GoogleSignin } = require('@react-native-google-signin/google-signin')
            const { idToken } = await GoogleSignin.signIn()
            const googleCredential = auth.GoogleAuthProvider.credential(idToken)
            await auth().signInWithCredential(googleCredential)
            const user = getUser()
            if(!user) return
            await finalizeAccountCreationIfNeeded(user.displayName ?? '', user.email ?? '', user.photoURL ?? defaultProfilePicture, 'google')
            facebookTrackCompletedRegistration('google')
        } catch (e) {
            console.error(e)
        }
    }
}

export const finalizeAccountCreationIfNeeded = async (name: string, email: string, imageUrl: string, provider: 'email' | 'google' | 'apple') => {
    if(Platform.OS === 'web') {

        // Get current user
        const user = webAuth.getAuth().currentUser
        if(!user) {
            console.log('No user found')
            return
        }

        // Ensure this is a new user
        const db = webFirestore.getFirestore()
        const userInfoCollection = webFirestore.collection(db, 'userInfo')
        const userInfoDoc = webFirestore.doc(userInfoCollection, user.uid)
        const userInfo = await webFirestore.getDoc(userInfoDoc)
        if(userInfo.exists()) {
            console.log('User already exists, skipping account finalization')
            return
        }

        // Finalize account creation
        await webAuth.updateProfile(user, {displayName: name, photoURL: imageUrl})
        await finalizeAccountCreation(email, {name, image: imageUrl})
    } else {

        // Get current user
        const user = auth().currentUser
        if(!user) return

        // Ensure this is a new user
        const userInfoDoc = await firestore().collection('userInfo').doc(user.uid).get()
        if(userInfoDoc.exists) return
        if(['email', 'apple'].includes(provider)) await user.updateProfile({displayName: name, photoURL: imageUrl})
        
        // Finalize account creation
        await finalizeAccountCreation(email, {name, image: imageUrl})
        await facebookTrackCompletedRegistration(provider)
    }
}

import React from 'react'
import { useFocusEffect } from '@react-navigation/native'
import IReservation from './models/IReservation'
import slugify from 'slugify'

export const signInWithApple = async () => {

    if(Platform.OS === 'web') {
        return Promise.reject(new Error('Not supported on web'))
    } else {
        try {
            
            const { appleAuth } = require('@invertase/react-native-apple-authentication')

            // Start the sign-in request
            const appleAuthRequestResponse = await appleAuth.performRequest({
                requestedOperation: appleAuth.Operation.LOGIN,
                requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
            });


            // Ensure Apple returned a user identityToken
            if (!appleAuthRequestResponse.identityToken) {
                throw new Error('Apple Sign-In failed - no identify token returned');
            }

            const displayName = appleAuthRequestResponse?.fullName?.givenName ?? ''

            // Create a Firebase credential from the response
            const { identityToken, nonce } = appleAuthRequestResponse;
            const appleCredential = auth.AppleAuthProvider.credential(identityToken, nonce);

            // Sign the user in with the credential
            await auth().signInWithCredential(appleCredential);

            const user = getUser()
            if(!user) return
            console.log(user.email)
            await finalizeAccountCreationIfNeeded(user.displayName ?? displayName, user.email ?? '', user.photoURL ?? defaultProfilePicture, 'apple')
            facebookTrackCompletedRegistration('apple')
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

interface openUrlProps {
    url: string
    wishlistId?: string
    productId?: string
    trackBuy?: boolean
}
export const openUrl = async (props: openUrlProps) => {

    if(!props.url) return
    console.log(props.url)
    let url = `${new_search}go?url=${encodeURIComponent(props.url)}`

    if(props.trackBuy || props.trackBuy === undefined) {
        if(props.productId) await informProductBuyById(props.productId)
        await facebookTrackInitiatedPurchase('product')
    }

    if(Platform.OS === 'web') {
        window.open(url, '_blank');
    } else {
        if(await Linking.canOpenURL(props.url)) {
            await Linking.openURL(url)
        } else {
            return Promise.reject(new Error(t('Api.error_open_url')))
        }
    }
}


// Get the current user
export const getUser = () => {
    if(Platform.OS === 'web') {
        return webAuth.getAuth().currentUser
    } else {
        return auth().currentUser
    }
}

export const setWishlistLink = async (wishlistId: string) => {
    // Prepare wishlist link
    const ip = await getIp()
    const wishlistLink: IWishlistLink = { wishlistId, ip }
    
    // Insert wishlist link into firestore
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistLinkRef = webFirestore.doc(db, 'links/' + ip)
        return webFirestore.setDoc(wishlistLinkRef, wishlistLink)
    } else {
        return firestore().collection('links').doc(ip).set(wishlistLink)
    }
}

export const addShareFromIp = async () => {
    
    const user = getUser()
    if(!user) return Promise.reject(t('FirestoreApi.error_not_logged_in'))

    const wishlistId = await getWishlistIdFromIp()
    if(!wishlistId) return 
    
    if(Platform.OS === 'web') {
        // Check if a wishlist exists with the given id
        const db = webFirestore.getFirestore()
        const wishlistRef = webFirestore.collection(db, 'wishlist')
        const wishlistResponse = await webFirestore.getDocs(wishlistRef)
        const wishlist = wishlistResponse.docs.find(wishlist => wishlist.id === wishlistId)
        if(!wishlist) return 

        // Check if the user is already an editor
        const editors = wishlist.data().editors
        if(editors.includes(user.uid)) return 

        // Delete the link regardless of what happens
        await deleteWishlistLink()

        // Check if this wishlist was shared with the user before by wishlistId and uid query:
        const uid = user.uid
        const shareCollectionRef = webFirestore.collection(db, 'share')
        const shareQuery = webFirestore.query(shareCollectionRef, 
            webFirestore.where('wishlistId', '==', wishlistId), 
            webFirestore.where('uid', '==', uid))
        const shareResponse = await webFirestore.getDocs(shareQuery)
        if(!shareResponse.empty) return

        // Otherwise, add a new share entry
        const share = { wishlistId, uid }
        return webFirestore.addDoc(shareCollectionRef, share)
    } else {
        // Don't add to shared wishlists if the user is already an editor
        const wishlist = await firestore().collection('wishlist').doc(wishlistId).get()

        // Ensure the wishlist exists
        if(!wishlist.exists) return

        const editors = wishlist.data()?.editors
        if(!editors || editors.includes(user.uid)) return

        // Delete the link regardless of what happens
        await deleteWishlistLink()

        // Check if this wishlist was shared with the user before
        const uid = user.uid
        const shareDoc = await firestore().collection('share')
            .where('wishlistId', '==', wishlistId)
            .where('uid', '==', uid).get()

        // If the user already opened this wishlist, do nothing
        if(!shareDoc.empty) return
        facebookTrackOpenSharedWishlist(wishlistId)
        // Otherwise, add a new share entry
        return firestore().collection('share').add({
            wishlistId,
            uid,
        })
    }
}

export const facebookTrackCompletedRegistration = async (registrationMethod: 'email' | 'google' | 'apple') => {
    if(Platform.OS !== 'web') {
        const { AppEventsLogger } = require('react-native-fbsdk-next')
        AppEventsLogger.logEvent('registration_complete', {
            [AppEventsLogger.AppEventParams.RegistrationMethod]: registrationMethod
        })
    }
}

export const facebookTrackCreateWishlist = async () => {
    if(Platform.OS !== 'web') {
        const { AppEventsLogger } = require('react-native-fbsdk-next')
        AppEventsLogger.logEvent('wishlist_create')
    }
}

interface WishAddTrackingParams {
    type: 'link' | 'custom' | 'product'
    title?: string
    search?: string
}
export const facebookTrackAddToWishlist = async (params: WishAddTrackingParams) => {
    if(Platform.OS !== 'web') {
        const { AppEventsLogger } = require('react-native-fbsdk-next')
        AppEventsLogger.logEvent('wishlist_add_wish', {
            [AppEventsLogger.AppEventParams.AddType]: params.type,
            [AppEventsLogger.AppEventParams.Content]: params?.title ?? '',
            [AppEventsLogger.AppEventParams.SearchString]: params?.search ?? ''
        })
    }
}

export const facebookTrackNavigation = async (screenName: string) => {
    if(Platform.OS !== 'web') {
        const { AppEventsLogger } = require('react-native-fbsdk-next')
        AppEventsLogger.logEvent('screen_view', {
            [AppEventsLogger.AppEventParams.Content]: screenName
        })
    }
}

export const facebookTrackInitiatedPurchase = async (type: 'custom' | 'product') => {
    if(Platform.OS !== 'web') {
        const { AppEventsLogger } = require('react-native-fbsdk-next')
        AppEventsLogger.logEvent('initiated_checkout', {
            [AppEventsLogger.AppEventParams.ContentType]: type
        })
    }
}

export const facebookTrackShareWishlist = async (wishlistId: string, title: string) => {
    if(Platform.OS !== 'web') {
        const { AppEventsLogger } = require('react-native-fbsdk-next')
        AppEventsLogger.logEvent('wishlist_share', {
            [AppEventsLogger.AppEventParams.Content]: title,
            [AppEventsLogger.AppEventParams.ContentID]: wishlistId
        })
    }
}

export const facebookTrackOpenSharedWishlist = async(wishlistId: string) => {
    if(Platform.OS !== 'web') {
        const { AppEventsLogger } = require('react-native-fbsdk-next')
        AppEventsLogger.logEvent('receive_shared_wishlist', {
            [AppEventsLogger.AppEventParams.ContentID]: wishlistId
        })
    }
}

// Get wishlist id from ip
export const getWishlistIdFromIp = async () => {
    const ip = await getIp()
    
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistLinkRef = webFirestore.doc(db, 'links/' + ip)
        const wishlistLink = await webFirestore.getDoc(wishlistLinkRef)
        if(!wishlistLink.exists) return 
        return wishlistLink?.data()?.wishlistId as string | undefined
    } else {
        const wishlistLink = await firestore().collection('links').doc(ip).get()
        if(!wishlistLink.exists) return 
        return wishlistLink?.data()?.wishlistId as string | undefined
    }
}

// Delete all wishlist links from Firestore based on user ip
export const deleteWishlistLink = async () => {
    const ip = await getIp()
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistLinkRef = webFirestore.doc(db, 'links/' + ip)
        return webFirestore.deleteDoc(wishlistLinkRef)
    } else {
        return firestore().collection('links').doc(ip).delete()
    }
}

export const getIp = async () => {
    const response = await fetch('https://products.xn--nskeliste-k8a.nu/ip')
    const json = await response.json()
    return json.ip as string
}

// Create a new wishlist on Firestore:
export const wishlistCreate = async (title: string, subtitle: string, imageUrl: string, cordovaWishlistId?: string, notifications?: boolean) => {

    // Check if the user is logged in
    const user = getUser()
    if(!user) return Promise.reject(t('FirestoreApi.error_not_logged_in'))
    const userId = user.uid

    // Create a new wishlist
    const wishlist: any = {
        editors: [userId],
        title, subtitle, imageUrl, 
        notifications: notifications ? true : false
    }

    if(cordovaWishlistId) wishlist.cordovaWishlistId = cordovaWishlistId

    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistRef = webFirestore.collection(db, 'wishlist')
        return webFirestore.addDoc(wishlistRef, wishlist)
    } else {
        facebookTrackCreateWishlist()
        return firestore().collection('wishlist').add(wishlist)
    }
}

// Create userinfo on Firestore:
export const finalizeAccountCreation = async (email: string, userInfo: IUserInfo) => {
    try {
        if(Platform.OS === 'web') {
            const user = getUser()
            if(!user) return Promise.reject(t('FirestoreApi.error_not_logged_in'))
            const db = webFirestore.getFirestore()
            const userRef = webFirestore.doc(db, `userInfo/${user.uid}`)
            const editorRef = webFirestore.doc(db, `editor/${email}`)
            await webFirestore.setDoc(userRef, userInfo as any)
            await webFirestore.setDoc(editorRef, {uid: user.uid})
        } else {
            const user = await auth().currentUser
            if(!user) return Promise.reject(t('FirestoreApi.error_not_logged_in'))
            await firestore().collection('userInfo').doc(user.uid).set(userInfo)
            await firestore().collection('editor').doc(email).set({uid: user.uid})
        }
        return Promise.resolve()
    } catch(e) {
        return Promise.reject(e)
    }
}

export const wishlistToHtml = (wishlist: IWishlist, wishes: IWish[]): string => {
    
    const qrCodes = wishes.map(wish => {
        if(wish.url) {
            return new QRCode({content: wish.url, width: 92, height: 92}).svg()
        } else return ''
    })

    const wishesHtml = wishes.map((wish, index) => {
        const qrCode = qrCodes[index]
        return `
            <a href="${wish.url}" target="_blank">
                <div class="wish-container">
                    <img class="wish-image" src="${wish.urlImage.length > 0 ? wish.urlImage : defaultProductPicture}" />
                    <div class="qr-container" ${wish.url.length === 0 && 'style="display: none;"'}>
                        ${qrCode}
                    </div>
                    <div class="text-container">
                        <p class="title">${wish.name}</p>
                        <p class="subtitle">${wish.comment ?? wish.description}</p>
                    </div>
                </div>
            </a>
        `
    }).join("")

    return `
<!DOCTYPE html>
    <body>
        <style>

            :root {
                --background: #FFF7FF;
            }

            body {
                width: 100%;
                margin: 0;
                padding: 8px;
                background-color: white;
            }
            
            .logo-container {
                width: 100%;
                text-align: center;
                margin-top: 16px;
                margin-bottom: 16px;
            }

            .logo {
                height: 64px;
            }

            .wish-container {
                margin: 8px;
                height: 92px;
                width: calc(100% - 32px);
                border-radius: 22.5px;
                border-color: rgba(0, 0, 0, 0.1);
                border-width: 1px;
                border-style: solid;
                background-color: var(--background);
                box-shadow: 1px 5px 14px 0px rgba(0,0,0,0.05);
                overflow: hidden;
            }

            .wish-image {
                width: 92px;
                height: 92px;
                background-color: #FFF7FF;
                display: inline-block;
            }

            .text-container {
                width: calc(100% - 232px);
                height: 100%;
                display: inline-block;
                vertical-align: top;
                padding: 0 8px;
            }

            .qr-container {
                width: 92px;
                height: 92px;
                display: inline-block;
                text-align: center
            }
            
            .qr {
                width: 92px;
                height: 92px;
            }

            p {
                font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                margin-top: 8px;
                color: black;
            }

            .title {
                font-weight: bolder;
                line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                        line-clamp: 1; 
                -webkit-box-orient: vertical;
            }

            .subtitle {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                        line-clamp: 2; 
                -webkit-box-orient: vertical;
            }

            .wishlist-title {
                font-weight: bold;
                font-size: 40px;
                padding-left: 8px;
                margin: 0;
            }
            .wishlist-subtitle {
                font-weight: bold;
                font-size: 25px;
                padding-left: 8px;
                margin: 0;
                margin-bottom: 16px;
            }
            .wishlist-text-wishes {
                font-size: 20px;
                padding-left: 8px;
                margin-top: 8px;
                margin-bottom: 8px;
            }
        </style>

        <!-- Wishlist begin -->
        <div class="logo-container">
            <a href="https://ønskeliste.nu/liste/${wishlist.id}">
                <img class="logo" src="https://products.xn--nskeliste-k8a.nu/logo.png">
            </a>
        </div>
        <p class="wishlist-title">${wishlist.title}</p>
        <p class="wishlist-subtitle">${wishlist.subtitle}</p>


        <!-- Wishes -->
        <p class="wishlist-text-wishes">
            Ønsker
        </p>
        ${wishesHtml}
    </body>
</html>
    `
}

export const wishlistToText = (wishlist: IWishlist, wishes: IWish[]): string => {
    const wishesText = wishes.map(wish => {
        return `- ${wish.name}`
    }).join("\n")
    return `Ønskeliste:
${wishlist.title}
${wishlist.subtitle}

Ønsker:
${wishesText}
    `
}

export const getWishlists = async () => {
    const user = getUser()
    if(!user) return []

    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistRef = webFirestore.collection(db, 'wishlist')    
        const wishlistQuery = webFirestore.query(wishlistRef, webFirestore.where('editors', 'array-contains', user.uid))
        const response = await webFirestore.getDocs(wishlistQuery)
        const wishlists: IWishlist[] = []
        response.docs.forEach(doc => {
            const wishlist = doc.data() as IWishlist
            wishlist.id = doc.id
            wishlists.push(wishlist)
        })
        return wishlists
    } else {
        const response = await firestore()
            .collection('wishlist')
            .where('editors', 'array-contains', user.uid)
            .get()
        const wishlists: IWishlist[] = []
        response.forEach(doc => {
            const wishlist = doc.data() as IWishlist
            wishlist.id = doc.id
            wishlists.push(wishlist)
        })
        return wishlists
    }
}

export const getSharedWishlists = async () => {
    const user = getUser()
    if(!user) return []
    let sharedWishlists: IWishlist[] = []
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const shareRef = webFirestore.collection(db, 'share')
        const shareQuery = webFirestore.query(shareRef, webFirestore.where('uid', '==', user.uid))
        const shareResponse = await webFirestore.getDocs(shareQuery)
        for(let i=0; i<shareResponse.docs.length; i++) {
            const share = shareResponse.docs[i].data()
            const wishlistId = share.wishlistId
            const wishlistDocRef = webFirestore.doc(db, `wishlist/${wishlistId}`)
            const wishlist = (await webFirestore.getDoc(wishlistDocRef)).data()
            if(wishlist) {
                wishlist.id = wishlistId
                sharedWishlists.push(wishlist as IWishlist)
            }
        }
    } else {
        const shareRef = firestore().collection('share')
        const shareQuery = shareRef.where('uid', '==', user.uid)
        const shareResponse = await shareQuery.get()
        for(let i=0; i<shareResponse.docs.length; i++) {
            const share = shareResponse.docs[i].data()
            const wishlistId = share.wishlistId
            const wishlistDocRef = firestore().collection('wishlist').doc(wishlistId)
            const wishlist = (await wishlistDocRef.get()).data()
            if(wishlist) {
                wishlist.id = wishlistId
                sharedWishlists.push(wishlist as IWishlist)
            }
        }
    }
    return sharedWishlists
}

export const wishlistGetWishAmount = async (wishlist: IWishlist) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishesRef = webFirestore.collection(db, `wishlist/${wishlist.id}/wish`)
        const wishes = await webFirestore.getDocs(wishesRef)
        return wishes.size
    } else {
        const wishes = await firestore().collection('wishlist').doc(wishlist.id).collection('wish').get()
        return wishes.size
    }
}

export const wishlistAddProduct = async (wishlist: IWishlist, product: IProduct, marksMax: number | null = 0) => {
    const wish: IWish = {
        name: product.name,
        description: product.description,
        marks: 0,
        marksMax: marksMax ?? 0,
        price: product.price,
        url: product.urlTracking,
        urlImage: product.urlImage,
        originalPrice: product.originalPrice,
        shippingPrice: product.shippingPrice,
        productId: product.id,
        category: product.category ?? '',
        wishlistId: wishlist.id,
        notifications: wishlist.notifications,
        type: 'product'
    }
    await wishlistAddWish(wishlist, wish)
    await informProductAdd(product)
}

export const wishlistAddWish = async (wishlist: IWishlist, wish: IWish) => {
    wish.position = await wishlistGetWishAmount(wishlist)
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishCollectionRef = webFirestore.collection(db, `wishlist/${wishlist.id}/wish`)
        await webFirestore.addDoc(wishCollectionRef, wish)
    } else {
        await firestore().collection('wishlist').doc(wishlist.id).collection('wish').add(wish)
        await facebookTrackAddToWishlist({title: wish.name, type: 'link'})
    }
    console.log('done')
}

interface CreateAccountProps {
    email: string
    password: string
    name: string
    imageUrl: string
}
export const createAccountEmail = async (props: CreateAccountProps) => {
    if(Platform.OS === 'web') {
        const auth = webAuth.getAuth()
        await webAuth.createUserWithEmailAndPassword(auth, props.email, props.password)
        const user = auth.currentUser
        if(!user) {
            showMessage(t('ScreenCreateAccount.error_title'), t('ScreenCreateAccount.error_create_user'))
            return
        }
        await finalizeAccountCreationIfNeeded(props.name, props.email, props.imageUrl, 'email')
    } else {
        await auth().createUserWithEmailAndPassword(props.email, props.password)
        const user = auth().currentUser
        if(!user) {
            showMessage(t('ScreenCreateAccount.error_title'), t('ScreenCreateAccount.error_text'))
            return
        }
        await finalizeAccountCreationIfNeeded(props.name, props.email, props.imageUrl, 'email')
        await migrateFromCordovaIfNeeded()
    }
}

export const getUserInterests = async () => {
    try {
        const user = getUser()
        if(!user) return []
        let userInterests: string[] | undefined = []
        if(Platform.OS === 'web') {
            const db = webFirestore.getFirestore()
            const interestsRef = webFirestore.doc(db, `interests/${user.uid}`)
            const interestsResponse = await webFirestore.getDoc(interestsRef)
            userInterests = interestsResponse.data()?.categories as string[]
        } else {
            const interestsRef = firestore().collection('interests').doc(user.uid)
            const interestsResponse = await interestsRef.get()
            userInterests = interestsResponse.data()?.categories as string[] | undefined
        }
        if(!userInterests) return []
        return userInterests
    } catch {
        return []
    }
}

export const setUserInterests = async (categories: string[]) => {
    const user = getUser()
    if(!user) return

    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const interestsRef = webFirestore.doc(db, `interests/${user?.uid}`)
        await webFirestore.setDoc(interestsRef, {categories})
    } else {
        await firestore().collection('interests').doc(user?.uid).set({categories})
    }

    await AsyncStorage.setItem('hasInterests', 'true')
}

export const getSuggestableCategories = async () => {
    const user = getUser()
    if(!user) return []

    // Get wishlists and shared wishlists
    let wishlists = await getWishlists()
    let sharedWishlists = await getSharedWishlists()

    // Merge wishlists and shared wishlists into one list
    const allWishlists = wishlists.concat(sharedWishlists)

    // Get categories from wishlists
    let categoriesToSuggest: string[] = []
    for(let i=0; i<allWishlists.length; i++) {
        const wishlist = allWishlists[i]
        if(Platform.OS === 'web') {
            const db = webFirestore.getFirestore()
            const wishesRef = webFirestore.collection(db, `wishlist/${wishlist.id}/wish`)
            const wishesResponse = webFirestore.getDocs(wishesRef)
            const wishes = (await wishesResponse).docs.map(doc => doc.data() as IWish)
            const wishlistCategories = wishes
                .map(wish => wish.category ?? '')
                .filter(category => category.length > 0)
                .filter((category, index, self) => self.indexOf(category) === index)
            categoriesToSuggest = categoriesToSuggest.concat(wishlistCategories)
        } else {
            const wishesRef = firestore().collection(`wishlist/${wishlist.id}/wish`)
            const wishesResponse = await wishesRef.get()
            const wishes = wishesResponse.docs.map(doc => doc.data() as IWish)
            const wishlistCategories = wishes
                .map(wish => wish.category ?? '')
                .filter(category => category.length > 0)
                .filter((category, index, self) => self.indexOf(category) === index)
            categoriesToSuggest = categoriesToSuggest.concat(wishlistCategories)
        }
    }
    return categoriesToSuggest
}

export const useAsyncEffect = (asyncEffect: () => Promise<void>, deps: any[]) => {
    return React.useEffect(() => {
        const effect = async () => {
            await asyncEffect()
        }
        effect()
    }, deps)
}

export const useAsyncFocusEffect = (asyncEffect: () => Promise<void>) => {
    return useFocusEffect(() => {
        const effect = async () => {
            await asyncEffect()
        }
        effect()
    })
}

export const setUserProfilePicture = async (imageUrl: string) => {
    if(Platform.OS === 'web') {
        const user = webAuth.getAuth().currentUser
        if(!user) { throw new Error(t('ScreenProfile.error_not_logged_in')) }
        await webAuth.updateProfile(user, {photoURL: imageUrl})
        const db = webFirestore.getFirestore()
        const userDocRef = webFirestore.doc(db, `userInfo/${user.uid}`)
        await webFirestore.setDoc(userDocRef, {image: imageUrl})
    } else {
        const user = await auth().currentUser
        if(!user) { throw new Error(t('ScreenProfile.error_not_logged_in')) }
        await user.updateProfile({photoURL: imageUrl})
        await firestore().collection('userInfo').doc(user.uid).update({image: imageUrl})
    }
}

export const setUserDisplayName = async (name: string) => {
    if(name != getUser()?.displayName) {
        if(Platform.OS === 'web') {
            const user = webAuth.getAuth().currentUser
            if(!user) { throw new Error(t('ScreenProfile.error_not_logged_in')) }
            await webAuth.updateProfile(user, {displayName: name})
            const db = webFirestore.getFirestore()
            const userDocRef = webFirestore.doc(db, `userInfo/${user.uid}`)
            await webFirestore.setDoc(userDocRef, {name})
        } else {
            const user = await auth().currentUser
            if(!user) { throw new Error(t('ScreenProfile.error_not_logged_in')) }
            await user.updateProfile({displayName: name})
            await firestore().collection('userInfo').doc(user.uid).update({name})
        }
    }
}

export const observeReservations = (callback: (reservations: IReservation[]) => void) => {
    const user = getUser()
    if(!user) {
        callback([])
        return
    }

    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const reservationRef = webFirestore.collection(db, 'reservation')
        const reservationQuery = webFirestore.query(reservationRef, webFirestore.where('uid', '==', user.uid))
        const unsubscribeReservations = webFirestore.onSnapshot(reservationQuery, snapshot => {
            if(snapshot.empty) {
                disableReservationNotifications()
                callback([])
                return
            }
            const reservations = snapshot.docs.map(doc => {
                const data = doc.data() as IReservation
                data.id = doc.id
                return data
            })
            callback(reservations)
        })
        return unsubscribeReservations
    } else {
        const unsubscribeReservations = firestore()
            .collection('reservation')
            .where('uid', '==', user.uid)
            .onSnapshot(snapshot => {
            if(snapshot.empty) {
                callback([])
                return
            }
            const reservations = snapshot.docs.map(doc => {
                const data = doc.data() as IReservation
                data.id = doc.id
                return data
            })
            callback(reservations)
        })
        return unsubscribeReservations
    }
}

export const getUserDidReserveWish = async (wish: IWish) => {
    const user = getUser()
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const reservationCollectionRef = webFirestore.collection(db, 'reservation')
        const reservationQuery = webFirestore.query(reservationCollectionRef, 
            webFirestore.where('uid', '==', user?.uid),
            webFirestore.where('wishId', '==', wish.id)
        )
        const currentUserReservations = await webFirestore.getDocs(reservationQuery)
        return !currentUserReservations.empty
    } else {
        const currentUserReservations = await firestore()
            .collection('reservation')
            .where('uid', '==', user?.uid)
            .where('wishId', '==', wish.id)
            .get()
        return !currentUserReservations.empty
    }
}

export const wishReserve = async (wishlist: IWishlist, wish: IWish, product?: IProduct | null) => {
    const reservation: IReservation = {
        uid: getUser()?.uid ?? '',
        bought: false,
        wishId: wish.id ?? '',
        wishlistId: wishlist.id,
        wishlistTitle: wishlist.title,
        amount: 1,
        name: wish.name ?? '',
        comment: wish.comment ?? '',
        imageUrl: wish.urlImage ?? '',
        productId: product?.id ?? '',
        url: wish.url ?? ''
    }

    if(Platform.OS === 'web') {

        // If the user is not logged in, increase wish marks by one
        if(!getUser()?.uid) {
            const db = webFirestore.getFirestore()
            const wishDocRef = webFirestore.doc(db, `wishlist/${wishlist.id}/wish/${wish.id}`)
            const wishDoc = await webFirestore.getDoc(wishDocRef)
            const wishData = wishDoc.data() as IWish
            await webFirestore.updateDoc(wishDocRef, {marks: wishData.marks + 1})
            return
        }

        // Otherwise, add the reservation entry
        const db = webFirestore.getFirestore()
        const reservationCollectionRef = webFirestore.collection(db, 'reservation')
        await webFirestore.addDoc(reservationCollectionRef, reservation)
    } else {
        await firestore().collection('reservation').add(reservation)

        // Enable shopping reminder if user has enabled it
        const enabledReservationNotifications = await AsyncStorage.getItem('enabledReservationNotifications')
        if(enabledReservationNotifications === null) await AsyncStorage.setItem('enabledReservationNotifications', 'true')
        if(enabledReservationNotifications === null || enabledReservationNotifications === 'true') {
            await enableReservationNotifications()
        }
    }
}

export const wishUnreserve = async (wishlist: IWishlist, wish: IWish) => {
    if(Platform.OS === 'web') {

        // If the user is not logged in, decrease wish marks by one
        if(!getUser()?.uid) {
            const db = webFirestore.getFirestore()
            const wishDocRef = webFirestore.doc(db, `wishlist/${wishlist.id}/wish/${wish.id}`)
            const wishDoc = await webFirestore.getDoc(wishDocRef)
            const wishData = wishDoc.data() as IWish
            await webFirestore.updateDoc(wishDocRef, {marks: Math.max(0, wishData.marks - 1)})
            return
        }

        // Otherwise, delete the reservation entry
        const db = webFirestore.getFirestore()
        const reservationCollectionRef = webFirestore.collection(db, 'reservation')
        const reservationQuery = webFirestore.query(reservationCollectionRef, 
            webFirestore.where('uid', '==', getUser()?.uid),
            webFirestore.where('wishId', '==', wish.id)
        )
        const reservations = await webFirestore.getDocs(reservationQuery)
        if(reservations.empty) return
        const reservation = reservations.docs[0]
        await webFirestore.deleteDoc(reservation.ref)
    } else {
        const reservation = await firestore()
            .collection('reservation')
            .where('uid', '==', getUser()?.uid)
            .where('wishId', '==', wish.id)
            .get()
        if(reservation.empty) return
        await reservation.docs[0].ref.delete()

        // Check if we should disable shopping reminder notifications
        const reservations = await firestore()
            .collection('reservation')
            .where('uid', '==', getUser()?.uid)
            .get()
        if(reservations.empty) {
            await disableReservationNotifications()
        }
    }

}

export const wishUpdate = async (wishlistId: string, wish: IWish, name: string, comment: string, price: string, url: string, urlImage: string) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishRef = webFirestore.doc(db, `wishlist/${wishlistId}/wish/${wish.id}`)
        await webFirestore.updateDoc(wishRef, {
            name, comment, price, url, urlImage
        })
    } else {
        await firestore().collection('wishlist').doc(wishlistId).collection('wish').doc(wish.id).update({
            name, comment, price, url, urlImage
        })
    }
}

export const wishSetMaxMarks = async (wishlistId: string, wish: IWish, amount: number | null) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishRef = webFirestore.doc(db, `wishlist/${wishlistId}/wish/${wish.id}`)
        await webFirestore.updateDoc(wishRef, {
            marksMax: amount ?? 0,
            marksIsUnlimited: amount === null,
        })
    } else {
        await firestore().collection('wishlist').doc(wishlistId).collection('wish').doc(wish.id).update({
            marksMax: amount ?? 0,
            marksIsUnlimited: amount === null,
        })
    }
}

export const wishlistGetEditors = async (wishlistId: string) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistRef = webFirestore.doc(db, `wishlist/${wishlistId}`)
        const wishlist = await webFirestore.getDoc(wishlistRef)
        const editorUids: string[] = wishlist.data()?.editors
        if(!editorUids) return
        const editorRefs = editorUids.map(editor => webFirestore.doc(db, `userInfo/${editor}`))
        const editors = await Promise.all(editorRefs.map(ref => webFirestore.getDoc(ref)))
        const editorInfos = editors.map(editor => editor.data() as IUserInfo)
        return editorInfos
    } else {
        const wishlist = await firestore().collection('wishlist').doc(wishlistId).get()
        const editorUids: string[] = wishlist.data()?.editors
        if(!editorUids) return
        const editorRefs = editorUids.map(editor => firestore().doc(`userInfo/${editor}`))
        const editors = await Promise.all(editorRefs.map(ref => ref.get()))
        const editorInfos = editors.map(editor => editor.data() as IUserInfo)
        return editorInfos
    }
}

export const observeWishlist = (wishlistId: string, callback: (wishlist: IWishlist) => void) => {
    
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistRef = webFirestore.doc(db, `wishlist/${wishlistId}`)
        return webFirestore.onSnapshot(wishlistRef, (doc) => {
            const wishlist = doc.data()
            if(wishlist) {
                wishlist.id = doc.id
                callback(wishlist as IWishlist)
            }
        })
    } else {
        return firestore().collection('wishlist').doc(wishlistId).onSnapshot(doc => {
            const wishlist = doc.data()
            if(wishlist) {
                wishlist.id = doc.id
                callback(wishlist as IWishlist)
            }
        })
    }
}

export const observeWishes = (wishlistId: string, callback: (wishes: IWish[]) => void) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishesRef = webFirestore.collection(db, `wishlist/${wishlistId}/wish`)
        return webFirestore.onSnapshot(wishesRef, (snapshot) => {
            if(!snapshot)  {
                callback([])
            } else {
                const wishes = snapshot.docs.map(doc => {
                    const wish = doc.data()
                    wish.id = doc.id
                    return wish as IWish
                })
                wishes.sort((a, b) => (b.position ?? 0) - (a.position ?? 0))
                callback(wishes)
            }
        })
    } else {
        return firestore().collection('wishlist').doc(wishlistId).collection('wish').onSnapshot(snapshot => {
            if(!snapshot) callback([])
            else {
                const wishes = snapshot.docs.map(doc => {
                    const wish = doc.data()
                    wish.id = doc.id
                    return wish as IWish
                })
                wishes.sort((a, b) => (b.position ?? 0) - (a.position ?? 0))
                callback(wishes)
            }
        })
    }
}

export const observeWishlistReservations = (wishlistId: string, callback: (reservations: IReservation[]) => void) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const reservationsRef = webFirestore.collection(db, 'reservation')
        const reservationsQuery = webFirestore.query(reservationsRef, 
            webFirestore.where('wishlistId', '==', wishlistId)
        )
        return webFirestore.onSnapshot(reservationsQuery, (snapshot) => {
            if(!snapshot) return
            const reservations = snapshot.docs.map(doc => {
                const reservation = doc.data()
                reservation.id = doc.id
                return reservation as IReservation
            })
            callback(reservations)
        })
    } else {
        return firestore()
        .collection('reservation')
        .where('wishlistId', '==', wishlistId)
        .onSnapshot(snapshot => {
            if(!snapshot) return
            const reservations = snapshot.docs.map(doc => {
                const reservation = doc.data()
                reservation.id = doc.id
                return reservation as IReservation
            })
            callback(reservations)
        })
    }
}

// Check if a wish is reserved
export const wishIsReserved = (wish: IWish, reservations: IReservation[]) => {

    let marksMax = wish.marksMax
    if(marksMax === null || marksMax === undefined) marksMax = 1
    if(marksMax === 0) return false

    // Check how many users reserved
    const reservationsThisWish = reservations.filter(reservation => reservation.wishId === wish.id)
    
    // Check how many anonymous guests reserved
    const marks = wish.marks

    // Put it together
    const totalMarks = reservationsThisWish.length + marks
    
    // Check if it is above max marks
    return totalMarks >= marksMax
}

export const wishlistShare = async(wishlist: IWishlist) => {
    // Generate URL
    const link = `https://xn--nskeliste-k8a.nu/liste/${wishlist.id}?n=${slugify(wishlist.title ?? '')}`

    // Share the wishlist
    try {
        if(Platform.OS === 'web') {
            await Clipboard.setStringAsync(link)
            showMessage(t('ScreenWishlist.dialog_share_web_title'), t('ScreenWishlist.dialog_share_web_text'))
        } else {
            Share.share({message: link})
            facebookTrackShareWishlist(wishlist.id ?? '', wishlist.title ?? '')
        }
    } catch(e: any) {
        console.error(e)
        showMessage(t('ScreenWishlist.error_share_title'), t('ScreenWishlist.error_share_text', {message: e.message}))
    }   
}

export const wishlistSetNotifications = async (wishlistId: string, notifications: boolean) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistRef = webFirestore.doc(db, `wishlist/${wishlistId}`)
        await webFirestore.updateDoc(wishlistRef, {notifications})
    } else {
        await firestore().collection('wishlist').doc(wishlistId).update({notifications})
        
        // Prepare the topic
        const topic = `wishlist_${wishlistId}`
        
        // Update all affected wishes to match new notification settings (product wishes)
        const productWishes = await firestore().collection(`wishlist/${wishlistId}/wish`).get()
        productWishes.docs.forEach(doc => {
            if(doc.data().productId) {
                doc.ref.update({notifications})
            }
        })

        // Update the user's notification settings
        if(notifications) {
            await messaging().subscribeToTopic(topic)
            console.log('Subscribed to topic', topic)
        } else {
            await messaging().unsubscribeFromTopic(topic)
            console.log('Unsubscribed from topic', topic)
        }
    }
}

export const wishlistGet = (id: string) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishlistRef = webFirestore.doc(db, `wishlist/${id}`)
        return webFirestore.getDoc(wishlistRef).then(doc => {
            if(doc.exists()) {
                const wishlist = doc.data()
                wishlist.id = doc.id
                return wishlist as IWishlist
            } else {
                return null
            }
        })
    } else {
        return firestore().collection('wishlist').doc(id).get().then(doc => {
            if(doc.exists) {
                const wishlist = doc.data() as IWishlist
                wishlist.id = doc.id
                return wishlist
            } else {
                return null
            }
        })
    }
}

export const wishlistGetWishes = (id: string) => {
    if(Platform.OS === 'web') {
        const db = webFirestore.getFirestore()
        const wishesRef = webFirestore.collection(db, `wishlist/${id}/wish`)
        return webFirestore.getDocs(wishesRef).then(snapshot => {
            const wishes = snapshot.docs.map(doc => {
                const wish = doc.data()
                wish.id = doc.id
                return wish as IWish
            })
            wishes.sort((a, b) => (b.position ?? 0) - (a.position ?? 0))
            return wishes
        })
    } else {
        return firestore().collection('wishlist').doc(id).collection('wish').get().then(snapshot => {
            const wishes = snapshot.docs.map(doc => {
                const wish = doc.data()
                wish.id = doc.id
                return wish as IWish
            })
            wishes.sort((a, b) => (b.position ?? 0) - (a.position ?? 0))
            return wishes
        })
    }
}

export const askForWishlistNotificationsOnce = async (wishlistId: string) => {
    if(Platform.OS === 'web') return
    
    const key = `askedForWishlistNotifications_${wishlistId}`
    const asked = await AsyncStorage.getItem(key)
    if(asked === null) {
        await AsyncStorage.setItem(key, 'true')

        // Ask for notifications
        Alert.alert(
            'Spar penge på julegaverne',
            'Du har tilføjet et produkt fra vores database til din ønskeliste. Vil du gerne modtage notifikationer, når produkternes priser falder på denne ønskeliste? \n\nDu kan til hver en tid slå notifikationer til eller fra, ved at trykke på klokken i øverste højre hjørne.',
            [
                {
                    text: 'Ja',
                    onPress: async () => {
                        const notificationsAreEnabled = await askForNotificationPermission()
                        if(notificationsAreEnabled) wishlistSetNotifications(wishlistId, true)
                    }
                },
                {
                    text: 'Nej',
                    onPress: () => wishlistSetNotifications(wishlistId, false),
                    style: 'cancel'
                }
            ]
        )

        return true
    } else {
        return false
    }
}