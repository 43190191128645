import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function IconCategoryElectronics(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 28 22"
      fill="none"
      {...props}
    >
      <Path
        d="M0 0v18h13v2H8v2h12v-2h-5v-2h13V0H0zm2 2h24v14H2V2z"
        fill="#4D335E"
      />
    </Svg>
  );
}

export default IconCategoryElectronics;