import * as React from "react";
import Svg, {
  SvgProps,
  Path,
  Defs,
  LinearGradient,
  Stop,
} from "react-native-svg";

function TopGradient(props: SvgProps) {
  return (
    <Svg
      width="1em"
      height="1em"
      fill="none"
      {...props}
    >
      <Path fill="url(#prefix__paint0_linear_123_511)" d="M0 0h5000v292H0z" />
      <Defs>
        <LinearGradient
          id="prefix__paint0_linear_123_511"
          x1={1000}
          y1={0}
          x2={1000}
          y2={292}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FFF7FF" />
          <Stop offset={1} stopColor="#FFF7FF" stopOpacity={0} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default TopGradient;