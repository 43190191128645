import ScreenHome from './pages/ScreenHome'
import ScreenWishlist from './pages/ScreenWishlist'
import ScreenWishlistCreate from './pages/ScreenWishlistCreate'
import ScreenWishCreate from './pages/ScreenWishCreate'
import ScreenProduct from './pages/ScreenProduct'
import ScreenWishlistEdit from './pages/ScreenWishlistEdit'
import ScreenLogin from './pages/ScreenLogin'
import ScreenCreateAccount from './pages/ScreenCreateAccount'
import ScreenProfile from './pages/ScreenProfile'
import ScreenForgotPassword from './pages/ScreenForgotPassword'
import ScreenChangePassword from './pages/ScreenChangePassword'
import ScreenChangeEmail from './pages/ScreenChangeEmail'
import ScreenDeleteAccount from './pages/ScreenProfile'
import ScreenWishlistAddEditor from './pages/ScreenWishlistAddEditor'
import ScreenWishCreateManually from './pages/ScreenWishCreateManually'
import ScreenWishEdit from './pages/ScreenWishEdit'
import ScreenWish from './pages/ScreenWish'
import ScreenReservations from './pages/ScreenReservations'
import Color from './style/Color'
import IconGift from './components/icons/IconGift'
import IconGiftDisabled from './components/icons/IconGiftDisabled'
import IconViewList from './components/icons/IconReservations'
import IconViewListDisabled from './components/icons/IconReservationsDisabled'
import IconCompass from './components/icons/IconCompass'
import IconCompassDisabled from './components/icons/IconCompassDisabled'
import ScreenDiscovery from './pages/ScreenDiscovery'
import ScreenAddWishSelectWishlist from './pages/ScreenAddWishSelectWishlist'
import ScreenLandingPageWeb from './pages/ScreenLandingPageWeb'
import ScreenLandingPageNative from './pages/ScreenLandingPageNative'
import analytics from '@react-native-firebase/analytics'
import ScreenDiscoveryInterests from './pages/ScreenDiscoveryInterests'
import firestore from '@react-native-firebase/firestore'
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { getPathFromState } from "@react-navigation/native"
import { useEffect, useRef, useState } from 'react'
import { Platform, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { MaterialTopTabNavigationOptions, createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { Text, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { facebookTrackNavigation, getUser } from './Api'
import * as webAuth from 'firebase/auth'
import * as webAnalytics from 'firebase/analytics'
import * as webFirestore from 'firebase/firestore'
import * as Notifications from 'expo-notifications'
import auth, { FirebaseAuthTypes } from '@react-native-firebase/auth'
import ScreenWishlistOffers from './pages/ScreenWishlistOffers'

const config = {
    screens: {
        Ønskelister: {
            screens: {
                Landing: '',
                Home: '/ønskelister',
                CreateWishlist: '/opret-ønskeliste',
                Profile: '/profil',
                Login: '/log-ind',
                ForgotPassword: '/glemt-kodeord',
                CreateAccount: '/opret-konto',
                CreateWish: '/:wishlistId/opret-ønske',
                Product: '/produkt/:productId',
                EditWishlist: '/:wishlistId/rediger-ønskeliste',
                ChangePassword: '/profil/skift-kodeord',
                ChangeEmail: '/profil/skift-email',
                DeleteProfile: '/profil/slet-konto',
                WishlistAddEditor: '/:wishlistId/tilføj-editor',
                WishCreateManually: '/:wishlistId/opret-ønske-manuelt',
                WishEdit: '/:wishlistId/ønske/:wishId/rediger-ønske',
                Wish: '/:wishlistId/ønske/:wishId',
                Wishlist: 'liste/:wishlistId'
            }   
        },
        Reservationer: '/reservationer',
        Udforsk: '/udforsk',
    }
}

const linking = {
    prefixes: ['onskeliste://', 'https://ønskeliste.nu'],
    config,
    getPathFromState(state: any, config: any){
        let path = getPathFromState(state, config);
        const index = path.indexOf("?")
        if(index>=0){
           path = path.substr(0, index);
        }
        return path;
    }
}

const HomeStack = createNativeStackNavigator()
const ScreenLandingPage = Platform.OS === 'web' ? ScreenLandingPageWeb : ScreenLandingPageNative
const HomeStackNavigator = () => (
    <HomeStack.Navigator screenOptions={{headerShown: false}}>
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Hjem'}} name="Home" component={ScreenHome} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Ønskelister til alle begivenheder'}} name="Landing" component={ScreenLandingPage} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Opret ønskeliste'}} name="CreateWishlist" component={ScreenWishlistCreate} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Ønskeliste'}} name="Wishlist" component={ScreenWishlist} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Opret ønske'}} name="CreateWish" component={ScreenWishCreate} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Se tilbud'}} name="WishlistOffers" component={ScreenWishlistOffers} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Produkt'}} name="Product" component={ScreenProduct} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Rediger ønskeliste'}} name="EditWishlist" component={ScreenWishlistEdit} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Login'}} name="Login" component={ScreenLogin} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Ny bruger'}} name="CreateAccount" component={ScreenCreateAccount} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Profil'}} name="Profile" component={ScreenProfile} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Glemt password'}} name="ForgotPassword" component={ScreenForgotPassword} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Skift password'}} name="ChangePassword" component={ScreenChangePassword} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Skift email'}} name="ChangeEmail" component={ScreenChangeEmail} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Slet profil'}} name="DeleteProfile" component={ScreenDeleteAccount} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Tilføj editor'}} name="WishlistAddEditor" component={ScreenWishlistAddEditor} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Opret manuelt'}} name="CreateWishManually" component={ScreenWishCreateManually} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Rediger ønske'}} name="EditWish" component={ScreenWishEdit} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Ønske'}} name="Wish" component={ScreenWish} />
        <HomeStack.Screen options={{title: 'Ønskeliste.nu | Vælg ønskeliste'}} name="AddWishSelectWishlist" component={ScreenAddWishSelectWishlist} />

    </HomeStack.Navigator>
)

const DiscoveryStack = createNativeStackNavigator()
const DiscoveryStackNavigator = () => (
    <DiscoveryStack.Navigator screenOptions={{headerShown: false}}>
        <DiscoveryStack.Screen name="Discovery" component={ScreenDiscovery} />
        <DiscoveryStack.Screen name="DiscoveryInterests" component={ScreenDiscoveryInterests} />
        <DiscoveryStack.Screen name="DiscoveryProduct" component={ScreenProduct} />
        <DiscoveryStack.Screen name="AddWishSelectWishlist" component={ScreenAddWishSelectWishlist} />
    </DiscoveryStack.Navigator>
)

const ReservationStack = createNativeStackNavigator()
const ReservationStackNavigator = () => {
    return (
        <ReservationStack.Navigator screenOptions={{headerShown: false}}>
            <ReservationStack.Screen name="Reservations" component={ScreenReservations} />
            <ReservationStack.Screen name="ReservationsProduct" component={ScreenProduct} />
        </ReservationStack.Navigator>
    )
}

const Tab = createMaterialTopTabNavigator()
const Navigation = () => {
    
    const { t } = useTranslation()
    const [user, setUser] = useState<webAuth.User | FirebaseAuthTypes.User | null>(null)
    const navigationRef = useNavigationContainerRef()
    const routeNameRef = useRef<string>()
    const [badgeCount, setBadgeCount] = useState<number>()
    const safeArea = useSafeAreaInsets()

    // Get amount of reservations
    useEffect(() => {
        const user = getUser()
        if(!user) return

        if(Platform.OS === 'web') {
            const db = webFirestore.getFirestore()
            const reservationRef = webFirestore.collection(db, 'reservation')
            const reservationQuery = webFirestore.query(reservationRef, webFirestore.where('uid', '==', user.uid), webFirestore.where('bought', '==', 'false'))
            const unsubscribeReservations = webFirestore.onSnapshot(reservationQuery, snapshot => {
                if(snapshot.empty) {
                    setBadgeCount(undefined)
                    return
                }
                setBadgeCount(snapshot.size)
            })

            return unsubscribeReservations
        } else {
            const unsubscribeReservations = firestore()
                .collection('reservation')
                .where('uid', '==', user.uid)
                .where('bought', '==', false)
                .onSnapshot(snapshot => {
                if(snapshot.empty) {
                    setBadgeCount(undefined)
                    return
                }
                setBadgeCount(snapshot.size)
                Notifications.setBadgeCountAsync(snapshot.size)
            })
            return unsubscribeReservations
        }
    }, [])

    useEffect(() => {
        if(Platform.OS === 'web') {
            const unsubscribeUser = webAuth.getAuth().onAuthStateChanged(user => {
                setUser(user)
            })
            return unsubscribeUser
        } else {
            const unsubscribeUser = auth().onAuthStateChanged(user => {
                setUser(user)
                setTabAnimationEnabled(false)
                // White bar above tab bar fix for iOS
                if(Platform.OS === 'ios') setTimeout(() => { setTabAnimationEnabled(true) }, 3000)
            })
            return unsubscribeUser
        }
    }, [])

    const tabBarOptions: MaterialTopTabNavigationOptions = {
        tabBarActiveTintColor: Color.Primary,
        tabBarIndicatorStyle: {
            backgroundColor: Color.Primary
        },
        swipeEnabled: user === null ? false : true,
        tabBarStyle: {
            display: user === null ? 'none' : 'flex',
            borderTopColor: Color.DarkTransparentMore,
            padding: 0,
            borderTopWidth: 1,
            paddingBottom: safeArea.bottom,
            minHeight: 0,
            height: 65 + safeArea.bottom
        },
        tabBarLabelStyle: {
            fontSize: 10,
        }
    }

    const onReady = () => {
        routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name
    }

    const onStateChange = async () => {
        const routeName = navigationRef.current?.getCurrentRoute()?.name
        if(routeName !== routeNameRef.current) {
            const screenInfo = {screen_name: routeName, firebase_screen: routeName, firebase_screen_class: routeName}
            try {
                if(Platform.OS === 'web') {
                    const analytics = webAnalytics.getAnalytics()
                    webAnalytics.logEvent(analytics, "screen_view", screenInfo)
                } else {
                    analytics().logEvent("screen_view", screenInfo)
                    if(!!routeName) facebookTrackNavigation(routeName)
                }
            } catch(e) {
                console.error(e)
            }
        }
        routeNameRef.current = routeName
    }

    const homeOptions: MaterialTopTabNavigationOptions = {
        tabBarIcon: ({focused}) => {return focused ? <IconGift width={22} height={22} /> : <IconGiftDisabled width={22} height={22} />},
        title: t('TabBar.wishlists')
    }
    
    const badgeStyle: ViewStyle = {
        width: 18, 
        height: 18, 
        backgroundColor: Color.Primary,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        position: 'relative',
        right: '200%',
        top: 5,
        borderRadius: 9
    }
    const reservationOptions: MaterialTopTabNavigationOptions = {
        tabBarIcon: ({focused}) => {return focused ? <IconViewList width={22} height={22} /> : <IconViewListDisabled width={22} height={22} />},
        tabBarBadge: () => badgeCount && badgeCount > 0 ? (
            <View style={badgeStyle}>
                <Text style={{color: Color.White, fontSize: 10}}>{badgeCount}</Text>
            </View>
        ) : <View />,
        title: t('TabBar.reservations')
    }

    const discoveryOptions: MaterialTopTabNavigationOptions = {
        tabBarIcon: ({focused}) => {return focused ? <IconCompass width={22} height={22} /> : <IconCompassDisabled width={22} height={22} />},
        title: t('TabBar.discovery')
    }

    const [tabAnimationEnabled, setTabAnimationEnabled] = useState<boolean>(false)

    return (
        <NavigationContainer ref={navigationRef} linking={linking} {... {onReady, onStateChange}} >
            <Tab.Navigator tabBarPosition='bottom' screenOptions={tabBarOptions}>
                <Tab.Screen name="Ønskelister" component={HomeStackNavigator} options={homeOptions} />
                <Tab.Screen name="Udforsk" component={DiscoveryStackNavigator} options={discoveryOptions} />
                <Tab.Screen name="Reservationer" component={ReservationStackNavigator} options={reservationOptions} />
            </Tab.Navigator>
        </NavigationContainer>
    )
}

export default Navigation