import {Text, View, SafeAreaView, StyleSheet, TouchableOpacity, ScrollView, Alert, Platform, PlatformColor} from "react-native"
import Button from "../components/Button"
import Divider from "../components/Divider"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import TextInput from "../components/TextInput"
import Color from "../style/Color"
import { useNavigation } from "@react-navigation/native"
import { useState } from "react"
import auth from "@react-native-firebase/auth"
import LoadingOverlay from "../components/LoadingOverlay"
import * as firebaseWeb from 'firebase/auth'
import * as api from "../Api"
import { useTranslation } from "react-i18next"
import { getUser, getUserFriendlyErrorMessage } from "../Api"

const style = StyleSheet.create({
    forgotPasswordText: {alignSelf: 'center'},
    buttonFacebook: {backgroundColor: Color.Facebook},
    buttonGoogle: {backgroundColor: Color.Google},
    buttonApple: {backgroundColor: Color.Apple},
})

const ScreenLogin = () => {

    const navigation = useNavigation<any>()
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loadingText, setLoadingText] = useState<string>()
    const canLogIn = email.length > 0 && password.length > 0

    const onPressCreateAccount = () => {
        navigation.navigate('CreateAccount', {email, password})
    }

    const onPressLogin = async () => {
        if(!canLogIn) return

        try {
            setLoadingText(t('ScreenLogin.logging_in'))
            if(Platform.OS === 'web') {
                const auth = firebaseWeb.getAuth()
                await firebaseWeb.signInWithEmailAndPassword(auth, email, password)
            } else {
                await auth().signInWithEmailAndPassword(email, password)
            }
            navigation.navigate('Home')
        } catch(e: any) {
            setLoadingText(undefined)
            const userFriendlyMessage = getUserFriendlyErrorMessage(e)
            api.showMessage(t('ScreenLogin.error_title_login'), userFriendlyMessage)
        }
    }

    const onPressForgotPassword = () => {
        navigation.navigate('ForgotPassword')
    }

    const onPressLoginToGoogle = async () => {
        try {
            setLoadingText(t('ScreenLogin.loading_google'))
            await api.signInWithGoogle()
            if(getUser() !== null) {
                await api.migrateFromCordovaIfNeeded()
                navigation.navigate('Home')
            }
        } catch(e) {
            setLoadingText(undefined)
            const userFriendlyMessage = getUserFriendlyErrorMessage(e)
            api.showMessage(t('ScreenLogin.error_title_login'), userFriendlyMessage)
        }
    }

    const onPressLoginToApple = async () => {
        try {
            setLoadingText(t('ScreenLogin.loading_apple'))
            await api.signInWithApple()
            if(getUser() !== null) {
                await api.migrateFromCordovaIfNeeded()
                navigation.navigate('Home')
            }
        } catch(e) {
            setLoadingText(undefined)
            const userFriendlyMessage = getUserFriendlyErrorMessage(e)
            api.showMessage(t('ScreenLogin.error_title_login'), userFriendlyMessage)
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                    <Divider.Medium />
                    <Button.Back text={t('ScreenLogin.home')} />
                    <Divider.Medium />
                    <ScrollView keyboardShouldPersistTaps='always' style={PageStyle.safeArea}>

                        { Platform.OS === 'web' && <Divider.Gigantic />}

                        { loadingText && <LoadingOverlay text={loadingText} /> }

                        {/* Screen title and subtitle */}
                        <Text style={Typography.Title}>{t('ScreenLogin.title')}</Text>
                        <Text style={Typography.Subtitle}>{t('ScreenLogin.subtitle')}</Text>
                        <Divider.Medium />

                        {/* User input */}
                        <TextInput 
                            title={t('ScreenLogin.email')} 
                            onChangeText={setEmail} 
                            autoCapitalize="none" 
                            keyboardType="email-address" />
                        <Divider.Small />
                        
                        <TextInput 
                            title={t('ScreenLogin.password')} 
                            onChangeText={setPassword} 
                            secureTextEntry={true} />
                        <Divider.Small />
                        
                        { Platform.OS !== 'web' && <View>
                            <Button.Primary onPress={onPressLogin} text={t('ScreenLogin.button_login')} disabled={!canLogIn} />
                            <Divider.Tiny />
                            <TouchableOpacity onPress={onPressForgotPassword}>
                                <Text style={[Typography.BackButton, style.forgotPasswordText]}>{t('ScreenLogin.button_forgot_password')}</Text>
                            </TouchableOpacity>
                            <Divider.Medium />

                            <Button.Primary text={t('ScreenLogin.button_google')} onPress={onPressLoginToGoogle} style={style.buttonGoogle} textStyle={{color: Color.Dark}} googleIcon={true} />
                            <Divider.Small />

                            {Platform.OS === 'ios' && (
                                <Button.Primary 
                                    text={t('ScreenLogin.button_apple')} 
                                    onPress={onPressLoginToApple} 
                                    style={[style.buttonApple]} 
                                    textStyle={{color: Color.White}} 
                                    appleIcon={true} />
                            )}
                            <Divider.Small />

                            <Button.Primary text={t('ScreenLogin.button_new_user')} onPress={onPressCreateAccount} />    
                            <Divider.Small />

                            
                        </View> }

                        { Platform.OS === 'web' && <View>
                            <TouchableOpacity onPress={onPressForgotPassword}>
                                <Text style={[Typography.BackButton, style.forgotPasswordText, {alignSelf: 'flex-start'}]}>{t('ScreenLogin.button_forgot_password')}</Text>
                            </TouchableOpacity>
                            <Divider.Small />

                            <View style={PageStyle.row}>
                                <Button.Primary 
                                    onPress={onPressLogin} 
                                    style={{marginRight: 8}} 
                                    text={t('ScreenLogin.button_login')} 
                                    disabled={!canLogIn} />
                                <Button.Primary 
                                    text={t('ScreenLogin.button_new_user')} 
                                    onPress={onPressCreateAccount} />
                            </View>
                            <Divider.Tiny />
                            <Button.Primary 
                                text={t('ScreenLogin.button_google')} 
                                onPress={onPressLoginToGoogle} 
                                style={[style.buttonGoogle, {alignSelf: 'flex-start'}]} 
                                textStyle={{color: Color.Dark}} 
                                googleIcon={true} />
                        </View> }
                        

                    </ScrollView>                
            </SafeAreaView>
        </View>
    )
}

export default ScreenLogin