import Button from "../components/Button"
import CardWish from "../components/CardWish"
import Divider from "../components/Divider"
import IconMore from "../components/icons/IconMore"
import IconShare from "../components/icons/IconShare"
import LoadingOverlay from "../components/LoadingOverlay"
import TopGradient from "../components/TopGradient"
import IWishlist from "../models/IWishlist"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import firestore from "@react-native-firebase/firestore"
import { askForNotificationPermission, observeWishes, observeWishlist, observeWishlistReservations, useAsyncEffect, wishIsReserved, wishlistGetEditors, wishlistSetNotifications, wishlistShare, wishlistToHtml, wishlistToText } from "../Api"
import IWish from "../models/IWish"
import IReservation from "../models/IReservation"
import IUserInfo from "../models/IUserInfo"
import React, { useEffect, useState } from "react"
import { useActionSheet } from "@expo/react-native-action-sheet"
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native"
import { shareAsync } from "expo-sharing"
import { facebookTrackShareWishlist, getUser, setWishlistLink } from "../Api"
import { Share, View, ScrollView, Alert, Text, StyleSheet, ImageBackground, TouchableOpacity, FlatList, Platform, Image, ViewStyle, SafeAreaView } from "react-native"
import * as Print from 'expo-print'
import * as webFirestore from 'firebase/firestore'
import * as Clipboard from 'expo-clipboard'
import * as SecureStorage from 'expo-secure-store'
import AsyncStorage from '@react-native-async-storage/async-storage'

import ReviewGate from "../components/ReviewGate"
import IconViewCards from "../components/icons/IconViewCards"
import IconViewList from "../components/icons/IconViewList"
import IconEnvelope from "../components/icons/IconEnvelopeWhite"
import IconBell from "../components/icons/IconBell"
import IconBellOff from "../components/icons/IconBellOff"
import Animated, { FadeIn, FadeInDown } from "react-native-reanimated"
import ViewMode from "../models/ViewMode"
import { showMessage } from '../Api'
import slugify from "slugify"
import Color from "../style/Color"
import { useTranslation } from "react-i18next"
import * as Device from 'expo-device'
import * as Linking from 'expo-linking'

const style = StyleSheet.create({
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
    tinyGradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1
    },
    subtitle: {
        marginTop: -10
    },
    titlebarIcons: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'flex-end',
        marginRight: 24,
        alignSelf: 'center'
    },
    profilePictureShadow: {
        width: 48, 
        height: 48, 
        borderRadius: 24, 
        marginRight: 8,
        ... PageStyle.Shadow
    },
    profilePicture: {
        width: 48, 
        height: 48, 
        borderRadius: 24
    },
    shareButton: {
        width: 92,
        height: 92,
        borderRadius: 46,
        backgroundColor: Color.Primary,
        position: 'absolute',
        bottom: 24,
        right: 24,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        elevation: 10,
        borderColor: Color.DarkTransparentMore,
        borderWidth: 1,
    },
})

const ScreenWishlist = () => {

    const { t } = useTranslation()
    const {showActionSheetWithOptions} = useActionSheet()

    const navigation = useNavigation<any>()
    const route = useRoute<any>()
    
    const [wishlist, setWishlist] = useState<IWishlist>()
    const [wishes, setWishes] = useState<IWish[]>([])
    const [reservations, setReservations] = useState<IReservation[]>([])
    const [loadingText, setLoadingText] = useState<string | undefined>(t('ScreenWishlist.loading_wishlist'))
    const [editors, setEditors] = useState<IUserInfo[]>([])
    const [showReviewGate, setShowReviewGate] = useState<boolean>(false)
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Cards)
    const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(true)
    
    const user = getUser()
    const editMode = user !== null && wishlist?.editors.includes(user.uid)
    const wishlistId = route.params.wishlistId

    // Show review gate on `showAtCount`th visit
    const checkReviewGate = async (showAtCount: number) => {
        if(Platform.OS !== 'web') {
            const secureStoreAvailable = await SecureStorage.isAvailableAsync()
            if(!secureStoreAvailable) return
            const usageCount = await SecureStorage.getItemAsync('reviewGateUsageCount')
            if(!usageCount) {
                await SecureStorage.setItemAsync('reviewGateUsageCount', '1')
            } else {
                const usageCountInt = parseInt(usageCount)
                if(usageCountInt === showAtCount) {
                    setShowReviewGate(true)
                }
                await SecureStorage.setItemAsync('reviewGateUsageCount', (usageCountInt + 1).toString())
            }
        }
    }

    // Toggle view mode (cards or list)
    const toggleViewMode = () => {
        const newViewMode = viewMode === ViewMode.Cards ? ViewMode.List : ViewMode.Cards
        setViewMode(newViewMode)
        AsyncStorage.setItem('viewMode', newViewMode.toString())
    }

    // Get view mode from storage
    useAsyncEffect(async () => {
        const value = await AsyncStorage.getItem('viewMode')
        if(value === null) {
            await AsyncStorage.setItem('viewMode', ViewMode.Cards.toString())
            setViewMode(ViewMode.Cards)
        } else {
            setViewMode(parseInt(value))
        }
    }, [])

    // Show review gate on 100th wishlist view (anniversary woo-hoo)
    useFocusEffect(() => {
        checkReviewGate(100)
    })

    // Get wishlist editors 
    useAsyncEffect(async () => {
        const editors = await wishlistGetEditors(wishlistId)
        if(editors) setEditors(editors)
    }, [])

    // Observe wishlist, reservations and wishes
    useEffect(() => {
        setLoadingText(t('ScreenWishlist.loading_wishlist'))

        const unsubscribeWishlist = observeWishlist(wishlistId, setWishlist)
        const unsubscribeReservations = observeWishlistReservations(wishlistId, setReservations)
        const unsubscribeWishes = observeWishes(wishlistId, (wishes) => {
            setWishes(wishes)
            setLoadingText(undefined)
        })


        return () => {
            unsubscribeWishlist()
            unsubscribeReservations()
            unsubscribeWishes()
        }
    }, [])

    // Navigate to create wish screen
    const onPressCreateWish = () => {
        const wishlistId = wishlist?.id
        navigation.navigate('CreateWish', {wishlist, wishlistId})
    }

    // Share the wishlist
    const onPressShare = async () => {
        if(!wishlist) return
        await wishlistShare(wishlist)
    }

    // Leave the wishlist as an editor
    const onPressEditorLeave = async () => {
        const user = getUser()
        if(!wishlist || !user || !editMode) return

        try {
            setLoadingText(t('ScreenWishlist.loading_leave'))
            if(Platform.OS === 'web') {
                const db = webFirestore.getFirestore()
                const wishlistRef = webFirestore.doc(db, `wishlist/${wishlist.id}`)
                await webFirestore.updateDoc(wishlistRef, {
                    editors: webFirestore.arrayRemove(user.uid)
                })
            } else {
                await firestore().collection('wishlist').doc(wishlist.id).update({
                    editors: firestore.FieldValue.arrayRemove(user.uid)
                })
            }
            navigation.navigate('Home')
        } catch(e) {
            showMessage(t('ScreenWishlist.error_leave'), `${e}`)
            console.error(e)
            return
        } finally {
            setLoadingText(undefined)
        }
    }

    // Leave the wishlist as a guest
    const onPressLeave = async () => {
        const user = getUser()
        const wishlistId = wishlist?.id
        if(!user || !wishlistId) return

        try {
            setLoadingText(t('ScreenWishlist.loading_leave'))

            if(Platform.OS === 'web') {
                const db = webFirestore.getFirestore()
                const shareCollectionRef = webFirestore.collection(db, 'share')
                const shareQuery = webFirestore.query(shareCollectionRef,
                    webFirestore.where('wishlistId', '==', wishlistId),
                    webFirestore.where('userId', '==', user.uid)
                )
                const share = await webFirestore.getDocs(shareQuery)
                if(share.empty) return
                const shareId = share.docs[0].id

                // Remove share from firestore
                const shareRef = webFirestore.doc(db, `share/${shareId}`)
                await webFirestore.deleteDoc(shareRef)
            } else {
                // Get share from firestore
                const share = await firestore().collection('share').where('wishlistId', '==', wishlistId).where('uid', '==', user.uid).get()
                if(share.empty) return
                const shareId = share.docs[0].id
    
                // Remove share from firestore
                await firestore().collection('share').doc(shareId).delete()
            }
            navigation.navigate('Home')
        } catch(e) {
            showMessage(t('ScreenWishlist.error_leave'), `${e}`)
            return
        }
    }

    // Show menu
    const onPressMore = () => {
        if(editMode) {
            const leave = editMode && (wishlist?.editors?.length ?? 0) > 1
            const options = [
                t('ScreenWishlist.option_share'), 
                t('ScreenWishlist.option_manage_editors'), 
                t('ScreenWishlist.option_edit'), 
                t('ScreenWishlist.option_export_pdf'), 
                t('ScreenWishlist.option_export_text'), 
                t('ScreenWishlist.option_print'), 
                leave ? t('ScreenWishlist.option_leave') : t('ScreenWishlist.option_delete')]
            if(Platform.OS === 'ios') options.push(t('ScreenWishlist.cancel'))

            showActionSheetWithOptions({
                options,
                message: t('ScreenWishlist.share_message', {title: wishlist?.title}),
                cancelButtonIndex: Platform.OS === 'ios' ? 6 : -1 /* Workaround to get dismiss to work, do not remove */,
                destructiveButtonIndex: 6
            }, (index) => {
                switch(index) {
                    case 0:
                        onPressShare()
                        break
                    case 1:
                        onPressShareEditAccess()
                        break
                    case 2:
                        onPressEditCover()
                        break
                    case 3:
                        onPressExportPdf()
                        break
                    case 4:
                        onPressExportText()
                        break
                    case 5:
                        onPressPrint()
                        break
                    case 6:
                        leave ? onPressEditorLeave() : onPressDelete()
                        break
                }
            })
        } else {
            showActionSheetWithOptions({
                options: Platform.OS === 'ios' ? [t('ScreenWishlist.option_leave'), t('ScreenWishlist.cancel')] : [t('ScreenWishlist.option_leave')],
                message: t('ScreenWishlist.share_message', {title: wishlist?.title}),
                cancelButtonIndex: Platform.OS === 'ios' ? 1 : -1 /* Workaround to get dismiss to work, do not remove */,
                destructiveButtonIndex: 0
            }, (index) => {
                switch(index) {
                    case 0:
                        onPressLeave()
                        break
                }
            })
        }

    }

    // Open manage editors screen
    const onPressShareEditAccess = () => {
        const wishlistId = wishlist?.id
        navigation.navigate('WishlistAddEditor', {wishlistId})
    }

    // Open wishlist edit screen
    const onPressEditCover = () => {
        navigation.navigate('EditWishlist', {wishlist})
    }

    // Export the wishlist as a pdf
    const onPressExportPdf = async () => {
        if(!wishlist || !wishes) return
        const html = wishlistToHtml(wishlist, wishes)
        if(Platform.OS === 'web') {
            alert(t('ScreenWishlist.not_implemented_pdf'))
        } else {
            const { uri } = await Print.printToFileAsync({html})
            await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
        }
    }

    // Export the wishlist as text
    const onPressExportText = async () => {
        if(!wishlist || !wishes) return
        try {
            const text = wishlistToText(wishlist, wishes)
            await Clipboard.setStringAsync(text)
            showMessage(t('ScreenWishlist.info'), t('ScreenWishlist.success_text_export'))
        } catch(e) {
            console.error(e)
            Alert.alert(t('ScreenWishlist.error_text_export_title'), t('ScreenWishlist.error_text_export_text'))
        }
    }

    // Print the wishlist
    const onPressPrint = async () => {
        if(!wishlist || !wishes) return
        if(Platform.OS === 'web') {
            alert(t('ScreenWishlist.not_implemented_print'))
        } else {
            // Convert wishlist to HTML
            const html = wishlistToHtml(wishlist, wishes)
            if(Platform.OS === 'ios') {
                // On iOS, select printer and print
                const printer = await Print.selectPrinterAsync()
                if(!printer || !printer.url) return
                await Print.printAsync({html, printerUrl: printer.url})
            } else {
                // On Android, print to default printer
                await Print.printAsync({html})
            }
        }
    }

    // Delete the wishlist
    const onPressDelete = async () => {
        if(Platform.OS === 'web') {
            const confirmation = confirm(t('ScreenWishlist.web_confirm_deletion', {title: wishlist?.title}))
            if(confirmation) onPressConfirmDelete()
        } else {
            Alert.alert(
                t('ScreenWishlist.native_confirm_deletion_title'), 
                t('ScreenWishlist.web_confirm_deletion', {title: wishlist?.title}), 
                [
                    {text: t('ScreenWishlist.button_delete'), onPress: onPressConfirmDelete, style: 'destructive'}, 
                    {text: t('ScreenWishlist.cancel'), style: 'cancel'}
                ], 
                {cancelable: true}
            )
        }
    }

    // Open app store page (web only)
    const openAppStorePage = async () => {
        let url: string | null = null
        switch(Device.osName) {
            case 'Android':
                url = 'https://play.google.com/store/apps/details?id=nu.oenskeliste'
                break
            case 'iOS':
                url = 'https://apps.apple.com/dk/app/%C3%B8nskeliste/id1441878692'
                break
        }

        try {
            if(url !== null) {
                if(await Linking.canOpenURL(url)) {
                    await Linking.openURL(url)
                }
            }
        } catch {
            // Do nothing
        }
    }

    // Create wishlist link if user is not logged in
    // This is so the user will have the wishlist on their shared list
    // when they log in or create an account
    useAsyncEffect(async () => {
        if(!wishlist?.id) return
        const user = getUser()
        if(!user) { 
            try {
                await setWishlistLink(wishlist?.id)
                
                // If the user is on mobile web, redirect to the app store page once per day
                if(Platform.OS === 'web') {
                    const timePastOpenString = await AsyncStorage.getItem('timePastOpen')
                    const timeNow = new Date().getTime()
                    if(!timePastOpenString) {
                        await AsyncStorage.setItem('timePastOpen', `${timeNow}`)
                        openAppStorePage()
                    } else {
                        const timePastOpen = parseInt(timePastOpenString)
                        if(timeNow - timePastOpen > 1000 * 60 * 60 * 24) {
                            await AsyncStorage.setItem('timePastOpen', `${timeNow}`)
                            openAppStorePage()
                        }
                    }
                }
            } catch {
                // Do nothing
            }
        }
    }, [wishlist])

    // Delete the wishlist
    const onPressConfirmDelete = async () => {
        
        if(Platform.OS === 'web') {
            // Delete all wishes in Firestore
            const db = webFirestore.getFirestore()
            const wishesCollectionRef = webFirestore.collection(db, `wishlist/${wishlist?.id}/wish`)
            const wishes = await webFirestore.getDocs(wishesCollectionRef)
            wishes.forEach(async doc => {
                const docRef = doc.ref
                await webFirestore.deleteDoc(docRef)
            })

            // Delete wishlist in Firestore
            const wishlistRef = webFirestore.doc(db, `wishlist/${wishlist?.id}`)
            await webFirestore.deleteDoc(wishlistRef)
        } else {
            // Delete all wishes in Firestore
            const wishlistId = wishlist?.id
            const wishes = await firestore().collection('wishlist').doc(wishlistId).collection('wish').get()
            wishes.forEach(async doc => {
                await doc.ref.delete()
            })
    
            // Delete wishlist in Firestore
            await firestore().collection('wishlist').doc(wishlistId).delete()
        }

        // Go back to home screen
        navigation.navigate('Home')
    }

    // Render the top of the page, above the wishes
    const renderTop = () => {
        return <View>

            {/* Editors container */}
            <View style={[PageStyle.row]}>

                {/* Editors */}
                {editors.length > 0 && 
                    editors.map(editor => 
                        <View style={style.profilePictureShadow} key={Math.random() /* Lazyness */}>
                            <Image 
                                style={style.profilePicture} 
                                source={{uri: editor.image}} />
                        </View>
                    ) 
                }
            </View>

            {/* Wishlist title and subtitle */}
            {!!wishlist?.title && <Text style={Typography.WishlistTitle}>{wishlist?.title}</Text>}
            {!!wishlist?.subtitle && <Text style={[Typography.WishlistSubtitle, style.subtitle]}>{wishlist?.subtitle}</Text>}
            
            {/* Create wish button (only visible when user is editor) */}
            { editMode && <View>
                <Divider.Small />
                <Button.Primary 
                    onPress={onPressCreateWish} 
                    style={Platform.OS === 'web' && {width: '100%', maxWidth: 633}} 
                    text={t('ScreenWishlist.button_create_wish')} />
            </View> }
            <Divider.Tiny />
        </View>
    }

    // Group array elements in pairs, to be used for rendering wishes
    const inPairs = (_: any, i: any, a: any) => i % 2 ? [] : [a.slice(i, i + 2)]

    // Render wishes on web or native, depending on platform
    const renderWishes = () => {
        return Platform.OS === 'web' ? renderWishesWeb() : renderWishesNative()
    }

    // Render wishes for native
    const renderWishesNative = () => {

        if(viewMode === ViewMode.Cards) {
            // Render a list of wishes (CARD MODE)
            return <FlatList 
                contentContainerStyle={{alignSelf: 'stretch', padding: 24}} 
                ListFooterComponent={<Divider.Gigantic />}
                scrollEnabled={true}
                data={[ [{}, {}] as [IWish, IWish][], [{}, {}] as [IWish, IWish][], ...wishes].flatMap(inPairs) as [IWish, IWish][]}
                renderItem={itemInfo => {

                    // Render wishlist info at top
                    if(itemInfo.index === 0) return renderTop()

                    // Render wish pair (card mode)
                    return <View style={{flexDirection: 'row'}}>
                        {/* Wish 1 */}
                        { wishlist != null && 
                            <CardWish 
                                wishlist={wishlist} 
                                reserved={wishIsReserved(itemInfo.item[0], reservations)} 
                                wish={itemInfo.item[0]} 
                                viewMode={viewMode}
                                /> 
                        }
                        
                        {/* Wish 2 (if any) */}
                        {itemInfo.item.length > 1 && wishlist != null && 
                            <CardWish 
                                reserved={wishIsReserved(itemInfo.item[1], reservations)} 
                                wishlist={wishlist} 
                                wish={itemInfo.item[1]} 
                                viewMode={viewMode}
                                />
                        }
                    </View>
                }}
                keyExtractor={wish => `${wish[0].id}`}
            />
        } else {
            // Render a list of wishes (LIST MODE)
            const wishesWithTop = [null, ...wishes]
            return <FlatList 
                contentContainerStyle={{alignSelf: 'stretch', padding: 24}} 
                scrollEnabled={true}
                ListFooterComponent={<Divider.Gigantic />}
                data={wishesWithTop}
                renderItem={itemInfo => {

                    // Render wishlist info at top
                    if(itemInfo.item === null) return renderTop()

                    // Render wish pair (card mode)
                    if(!wishlist) return <View />
                    return <CardWish 
                                wishlist={wishlist} 
                                reserved={wishIsReserved(itemInfo.item, reservations)} 
                                wish={itemInfo.item} 
                                viewMode={viewMode}
                                /> 
                        

                }}
                keyExtractor={wish => `${wish?.id ?? 'top'}`} 
                />
        }


    }

    // Render wishes for web
    const renderWishesWeb = () => {

        // If no wishes, return an empty view
        if(!wishlist) return <View />

        const cardStyle = {flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignSelf: 'center'} as ViewStyle
        const listStyle = {alignSelf: 'center', width: '100%'} as ViewStyle

        // Render a list of wishes
        return ( 
                <ScrollView contentContainerStyle={{alignSelf: 'center', flex: 1, padding: 24, maxWidth: 1024, width: '100%'}}>
                    <Divider.Small />
                    {/* Render wishlist info at top */}
                    { renderTop() }
                    <Divider.Large />

                    {/* Render wishes */}
                    
                    <View style={viewMode === ViewMode.Cards ? cardStyle : listStyle}>
                        { wishes.map(wish => 
                            <CardWish 
                                reserved={wishIsReserved(wish, reservations)} 
                                key={wish.id} 
                                wishlist={wishlist} 
                                wish={wish} 
                                viewMode={viewMode}
                            />)
                        }
                    </View>
                    <Divider.Large />
                </ScrollView>
        )
    }

    const onPressDismissReviewGate = () => {
        setShowReviewGate(false)
    }

    const onPressToggleNotifications = async () => {
        if(!wishlist) return
        
        // Ask for permission if needed, when the user wants to enable notifications
        if(!wishlist.notifications) {
            const notificationsAllowed = await askForNotificationPermission()
            if(!notificationsAllowed) return
        }

        const toggleNotifications = async () => {
            try {
                wishlistSetNotifications(wishlist.id, !wishlist.notifications)
            } catch {
                showMessage('Fejl', 'Kunne ikke ændre notifikationsindstillingen')
            }
        }

        if(wishlist.notifications) {
            Alert.alert(
                'Slå notifikationer fra?',
                'Du vil ikke længere modtage notifikationer, når der er tilbud på ting på din liste.',
                [
                    {text: 'Annuller', style: 'cancel'},
                    {text: 'Slå fra', onPress: toggleNotifications}
                ]
            )
        } else {
            toggleNotifications()
        }
    }

    return <>
        {/* Review gate */}
        {showReviewGate && <ReviewGate onPressDismiss={onPressDismissReviewGate} />}
    
        <View style={PageStyle.default}>

            {/* Full-page loading overlay */}
            { !showReviewGate && !!loadingText && <LoadingOverlay text={loadingText} /> }

            {/* Page container */}
            <SafeAreaView style={{ flex: 1 }}>

                {/* Share button */}
                {editMode && <TouchableOpacity style={style.shareButton} activeOpacity={0.8} onPress={onPressShare}>
                    <IconEnvelope width={64} height={64} />
                    <Text style={[Typography.SubsubtitleLight, {color: Color.White}]}>{t('ScreenWishlist.share')}</Text>
                </TouchableOpacity>}

                <Divider.Medium />
                <View style={{justifyContent: 'center', width: '100%'}}>
                    <View style={[PageStyle.row, {maxWidth: 1024, width: '100%', alignSelf: 'center'}]}>
                        <Button.Back text={t('ScreenWishlist.button_back')} />
                        
                        <View style={style.titlebarIcons}>
                            {/* Wish style button (cards/list toggle) */}
                            <TouchableOpacity onPress={toggleViewMode}>
                                { viewMode === ViewMode.List && <Animated.View entering={FadeIn}>
                                    <IconViewCards width={34} height={34} />
                                </Animated.View> }
                                { viewMode === ViewMode.Cards && <Animated.View entering={FadeIn}>
                                    <IconViewList width={34} height={34} />
                                </Animated.View> }
                            </TouchableOpacity>

                            {/* Notifications button */}
                            {Platform.OS !== 'web' && editMode && (
                                <TouchableOpacity onPress={onPressToggleNotifications} style={{marginHorizontal: 4}}>
                                    <Animated.View entering={FadeIn}>
                                        {wishlist?.notifications ? (
                                            <IconBell width={34} height={34} />
                                        ) : (
                                            <IconBellOff width={34} height={34} />
                                        )}
                                    </Animated.View>
                                </TouchableOpacity>
                            )}

                            {/* Menu options, dots */}
                            { (Platform.OS !== 'web' || editMode) && <TouchableOpacity onPress={onPressMore}><IconMore width={34} height={34} /></TouchableOpacity> }
                        </View>
                    </View>
                </View>
                
                {/* Wishes */}
                <ImageBackground style={PageStyle.fillParent} source={{uri: wishlist?.imageUrl}}>
                    <TopGradient style={style.gradient} width={'100%'} height={292} /> 
                    { renderWishes() }
                </ImageBackground>

            </SafeAreaView>
        </View>
    </>
}

export default ScreenWishlist