import { StyleSheet, Text, TouchableOpacity } from "react-native"
import ICategory from "../models/ICategory"
import Color from "../style/Color"
import Typography from "../style/Typography"
import IconCheckEmpty from "./icons/IconCheckEmpty"
import IconCheckFull from "./icons/IconCheckFull"

const style = StyleSheet.create({
    container: {
        height: 64,
        borderRadius: 22.5,
        flex: 1,
        elevation: 2,
        flexDirection: 'row',
        backgroundColor: Color.White,
        alignItems: 'center',
        paddingLeft: 16,
        marginBottom: 8
    },
    icon: {
        
    },
    text: {
        marginLeft: 16,
        flex: 1
    },
    check: {
        marginRight: 16
    }
})

interface CardInterestProps {
    category: ICategory
    onValueChange?: (caterory: ICategory, selected: boolean) => void
    value?: boolean
}

const CardInterest = (props: CardInterestProps) => {

    const selected = props.value ?? false

    const onPress = () => {
        if(props.onValueChange) {
            props.onValueChange(props.category, !selected)
        }
    }

    return ( 
        <TouchableOpacity onPress={onPress} style={style.container}>
            {props.category.icon}
            <Text style={[Typography.Text, style.text]}>{props.category.name}</Text>
            { selected ? (
                <IconCheckFull style={style.check} width={32} height={32} />
            ) : (
                <IconCheckEmpty style={style.check} width={32} height={32} />
            )}
        </TouchableOpacity>
    )
}

export default CardInterest