import { useNavigation, useRoute } from "@react-navigation/native";
import { useState } from "react";
import { productSearch, productsFilterDiscounted, useAsyncEffect, wishlistGet, wishlistGetWishes } from "../Api";
import Button from "../components/Button";
import Divider from "../components/Divider";
import LoadingOverlay from "../components/LoadingOverlay";
import IProduct from "../models/IProduct";
import IWishlist from "../models/IWishlist";
import PageStyle from "../style/PageStyle";
import Typography from "../style/Typography";
import { Text, View, SafeAreaView, ScrollView, Platform } from 'react-native'
import CardProductLarge from "../components/CardProductLarge";

const ScreenWishlistOffers = () => {

    const navigation = useNavigation()
    const route = useRoute<any>()
    const wishlistId = route.params.wishlistId

    const [wishlist, setWishlist] = useState<IWishlist>()
    const [products, setProducts] = useState<IProduct[]>([])
    const [loading, setLoading] = useState(true)

    useAsyncEffect(async () => {
        
        // Get wishlist
        const wishlist = await wishlistGet(wishlistId)

        // Get wishes that has a product
        const wishes = await wishlistGetWishes(wishlistId)
        const productWishes = await wishes.filter(wish => wish.productId !== undefined)
        const productsIds = productWishes.map(wish => wish.productId!)

        // Get discounted products
        const products = await productsFilterDiscounted(productsIds)
        setProducts(products)

        setLoading(false)
    }, [])

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>

                {/* Loading overlay */}
                { loading && <LoadingOverlay text="Indlæser tilbud..." /> }

                {/* Web logo */}
                <Divider.Medium />
                {Platform.OS === 'web' && <Button.Back />}
                {Platform.OS === 'web' && <Divider.Gigantic /> }

                <ScrollView style={PageStyle.safeArea}>

                    {/* Screen title and subtitle */}
                    <Text style={Typography.Title}>Nedsatte produkter</Text>
                    <Text style={Typography.Subtitle}></Text>
                    <Divider.Large />

                    {/* Products */}
                    {products.map(product => (
                        <CardProductLarge key={product.id} product={product} />
                    ))}

                </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ScreenWishlistOffers