import { View, Text, StyleSheet, ScrollView, Alert, SafeAreaView } from "react-native"
import Button from "../components/Button"
import CardWishlist from "../components/CardWishlist"
import Divider from "../components/Divider"
import TextInput from "../components/TextInput"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import * as ImagePicker from "expo-image-picker"
import { useState } from "react"
import * as api from '../Api'
import { useNavigation } from "@react-navigation/native"
import { useTranslation } from "react-i18next"
import { wishlistCreate } from '../Api'

const style = StyleSheet.create({
    subtitle: {
        marginTop: -20
    },
    changeBackground: {
        maxWidth: 210
    }
})

const ScreenWishlistCreate = () => {

    const navigation = useNavigation()
    const { t } = useTranslation()
    const [title, setTitle] = useState<string>('')
    const [subtitle, setSubtitle] = useState<string>('')
    const [backgroundImageUri, setBackgroundImageUri] = useState<string | null>(null)
    const [loadingText, setLoadingText] = useState<string>()

    const onPressChangeBackground = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
            allowsEditing: true,
            aspect: [3, 2],
            quality: 0.5
        })

        if(!result.cancelled) {
            setBackgroundImageUri(result.uri)
        }
    }

    const onPressCreateWishlist = async () => {

        // Upload image if needed
        let imageUrl: string = api.defaultWishlistBackgroundUrl
        if(backgroundImageUri) {
            try {
                setLoadingText(t('ScreenWishlistCreate.loading_uploading_picture'))
                imageUrl = await api.uploadImage(backgroundImageUri)
            } catch(e: any) {
                console.error(e)
                setLoadingText(undefined)
                Alert.alert(t('ScreenWishlistCreate.error_upload_title'), t('ScreenWishlistCreate.error_upload_text', {message: e.message}))
                return
            }
        }

        // Create the wishlist
        try {
            setLoadingText(t('ScreenWishlistCreate.loading_creating_wishlist'))
            await wishlistCreate(title, subtitle, imageUrl, undefined, false)
            navigation.goBack()
        } catch(e: any) {
            Alert.alert(t('ScreenWishlistCreate.error_create_title'), t('ScreenWishlistCreate.error_create_text', {message: e.message}))
            console.error(e)
            return
        } finally {
            setLoadingText(undefined)
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                <Divider.Medium />
                <Button.Back text={t('ScreenWishlistCreate.button_back')}/>
                <Divider.Small />

                <ScrollView style={PageStyle.safeArea}>
                    

                    <Text style={Typography.Title}>{t('ScreenWishlistCreate.title')}</Text>
                    <Text style={[Typography.Subtitle, style.subtitle]}>{t('ScreenWishlistCreate.subtitle')}</Text>
                    <Divider.Large />

                    <TextInput title={t('ScreenWishlistCreate.input_title')} placeholder={t('ScreenWishlistCreate.input_title_placeholder')} onChangeText={setTitle} />
                    <Divider.Medium />
                    <TextInput title={t('ScreenWishlistCreate.input_subtitle')} placeholder={t('ScreenWishlistCreate.input_subtitle_placeholder')} onChangeText={setSubtitle} />
                    <Divider.Medium />

                    <Text style={Typography.InputTitle}>{t('ScreenWishlistCreate.preview')}</Text>
                    <CardWishlist title={title} subtitle={subtitle} imageUri={backgroundImageUri} />
                    <Divider.Small />
                    <Button.PrimarySmall text={t('ScreenWishlistCreate.button_change_background')} style={style.changeBackground} onPress={onPressChangeBackground} disabled={loadingText ? true : false} />
                    <Divider.Medium />
                    
                    <Button.Primary text={t('ScreenWishlistCreate.button_create')} loadingText={loadingText} onPress={onPressCreateWishlist} />
                    <Divider.Small />
                </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ScreenWishlistCreate