import { View } from "react-native";

const Hair = () => (<View style={{height: 1, alignSelf: 'stretch'}} />)
const Micro = () => (<View style={{height: 4, alignSelf: 'stretch'}} />)
const Tiny = () => (<View style={{height: 8, alignSelf: 'stretch'}} />)
const Small = () => (<View style={{height: 16, alignSelf: 'stretch'}} />)
const Medium = () => (<View style={{height: 24, alignSelf: 'stretch'}} />)
const Large = () => (<View style={{height: 32, alignSelf: 'stretch'}} />)
const Huge = () => (<View style={{height: 64, alignSelf: 'stretch'}} />)
const Gigantic = () => (<View style={{height: 128, alignSelf: 'stretch'}} />)
const Extreme = () => (<View style={{height: 256, alignSelf: 'stretch'}} />)
const Insane = () => (<View style={{height: 512, alignSelf: 'stretch'}} />)

export default { Hair, Micro, Tiny, Small, Medium, Large, Huge, Gigantic, Extreme, Insane }