import { Image, Platform, StyleSheet, Text, View } from "react-native"
import Color from "../style/Color"
import Typography from "../style/Typography"
import {defaultProductPicture, getProduct, limitText, openUrl, showMessage} from '../Api'
import PageStyle from "../style/PageStyle"
import IReservation from "../models/IReservation"
import Button from "./Button"
import * as Linking from 'expo-linking'
import * as webFirestore from 'firebase/firestore'
import firestore from '@react-native-firebase/firestore'
import Divider from "./Divider"
import { useNavigation } from "@react-navigation/native"
import { useTranslation } from "react-i18next"

const style = StyleSheet.create({
    view: {
        borderRadius: 22.5,
        width: Platform.OS === 'web' ? '32%' : undefined,
        flexDirection: 'row',
        overflow: 'hidden',
    },
    textBrand: {
        marginTop: -7
    },
    image: {
        width: 96,
        height: 96,
        alignSelf: 'flex-start',
        backgroundColor: Color.ImageBackground,
        marginRight: 8
    },
    innerView: {
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor: Color.Background,
        flexDirection: 'row',
        borderRadius: 22.5,
        overflow: 'hidden'
    },
    bottomView: {
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
    },
    textTitle: {
        marginTop: 4,
        lineHeight: 24
    }
})

interface ICardReservation {
    reservation: IReservation
    hideShadow?: boolean
}

export default (props: ICardReservation) => {

    const { t } = useTranslation()
    const navigation = useNavigation<any>()

    const hasComment = props.reservation.comment !== undefined
    const hasUrl = props.reservation.url !== undefined && props.reservation.url.length > 0
    const hasProductId = !!props?.reservation?.productId

    const onPressBuy = async () => {

        try {
            if(hasProductId) {
                const productId = props.reservation.productId!
                const product = await getProduct(`${productId}`)
                navigation.navigate('ReservationsProduct', { productId, product, reservation: true })
            } else {
                openUrl({url: props.reservation.url})
            }
        } catch(e: any) {
            showMessage(t('CardReservation.error'), t('CardReservation.error_browser', {message: e?.message}))
        }
    }

    const onPressDone = async () => {
        try {
            if(Platform.OS === 'web') {
                const db = webFirestore.getFirestore()
                const reservationRef = webFirestore.doc(db, `reservation/${props.reservation.id}`)
                await webFirestore.updateDoc(reservationRef, {
                    bought: true
                })
            } else {
                await firestore().collection('reservation').doc(props.reservation.id).update({
                    bought: true
                })
            }
        } catch(e: any) {
            console.error(e)
            showMessage(t('CardReservation.error'), t('CardReservation.error_done', {message: e?.message}))
        }
    }

    const image = props.reservation.imageUrl.length > 0 ? props.reservation.imageUrl : defaultProductPicture
    

    return <>
        {!props.hideShadow && <Text style={Typography.Subsubtitle}>{props.reservation.wishlistTitle}</Text>}
        <View style={[!props.hideShadow && PageStyle.Shadow, {borderColor: Color.DarkTransparentMore, borderRadius: 22.5, borderWidth: 1, margin: 4, marginVertical: props.hideShadow ? -2 : 4}]}>
            <View style={style.innerView}>
                <Image source={{uri: image}} style={style.image} />
                <View>
                    <Text style={[Typography.ProductTitle, style.textTitle]}>{limitText(props.reservation.name, 27)}</Text>
                    {hasComment && <Text style={[Typography.ProductBrand, style.textBrand]}>{limitText(props.reservation.comment ?? '', 30)}</Text> }
                    <Divider.Tiny />
                    {!props.reservation.bought && <View style={PageStyle.row}>
                        { hasUrl && <Button.PrimarySmall onPress={onPressBuy} text={t('CardReservation.button_buy')} style={{marginRight: 8, paddingHorizontal: 8}} /> }
                        <Button.SecondarySmall onPress={onPressDone} text={t('CardReservation.button_done')} style={{paddingHorizontal: 8}} />
                    </View>}
                    {props.reservation.bought && <View style={PageStyle.row}>
                    { hasUrl && <Button.PrimarySmall onPress={onPressBuy} text={t('CardReservation.button_buy_again')} style={{marginRight: 4, paddingHorizontal: 8}} /> }
                    </View>}
                </View>
            </View>
        </View>
    </>
}