import { useFocusEffect, useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { useState, useEffect } from 'react'
import { View, Text, StyleSheet, ScrollView, ImageBackground, TouchableOpacity, Platform, Alert, Dimensions, SafeAreaView } from 'react-native'
import BottomGradient from '../components/BottomGradient'
import Button from '../components/Button'
import CardWishlist from '../components/CardWishlist'
import Divider from '../components/Divider'
import Gift from '../components/Gift'
import PageStyle from '../style/PageStyle'
import Typography from '../style/Typography'
import auth, { FirebaseAuthTypes } from '@react-native-firebase/auth'
import * as authWeb from 'firebase/auth'
import * as firestoreWeb from 'firebase/firestore'
import IWishlist from '../models/IWishlist'
import firestore from '@react-native-firebase/firestore'
import * as Linking from 'expo-linking'
import LoadingOverlay from '../components/LoadingOverlay'
import ICordovaWishlist from '../models/ICordovaWishlist'
import * as api from '../Api'
import { addShareFromIp, facebookTrackOpenSharedWishlist, getUser } from '../Api'
import Animated, { FadeInDown, FadeOutDown } from 'react-native-reanimated'
import * as Notification from 'expo-notifications'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useTranslation } from 'react-i18next'

const style = StyleSheet.create({
    viewNoWishlists: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: Dimensions.get('window').height / 1.5
    },
    viewWishlistEntry: {
        marginTop: 8
    },
    viewBottom: {
        alignSelf: 'stretch',
        height: 104,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0
    },
    buttonBottom: {
        position: 'absolute', 
        left: 24, 
        right: 24, 
        bottom: 24
    },
    profilePicture: {
        width: 48, height: 48, borderRadius: 24, overflow: 'hidden',
        alignSelf: 'flex-end',
        marginLeft: 16,
        marginTop: 4
    }
})

const ScreenHome = () => {

    const navigation = useNavigation<any>()
    const { t } = useTranslation()
    const [user, setUser] = useState<FirebaseAuthTypes.User | authWeb.User | null>(null)
    const [wishlists, setWishlists] = useState<IWishlist[]>([])
    const [sharedWishlists, setSharedWishlists] = useState<IWishlist[]>([])
    const [cordovaWishlists, setCordovaWishlists] = useState<ICordovaWishlist[]>([])
    const [loadingText, setLoadingText] = useState<string | undefined>(t('ScreenHome.loading'))

    const onPressCreateWishlist = () => { 
        if(cordovaWishlists.length > 0) {
            Alert.alert(t('ScreenHome.dialog_update_title'), t('ScreenHome.dialog_update_message'))
            navigation.navigate('CreateAccount', {email: '', password: '', migrateFromCordova: true})
        } else {
            navigation.navigate(user ? 'CreateWishlist' : 'Login') 
        }
    }

    const onPressWishlist = (wishlist: IWishlist, shared?: boolean) => { 
        const wishlistId = wishlist.id
        navigation.navigate('Wishlist', {wishlistId, shared}) 
    }

    const onPressProfile = () => {
        navigation.navigate('Profile')
    }

    for(let i=0; i<sharedWishlists.length; i++) {
        if(wishlists.some(wishlist => wishlist.id === sharedWishlists[i].id)) {
            sharedWishlists.splice(i, 1)
        }
    }


    // Check if a notification was pressed, if so navigate to the reservation page
    useEffect(() => {
        Notification.addNotificationResponseReceivedListener(async (response) => {
            const type = response.notification.request.content.data.type as string
            if(!type) return

            switch(type) {
                case 'reservation-reminder':
                    navigation.navigate('Reservationer')
                    break
                default:
                    return
            }
        })

    }, [])

    useFocusEffect(() => {
        setUser(getUser())
    }) 

    // Cordova wishlists and external links
    useEffect(() => {

        if(Platform.OS !== 'web') {
            // Get cordova wishlists
            api.getCordovaWishlists().then(cordovaWishlists => {
                setCordovaWishlists(cordovaWishlists)
            })
    
            // Check if the app was opened from an external deep link
            Linking.getInitialURL().then(url => {
                if(url) {
                    console.log(url)
                    openExternalWishlist(url)
                } else {
                    AsyncStorage.getItem('firstTimeUse').then(value => {
                        if(!value) {
                            AsyncStorage.setItem('firstTimeUse', 'true')
                            navigation.navigate('Landing')
                        }
                    })
                }
            })
    
            // While the app is in the background, check if external links are opened 
            // We don't unubscribe from this listener, it will be called from other screens
            Linking.addEventListener('url', async (e) => {
                if(e.url) {
                    openExternalWishlist(e.url)
                }
            })
        }
    }, [])

    // Listen for user changes (native)
    useEffect(() => {
        if(Platform.OS !== 'web') {
            const unsubscribeUser = auth().onAuthStateChanged(user => {
                if(!user) {
                    setWishlists([])
                    setSharedWishlists([])
                }
                setUser(user)
                setLoadingText(undefined)
            
            })
            return unsubscribeUser
        }
    }, [])

    useFocusEffect(React.useCallback(() => {
        if(!user) return
        try {
            addShareFromIp()
        } catch {
            // No error handling needed
        }
    }, []))

    // Listen for user changes (web)
    useEffect(() => {
        if(Platform.OS === 'web') {
            const unsubscribeUser = authWeb.onAuthStateChanged(authWeb.getAuth(), user => {
                if(!user) {
                    setWishlists([])
                    setSharedWishlists([])
                }
                setTimeout(() => {setUser(user)}, 1000)
                setLoadingText(undefined)
            })
            return unsubscribeUser
        }
    }, [])

    // Listen for wishlist and shared wishlist updates
    useEffect(() => {
        if(!user) return

        if(Platform.OS === 'web') {
            setLoadingText(t('ScreenHome.loading_wishlists'))
            const uid = user.uid
            const db = firestoreWeb.getFirestore()
            const wishlistsRef = firestoreWeb.collection(db, 'wishlist')
            const ownWishlistsQuery = firestoreWeb.query(wishlistsRef, firestoreWeb.where('editors', 'array-contains', uid))
            const unsubscribeWishlists = firestoreWeb.onSnapshot(ownWishlistsQuery, snapshot => {
                if(!snapshot) return
                setWishlists(snapshot.docs.map(doc => {
                    const wishlist = doc.data()
                    wishlist.id = doc.id
                    return wishlist as IWishlist
                }))
                setLoadingText(undefined)
            })

            const shareRef = firestoreWeb.collection(db, 'share')
            const shareQuery = firestoreWeb.query(shareRef, firestoreWeb.where('uid', '==', uid))
            const unsubscribeShares = firestoreWeb.onSnapshot(shareQuery, async snapshot => {
                if(!snapshot) return
                const sharedWishlists: IWishlist[] = []
                for(let i=0; i<snapshot.docs.length; i++) {
                    const share = snapshot.docs[i].data()
                    const wishlistId = share.wishlistId
                    const wishlistDocRef = await firestoreWeb.doc(db, `wishlist/${wishlistId}`)
                    const wishlist = (await firestoreWeb.getDoc(wishlistDocRef)).data()
                    if(wishlist) {
                        wishlist.id = wishlistId
                        sharedWishlists.push(wishlist as IWishlist)
                    }
                }
                setSharedWishlists(sharedWishlists)
                setLoadingText(undefined)
            })

            return () => {
                unsubscribeWishlists()
                unsubscribeShares()
            }
            
        } else {
            setLoadingText(t('ScreenHome.loading_wishlists'))
            const uid = user.uid
            const unsubscribeWishlists = firestore().collection('wishlist').where('editors', 'array-contains', uid).onSnapshot(snapshot => {
                if(!snapshot) {
                    setLoadingText(undefined)
                    return
                }
                setLoadingText(undefined)
                setCordovaWishlists([])
                setWishlists(snapshot.docs.map(doc => {
                    const wishlist = doc.data()
                    wishlist.id = doc.id
                    return wishlist as IWishlist
                }))
            })

            const unsubscribeShares = firestore().collection('share').where('uid', '==', uid).onSnapshot(async snapshot => {
                if(!snapshot) return
                const sharedWishlists: IWishlist[] = []
                for(let i=0; i<snapshot.docs.length; i++) {
                    const share = snapshot.docs[i].data()
                    const wishlistId = share.wishlistId
                    const wishlistDoc = await firestore().collection('wishlist').doc(wishlistId).get()
                    const wishlist = wishlistDoc.data()
                    if(wishlist) {
                        wishlist.id = wishlistId
                        sharedWishlists.push(wishlist as IWishlist)
                    }
                }
                setCordovaWishlists([])
                setSharedWishlists(sharedWishlists)
            })
            return () => {
                unsubscribeWishlists()
                unsubscribeShares()
            }
        } 
        
    }, [user])

    const openExternalWishlist = async (url: string) => {

        console.log(url)

        let urlWithoutParamters = url.split('?')[0]
        // Remove trailing slash if it exists
        if(urlWithoutParamters.endsWith('/')) {
            urlWithoutParamters = urlWithoutParamters.slice(0, -1)
        }
        
        const wishlistId = urlWithoutParamters.split('/').pop()
        console.log('Wishlist ID: ' + wishlistId)
        const user = auth().currentUser

        const addShare = async () => {
            if(user != null) {
                // Extract the wishlist id from the url
                if(!wishlistId) return
        
                // Don't add to shared wishlists if the user is already an editor
                const wishlist = await firestore().collection('wishlist').doc(wishlistId).get()

                // Ensure the wishlist exists
                if(!wishlist.exists) return
                
                const editors = wishlist.data()?.editors
                if(!editors || editors.includes(user.uid)) return

                // Check if this wishlist was shared with the user before
                const uid = user.uid
                const shareDoc = await firestore().collection('share')
                    .where('wishlistId', '==', wishlistId)
                    .where('uid', '==', uid).get()
                
                // If the user already opened this wishlist, do nothing
                if(!shareDoc.empty) return

                facebookTrackOpenSharedWishlist(wishlistId)

                // Otherwise, add a new share entry
                return firestore().collection('share').add({
                    wishlistId,
                    uid,
                })
            } else {
                return Promise.resolve()
            }
        }

        await addShare()
        navigation.navigate('Home')
        navigation.navigate('Wishlist', {
            wishlistId,
            shared: true
        })
    }

    const onPressCordovaWishlist = (cordovaWishlist: ICordovaWishlist) => {
        Alert.alert(t('ScreenHome.dialog_update_title'), t('ScreenHome.dialog_update_message'))
        navigation.navigate('CreateAccount', {email: '', password: '', migrateFromCordova: true})
    }

    return (
        <View style={PageStyle.default}>
            
            {/* Loading text */}
            {loadingText && <LoadingOverlay text={loadingText} />}

            <SafeAreaView style={[PageStyle.fillParent, PageStyle.webSafeArea]}>
                
                {Platform.OS === 'web' && <>
                    <View>
                        <Divider.Medium />
                            <Button.Back /> {/* Logo for web */}
                        <Divider.Medium />
                    </View>
                </> }
                
                <ScrollView 
                    style={[PageStyle.safeArea]} 
                    contentContainerStyle={Platform.OS === 'web' && {alignItems: 'center'}}>

                    {Platform.OS === 'web' && <Divider.Gigantic /> }
                    <Divider.Medium />

                    <View style={{flexDirection: 'row'}}>
                        <Text style={[Typography.Title, {fontSize: 35}]}>{user ? `${t('ScreenHome.hello')} ${api.limitText(user.displayName?.split(' ')[0] ?? '', 12)}` : t('ScreenHome.welcome')}</Text>
                        { !!user && <TouchableOpacity onPress={onPressProfile} style={PageStyle.Shadow}>
                            <ImageBackground source={{uri: user.photoURL ?? ''}} style={style.profilePicture} />
                        </TouchableOpacity> }
                    </View>

                    {Platform.OS === 'web' && user && <>
                        <Button.Primary text={t('ScreenHome.create_wishlist')} onPress={onPressCreateWishlist} />
                    </> }
                    
                    <Divider.Small />
                    {Platform.OS === 'web' && user && <Divider.Huge />}

                    <Text style={[Typography.Subtitle]}>{t('ScreenHome.title')}</Text>

                    {/* No wishlists */}
                    { wishlists.length === 0 && cordovaWishlists.length === 0 && sharedWishlists.length === 0 && <>
                        <Animated.View entering={FadeInDown} exiting={FadeOutDown} style={style.viewNoWishlists}>
                            <Gift index={0} />
                            <Divider.Medium />
                            {Platform.OS === 'web' && <Divider.Large />}

                            <Text style={Typography.TextLarge}>{t('ScreenHome.title_no_wishlists')}</Text>
                            <Text style={[Typography.Text, Typography.Center]}>{t('ScreenHome.text_no_wishlists')}</Text>
                            <Divider.Small />

                            {!user && Platform.OS === 'web' && <>
                                <Button.Primary text={t('ScreenHome.start')} onPress={onPressCreateWishlist} />
                            </> }
                        </Animated.View>
                    </>}

                    {/* Your wishlists */}
                    <View style={[PageStyle.rowWrap, {justifyContent: 'center'}]}>
                        { wishlists.map(wishlist => (
                            <View style={style.viewWishlistEntry} key={`${wishlist.id}`}>
                                <CardWishlist 
                                    onPress={() => onPressWishlist(wishlist)} 
                                    title={wishlist.title} 
                                    subtitle={wishlist.subtitle} 
                                    imageUri={wishlist.imageUrl} />
                            </View>
                        ))}
                    </View>

                    {/* Wishlists shared with you */}
                    <Divider.Small />
                    { sharedWishlists.length > 0 && <>
                        <Text style={[Typography.Subtitle]}>{t('ScreenHome.title_shared')}</Text>
                    </> }


                    { sharedWishlists.map((wishlist) => <View style={style.viewWishlistEntry} key={`${wishlist.id}`}>
                            <CardWishlist onPress={() => onPressWishlist(wishlist, true)} title={wishlist.title} subtitle={wishlist.subtitle} imageUri={wishlist.imageUrl} />
                        </View> 
                    )}

                    {/* Cordova wishlists */}
                    {Platform.OS !== 'web' && !user && <View>
                        {cordovaWishlists
                        .filter(cordovaWishlist => (cordovaWishlist?.Password?.length ?? 0) > 0)
                        .map(cordovaWishlist => 
                            <View style={style.viewWishlistEntry} key={`${cordovaWishlist.Id}`}> 
                                <CardWishlist 
                                    onPress={() => onPressCordovaWishlist(cordovaWishlist)} 
                                    title={cordovaWishlist.Title} 
                                    subtitle={cordovaWishlist.Description} 
                                    imageUri={cordovaWishlist.Image} />
                            </View>
                        )}    
                    </View>}

                    {wishlists.length > 0 && <Divider.Gigantic />}

                </ScrollView>
                
                { Platform.OS !== 'web' && <View style={style.viewBottom}>
                    <BottomGradient width={'100%'} height={'100%'} preserveAspectRatio="xMinYMin slice" />  
                    <Button.Primary text={user !== null ? t('ScreenHome.create_wishlist') : t('ScreenHome.start')} style={style.buttonBottom} onPress={onPressCreateWishlist} />
                </View> }

            </SafeAreaView>
        </View>
    )
}

export default ScreenHome