import { useEffect, useState } from "react"
import IProductWithProgram from "../models/IProductWithProgram"
import * as api from '../Api'
import { StyleSheet, View, Image, Text, TouchableOpacity } from "react-native"
import IconArrowRight from "./icons/IconArrowRight"
import Typography from "../style/Typography"
import Divider from "./Divider"
import { useTranslation } from "react-i18next"
import LoadingOverlay from "./LoadingOverlay"
import Animated, { FadeInUp } from "react-native-reanimated"
import * as Linking from 'expo-linking'
import { facebookTrackInitiatedPurchase } from "../Api"

const style = StyleSheet.create({
    container: {
        minHeight: 64,
    },
    containerRow: {
        height: 64,
        flexDirection: 'row',
        backgroundColor: 'white',
        borderRadius: 16,
        elevation: 2,
        shadowColor: 'black',
        marginBottom: 8,
        justifyContent: 'flex-start'
    },
    logo: {
        alignSelf: 'stretch',
        resizeMode: 'contain',
        width: 96,
        marginLeft: 16
    },
    middle: {
        flex: 1,
        justifyContent: 'center'
    },
    arrow: {
        alignSelf: 'center',
        marginRight: 16
    },
    price: {
        alignSelf: 'flex-end',
        marginRight: 16,
        fontSize: 18
    }
})

interface PriceComparisonProps {
    ean: string
}

const PriceComparison = (props: PriceComparisonProps) => {

    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState<IProductWithProgram[]>([])

    useEffect(() => {
        const getPriceComparison = async () => {
            try {
                const products = await api.productFindAll(props.ean)
                setProducts(products)
            } catch(e) {
                setProducts([])
            } finally {
                setLoading(false)
            }
        }

        getPriceComparison()
    }, [])

    const onPressProduct = async (product: IProductWithProgram) => {
        const canOpenUrl = Linking.canOpenURL(product.urlTracking)
        if(!canOpenUrl) {
            api.showMessage(t('PriceComparison.error_open_title'), t('PriceComparison.error_open_description'))
            return
        }

        await api.informProductBuy(product as any)
        Linking.openURL(product.urlTracking)
        facebookTrackInitiatedPurchase('product')
    }

    return (
        <View style={style.container}>
            { loading && <LoadingOverlay text={t('PriceComparison.loading')} /> }
            <Text style={[Typography.Subsubtitle]}>
                {t('PriceComparison.title')}
            </Text>
            <Divider.Micro />
            {products.map((product, index) => (
                <Animated.View entering={FadeInUp}>
                    <TouchableOpacity activeOpacity={0.7} style={style.containerRow} onPress={() => onPressProduct(product)} key={index}>
                        <Image style={style.logo} source={{uri: product.programLogoUrl}} />
                        <View style={style.middle}>
                            <Text style={[Typography.Subsubtitlelight, style.price]}>
                                {product.price} kr.
                            </Text>
                        </View>
                        <IconArrowRight style={style.arrow} width={24} height={24} />
                    </TouchableOpacity>
                </Animated.View>
            ))}
        </View>
    )

}

export default PriceComparison