import { View, Text, StyleSheet, ScrollView, Alert, Platform, SafeAreaView } from "react-native"
import Button from "../components/Button"
import CardWishlist from "../components/CardWishlist"
import Divider from "../components/Divider"
import TextInput from "../components/TextInput"
import PageStyle from "../style/PageStyle"
import Typography from "../style/Typography"
import * as ImagePicker from "expo-image-picker"
import { useState } from "react"
import * as api from '../Api'
import { useNavigation, useRoute } from "@react-navigation/native"
import IWishlist from "../models/IWishlist"
import firestore from '@react-native-firebase/firestore'
import * as webFirestore from 'firebase/firestore'
import { useTranslation } from "react-i18next"

const style = StyleSheet.create({
    subtitle: {
        marginTop: -20
    },
    changeBackground: {
        maxWidth: 210
    }
})

const ScreeenWishlistEdit = () => {

    const navigation = useNavigation<any>()
    const route = useRoute<any>()
    const { t } = useTranslation()
    const wishlist: IWishlist = route.params?.wishlist
    if(!wishlist) navigation.goBack()
    const [title, setTitle] = useState<string>(wishlist.title)
    const [subtitle, setSubtitle] = useState<string>(wishlist.subtitle)
    const [backgroundImageUri, setBackgroundImageUri] = useState<string | null>(wishlist.imageUrl)
    const [isCoverChanged, setIsCoverChanged] = useState<boolean>(false) 
    const [loadingText, setLoadingText] = useState<string>()

    const onPressChangeBackground = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
            allowsEditing: true,
            aspect: [3, 2],
            quality: 0.2
        })

        if(!result.cancelled) {
            setBackgroundImageUri(result.uri)
            setIsCoverChanged(true)
        }
    }

    const onPressSaveWishlist = async () => {

        // Upload image if needed
        let imageUrl: string = wishlist.imageUrl
        if(backgroundImageUri && isCoverChanged) {
            try {
                setLoadingText(t('ScreenWishlistEdit.loading_upload_image'))
                imageUrl = await api.uploadImage(backgroundImageUri)
            } catch(e: any) {
                Alert.alert(t('ScreenWishlistEdit.error'), t('ScreenWishlistEdit.error_image_upload', {message: e.message}))
                console.error(e)
                setLoadingText(undefined)
                return
            }
        }

        // Create the wishlist
        try {
            setLoadingText(t('ScreenWishlistEdit.loading_save_cover'))
            if(Platform.OS === 'web') {
                const db = webFirestore.getFirestore()
                const wishlistRef = webFirestore.doc(db, `wishlist/${wishlist.id}`)
                await webFirestore.updateDoc(wishlistRef, {
                    title, subtitle, imageUrl
                })
            } else {
                await firestore().collection('wishlist').doc(wishlist.id).update({
                    title, subtitle, imageUrl
                })
            }
            navigation.navigate('Wishlist', {wishlistId: wishlist.id})
        } catch(e: any) {
            api.showMessage(t('ScreenWishlistEdit.error'), t('ScreenWishlistEdit.error_save', {message: e.message}))
            console.error(e)
            return
        } finally {
            setLoadingText(undefined)
        }
    }

    return (
        <View style={PageStyle.default}>
            <SafeAreaView style={{flex: 1}}>
                <Divider.Medium />
                <Button.Back text={wishlist.title}/>
                <Divider.Small />

                <ScrollView style={PageStyle.safeArea}>
                    
                    <Text style={Typography.Title}>{t('ScreenWishlistEdit.title')}</Text>
                    <Text style={[Typography.Subtitle, style.subtitle]}>{t('ScreenWishlistEdit.subtitle')}</Text>
                    <Divider.Large />

                    <TextInput value={title} title={t('ScreenWishlistEdit.input_title_title')} placeholder={t('ScreenWishlistEdit.input_title')} onChangeText={setTitle} />
                    <Divider.Medium />
                    <TextInput value={subtitle} title={t('ScreenWishlistEdit.input_subtitle')} placeholder={t('ScreenWishlistEdit.input_subtitle_placeholder')} onChangeText={setSubtitle} />
                    <Divider.Medium />

                    <Text style={Typography.InputTitle}>{t('ScreenWishlistEdit.preview')}</Text>
                    <CardWishlist title={title} subtitle={subtitle} imageUri={backgroundImageUri} />
                    <Divider.Small />
                    <Button.PrimarySmall text={t('ScreenWishlistEdit.change_background')} style={style.changeBackground} onPress={onPressChangeBackground} disabled={loadingText ? true : false} />
                    <Divider.Medium />
                    
                    <Button.Primary text={t('ScreenWishlistEdit.button_save')} loadingText={loadingText} onPress={onPressSaveWishlist} />
                    <Divider.Small />
                </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ScreeenWishlistEdit