import { Text, View, StyleSheet, TouchableOpacity, TextInput, Platform } from "react-native";
import Color from "../style/Color";
import Typography from "../style/Typography";
import Animated, {FadeInDown, FadeInUp, FadeOut, FadeOutDown} from "react-native-reanimated";
import PageStyle from "../style/PageStyle";
import StarEmpty from "./StarEmpty";
import StarFull from "./StarFull";
import { useState } from "react";
import Divider from "./Divider";
import Button from "./Button";
import firestore from "@react-native-firebase/firestore";
import * as webFirestore from 'firebase/firestore';
import { getUser } from "../Api";
import IFeedback from "../models/IFeedback";
import * as StoreReview from "expo-store-review";
import { useTranslation } from "react-i18next";

const style = StyleSheet.create({
    view: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Color.BackgroundTransparent,
        zIndex: 10,
        flexDirection: 'row',
    },
    container: {
        padding: 16,
        marginHorizontal: 16,
        borderRadius: 22.5,
        borderColor: Color.DarkTransparentMore,
        borderWidth: 1,
        justifyContent: 'center',
        maxWidth: 350,
        backgroundColor: Color.Background,
        ... PageStyle.Shadow
    },
    hidden: {
        display: 'none'
    },
    text: {
        marginLeft: 8
    },
    feedbackTextInput: {
        backgroundColor: 'white',
        borderRadius: 22.5,
        borderColor: Color.DarkTransparentMore,
        borderWidth: 1,
        height: 90,
        paddingHorizontal: 8,
        paddingVertical: 16,
        textAlignVertical: 'top'
    }
})


interface StarProps {
    full: boolean
    rating: number
    onPress: (rating: number) => void
}

export const Star = (props: StarProps) => {
    return (
        <TouchableOpacity onPress={() => props.onPress(props.rating)} style={{width: 48, height: 48, marginHorizontal: 2}}>
            {props.full ? 
                <Animated.View entering={FadeInUp} exiting={FadeOutDown}>
                    <StarFull width={48} height={48} /> 
                </Animated.View>
            : 
                <StarEmpty width={48} height={48} /> 
            }
        </TouchableOpacity>
    )
}

interface ReviewGateProps {
    onPressDismiss: () => void
}

export default (props: ReviewGateProps) => {

    const { t } = useTranslation()

    const [rating, setRating] = useState(-1)
    const [comment, setComment] = useState('')

    const onPressRating = (rating: number) => {
        setRating(rating)
    }

    const onPressFeedback = async () => {
        const user = getUser()
        if(!user) return
        
        try {
            if(Platform.OS === 'web') { // This component is never used on web
                const db = webFirestore.getFirestore()
                const feedbackRef = webFirestore.collection(db, 'feedback')
                await webFirestore.addDoc(feedbackRef, {
                    uid: user.uid,
                    rating: rating + 1, 
                    comment,
                    timestamp: firestore.FieldValue.serverTimestamp()
                } as IFeedback)
            } else {
                await firestore().collection('feedback').add({
                    uid: user.uid,
                    rating: rating + 1, 
                    comment,
                    timestamp: firestore.FieldValue.serverTimestamp()
                } as IFeedback)
            }
            props.onPressDismiss()
        } catch(e) {
            console.error(e)
            props.onPressDismiss()
        }
    }

    const onPressReview = async () => {
        try {
            const canReview = await StoreReview.isAvailableAsync()
            if(canReview) {
                await StoreReview.requestReview()
            }
        } catch(e) {
            console.error(e)
        } finally {
            props.onPressDismiss()
        }
    }
    
    return (<Animated.View exiting={FadeOut} style={style.view}>
        <Animated.View style={style.container} entering={FadeInDown} exiting={FadeOutDown}>
            
            
            {rating === -1 && <Text style={[Typography.ReviewGateTitle, Typography.Center]}>{t('ReviewGate.title')}</Text>}
            {rating > 2 && <Text style={[Typography.ReviewGateTitle, Typography.Center]}>{t('ReviewGate.thanks')}</Text>}
            {rating > 2 && <Text style={[Typography.ReviewGateSubtitle, Typography.Center]}>{t('ReviewGate.love')}</Text>}

            {rating <= 2 && rating > -1 && <Text style={[Typography.ReviewGateTitle, Typography.Center]}>{t('ReviewGate.thanks')}</Text>}
            {rating <= 2 && rating > -1 && <Text style={[Typography.ReviewGateSubtitle, Typography.Center]}>{t('ReviewGate.how_to_improve')}</Text>}

            <Divider.Tiny />

            <View style={[PageStyle.row, PageStyle.Shadow, {alignSelf: 'stretch', justifyContent: 'center'}]}>
                {[... Array(5).keys()].map(i => <Star onPress={onPressRating} key={i} full={i <= rating} rating={i} />)}
            </View>
        
            {rating > -1 && rating < 3 && <View>
                <Divider.Small />
                <TextInput onChangeText={setComment} multiline={true} style={style.feedbackTextInput}/>
                <Divider.Small />
                <Button.PrimarySmall text={t('ReviewGate.button_feedback')} onPress={onPressFeedback} />
            </View>}

            {rating > 2 && <View>
                <Text style={[Typography.ReviewGateSubtitle, Typography.Center]}>{t('ReviewGate.title_review')}</Text>
                <Divider.Small />
                <Button.PrimarySmall text={t('ReviewGate.button_review')} onPress={onPressReview} />
            </View>}

            <Divider.Tiny />

            <TouchableOpacity onPress={props.onPressDismiss} style={{alignSelf: 'center'}}>
                <Text style={Typography.ReviewGateDismiss}>{t('ReviewGate.button_dismiss')}</Text>
            </TouchableOpacity>

        </Animated.View>
    </Animated.View>)
}